/**
 ==================================================
 * Name: SpingBox - Business And Modern Corporate HTML Template
 * Version: 1.0
 * Author: Theembazaar
 * Author URL: http://www.theembazaar.com
 ===================================================
 */


/*Table Content
 =================================================
 * BASIC STYLE
 - Typography Style
 - Padding and Margin Style
 - General Style
 - Box Hover Style
 - Form Style

 * HEADER
 - Header Style
 - Header style 2
 - Navigation Menu

 * FOOTER

 * ELEMENTS
 - Main Banner
 - Page Intro
 - Testimonial
 - Pagination Navigation
 - Sidebar Widget
 - blog Section
 - Partner Section
 - About Us Page
 - Timeline Section
 - Team
 - Serve
 - Faq Page
 - 404 page
 - Blog Page
 - Register and login Pages
 - Contact Page
 - Preloader
 ================================================
 */


/*-------------------------------------------------------------------------------------------*/


/* BASIC STYLE    ||---------------------------- */


/*-------------------------------------------------------------------------------------------*/

body,
html {
    margin: 0;
    padding: 0;
}

body {
    line-height: 24px;
    color: #323232;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}


/*  Typography Style   ||-----------*/

h1 {
    font-size: 60px;
    margin-top: 0;
    position: relative;
    font-family: 'Raleway', sans-serif;
}

h1 span {
    position: relative;
}

h1:before {
    content: attr(data-title);
    font-size: 61px;
    margin-top: 0;
    position: absolute;
    top: 4px;
    left: 3px;
    color: rgba(6, 60, 54, 0.08);
    z-index: 0;
}

h2 {
    font-size: 30px;
    font-weight: 800;
    margin-top: 0;
    font-family: 'Raleway', sans-serif;
    color: #2fadb9;
    text-transform: capitalize;
}

h2 span {
    color: #222;
}

h5 {
    font-size: 15px;
    line-height: 1.5;
    font-weight: 600;
}

h6 {
    font-size: 13px;
    letter-spacing: 2px;
    line-height: 17px;
    font-weight: 600;
}

h3,
h4,
h5,
h6 {
    font-family: 'Raleway', sans-serif;
    margin-top: 10px;
    font-weight: 600;
    color: #2fadb9;
}

a:hover,
a:focus {
    text-decoration: none;
    outline: none;
}

a {
    color: #333;
}

.inner-intro h1 {
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    text-transform: uppercase;
}


/*  Padding and Margin Style   ||-----------*/

.padding {
    padding-top: 90px;
    padding-bottom: 90px;
}

.no-padding {
    padding: 0;
}

.ptb-20 {
    padding-bottom: 20px;
    padding-top: 20px;
}

.padding-20 {
    padding: 20px;
}

.padding-30 {
    padding: 30px;
}

.padding-40 {
    padding: 40px;
}

.padding-60 {
    padding: 60px;
}

.plr-0 {
    padding-left: 0;
    padding-right: 0;
}

.pl-0 {
    padding-left: 0;
}

.pr-0 {
    padding-right: 0;
}

.pr-30 {
    padding-right: 30px;
}

.pl-70 {
    padding-left: 70px;
}

.pr-70 {
    padding-right: 70px;
}

.plr-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.ptb-0 {
    padding-top: 0px;
    padding-bottom: 0px;
}

.pt-0 {
    padding-top: 0px;
}

.pb-0 {
    padding-bottom: 0px;
}

.pt-30 {
    padding-top: 30px;
}

.pb-30 {
    padding-bottom: 30px;
}

.ptb {
    padding-top: 80px;
    padding-bottom: 80px;
}

.pt {
    padding-top: 110px;
}

.pb {
    padding-bottom: 110px;
}

.ptb-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.pt-15 {
    padding-top: 15px;
}

.pb-15 {
    padding-bottom: 15px;
}

.ptb-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.pt-60 {
    padding-top: 60px;
}

.pb-60 {
    padding-bottom: 60px;
}

.pb-40 {
    padding-bottom: 40px;
}

.ptb-40 {
    padding-bottom: 40px;
    padding-top: 40px;
}

.ptb-80 {
    padding-top: 90px;
    padding-bottom: 90px;
}

.pt-80 {
    padding-top: 90px;
}

.pb-80 {
    padding-bottom: 90px;
}

.pt-90 {
    padding-top: 90px;
}

.pb-90 {
    padding-bottom: 90px;
}

.pt-50 {
    padding-top: 50px;
}

.pb-50 {
    padding-bottom: 50px;
}

.pb-70 {
    padding-bottom: 70px;
}

.pt-70 {
    padding-top: 70px;
}

.pb-38 {
    padding-bottom: 38px;
}

.mtb-0 {
    margin-top: 0px;
    margin-bottom: 0px;
}

.mlr-0 {
    margin-left: 0px;
    margin-right: 0px;
}

.mt-0 {
    margin-top: 0px;
}

.mb-0 {
    margin-bottom: 0px;
}

.ml-0 {
    margin-left: 0px;
}

.mr-0 {
    margin-right: 0px;
}

.up-80 {
    margin-top: -80px;
}

.mtb-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-80 {
    margin-top: 80px;
}

.mb-80 {
    margin-bottom: 80px;
}

.mtb-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.mt-60 {
    margin-top: 60px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mtb-45 {
    margin-top: 45px;
    margin-bottom: 45px;
}

.mt-45 {
    margin-top: 45px;
}

.mb-45 {
    margin-bottom: 45px;
}

.mt-40 {
    margin-top: 40px;
}

.mb-40 {
    margin-bottom: 35px;
}

.mb-35 {
    margin-bottom: 35px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mtb-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.ml-15 {
    margin-left: 15px;
}

.mt-30 {
    margin-top: 30px;
}

.mb-30 {
    margin-bottom: 30px;
}

.ml-30 {
    margin-left: 30px;
}

.mr-30 {
    margin-right: 30px;
}

.mtb-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.mt-25 {
    margin-top: 25px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mtb-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-10 {
    margin-top: 10px;
}

.mb-15 {
    margin-bottom: 15px;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.mt-up {
    margin-top: -100px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .up-80 {
        margin-top: 50px;
    }
    .mt-sm-30 {
        margin-top: 30px;
    }
    .mb-sm-30 {
        margin-bottom: 30px;
    }
    .ptb-sm-60 {
        padding-bottom: 60px;
    }
    .float-none-sm {
        float: none;
    }
    .float-left-sm {
        float: left;
    }
    .float-right-sm {
        float: right;
    }
    .pl-sm-0 {
        padding-left: 0;
    }
    .plr-xs-15 {
        padding-right: 15px;
        padding-left: 15px;
    }
    .padding-sm-25 {
        padding: 25px;
    }
}

@media (max-width: 767px) {
    .text-xs-left {
        text-align: left;
    }
    .pr-xs-0 {
        padding-right: 0;
    }
    .pl-xs-0 {
        padding-left: 0;
    }
    .pr-xs-0 {
        padding-right: 0;
    }
    .plr-xs-15 {
        padding-right: 15px;
        padding-left: 15px;
    }
    .padding-sm-25 {
        padding: 25px;
    }
    .pb-xs-30 {
        padding-bottom: 30px;
    }
    .pt-xs-60 {
        padding-top: 60px;
    }
    .ptb-xs-40 {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .pt-xs-40 {
        padding-top: 40px;
    }
    .pb-xs-40 {
        padding-bottom: 40px;
    }
    .pb-xs-60 {
        padding-bottom: 60px;
    }
    .ptb-xs-60 {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .ml-xs-0 {
        margin-left: 0;
    }
    .mtb-xs-0 {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .mt-xs-0 {
        margin-top: 0px;
    }
    .mb-xs-0 {
        margin-bottom: 0px;
    }
    .mtb-xs-15 {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .mt-xs-15 {
        margin-top: 15px;
    }
    .mb-xs-15 {
        margin-bottom: 15px;
    }
    .mtb-xs-30 {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .mt-xs-30 {
        margin-top: 30px;
    }
    .mb-xs-30 {
        margin-bottom: 30px;
    }
    .float-none-xs {
        float: none;
    }
    .float-left-xs {
        float: left;
    }
    .float-right-xs {
        float: right;
    }
}


/*  General Style   ||-----------*/

a:focus,
a:hover {
    color: #2fadb9;
}

.b-line {
    position: relative;
    width: 60px;
    height: 3px;
    background: #2fadb9;
    display: block;
    margin: 0 auto;
}

.l-left {
    margin: 0;
}

.no-brdr {
    border: none;
}

.left-bg {
    position: relative;
}

.right-bg {
    position: relative;
}

.left-bg:before {
    content: '';
    width: 50%;
    height: 100%;
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
}

.right-bg {
    position: relative;
}

.right-bg:before {
    content: '';
    width: 36%;
    height: 100%;
    background: #000;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
}

.color {
    color: #2fadb9;
}

.light-color * {
    color: #fff;
}

.italic {
    font-style: italic;
}

.about-block p {
    line-height: 2;
    margin-bottom: 0;
}

#project-section .about-block .text-box .box-title h3 a:hover {
    color: #fff;
}

.about-block .text-box .box-title h3 {
    font-size: 20px;
}

.v-align {
    display: inline-block;
    vertical-align: top;
}

.btn-text {
    font-size: 14px;
    color: #fff;
    font-family: 'Raleway', sans-serif;
    line-height: 41px;
    border: 2px solid #2fadb9;
    padding: 0 22px;
    border-radius: 4px;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1px;
    background: #2fadb9;
    height: 44px;
    text-align: center;
    -webkit-transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s;
}

.small-btn {
    padding: 10px 30px;
}

.btn-text:hover {
    background: none;
    color: #2fadb9;
    text-decoration: none;
}

.icon-box {
    width: 80px;
    height: 80px;
    line-height: 80px;
    margin: 20px auto;
    color: #fff;
    font-size: 25px;
    text-align: center;
    display: block;
    border-radius: 50%;
}

.width-25per {
    width: 25%;
}

.width-75per {
    width: 75%;
}

.top-space {
    padding-top: 218px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .top-space {
        padding-top: 100px;
    }
}

.heading-block h2 span {
    display: block;
}

@media (min-width: 768px) and (max-width: 991px) {
    .width-25per {
        width: 30%;
    }
    .width-75per {
        width: 70%;
    }
}

@media (max-width: 767px) {
    .width-25per {
        width: 100%;
    }
    .width-75per {
        width: 100%;
    }
}

.half-width {
    width: 50%;
}

@media (max-width: 767px) {
    .half-width {
        width: 100%;
    }
    .img-responsive {
        width: 100%;
    }
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.height-300 {
    height: 300px;
}

.height {
    height: 250px;
}

.border {
    border: 1px solid #ccc;
}

.about-block {
    -webkit-transition: all 0.4s linear 0s;
    -ms-transition: all 0.4s linear 0s;
    transition: all 0.4s linear 0s;
}

.v-line {
    border-left: 3px solid #2fadb9;
    padding-left: 20px;
}

.big-icon {
    line-height: 185px;
    margin: 20px auto;
    color: #3bafda;
    font-size: 100px;
    text-align: center;
    display: block;
}

.dark-bg,
.hover-bg:hover {
    background: #2fadb9;
}

.black-bg {
    background: #2C2C2C;
}

.gray-bg {
    background: #f1f1f1;
}

.dark-gray-bg {
    background: #eaeff3;
}

img {
    max-width: 100%;
}

.mt-15 {
    margin-top: 15px;
}

.box-shadow {
    box-shadow: 14px 14px 0 0 rgba(0, 0, 0, 0.19);
}

.box-shadow-l {
    box-shadow: -14px 14px 0 0 rgba(0, 0, 0, 0.19);
}

@media (max-width: 767px) {
    .box-shadow,
    .box-shadow-l {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
}

.height-300 {
    height: 236px;
}

.pb-15 {
    padding-bottom: 15px;
}

.m-height {
    min-height: 183px;
}


/*  Box Hover Style   ||-----------*/

.grid figure {
    position: relative;
    overflow: hidden;
    margin: 10px 1%;
    background: #133642;
    text-align: center;
    cursor: pointer;
}

.grid figure img {
    position: relative;
    display: block;
    min-height: 100%;
    max-width: 100%;
    opacity: 0.5;
}

.grid figure figcaption {
    padding: 2em;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
    pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption>a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


/* Anchor will cover the whole item by default */


/* For some effects it will show as a button */

.grid figure figcaption>a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
}

.grid figure h2 {
    word-spacing: -0.15em;
    font-size: 19px;
}

.grid figure h2 span {
    font-weight: 600;
}

.grid figure h2,
.grid figure p {
    margin: 0;
}

.grid figure p {
    letter-spacing: 1px;
    font-size: 68.5%;
}


/*---------------*/


/***** Sadie *****/


/*---------------*/

figure.effect-sadie figcaption::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(top, rgba(72, 76, 97, 0) 0%, rgba(72, 76, 97, 0.8) 75%);
    background: linear-gradient(to bottom, rgba(72, 76, 97, 0) 0%, rgba(72, 76, 97, 0.8) 75%);
    content: '';
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
}

figure.effect-sadie h2 {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    color: #fee;
    -webkit-transition: -webkit-transform 0.35s, color 0.35s;
    transition: transform 0.35s, color 0.35s;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
}

figure.effect-sadie figcaption::before,
figure.effect-sadie p {
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}

figure.effect-sadie p {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2em;
    width: 100%;
    opacity: 0;
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
}

figure.effect-sadie:hover h2 {
    color: #fff;
    -webkit-transform: translate3d(0, -50%, 0) translate3d(0, -40px, 0);
    transform: translate3d(0, -50%, 0) translate3d(0, -40px, 0);
}

figure.effect-sadie:hover figcaption::before,
figure.effect-sadie:hover p {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}


/*-----------------*/


/***** Goliath *****/


/*-----------------*/

figure.effect-goliath {
    background: #0e332f;
}

figure.effect-goliath img,
figure.effect-goliath h2 {
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
}

figure.effect-goliath img {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

figure.effect-goliath h2,
figure.effect-goliath p {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 30px;
}

figure.effect-goliath p {
    text-transform: none;
    font-size: 90%;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 50px, 0);
}

figure.effect-goliath:hover img {
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
}

figure.effect-goliath:hover h2 {
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
}

figure.effect-goliath:hover p {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.grid figure.effect-goliath {
    margin: 0;
}


/*---------------*/


/***** Zoe *****/


/*---------------*/

.grid figure.effect-zoe {
    background: none;
}

.grid figure.effect-zoe img {
    opacity: 1;
}

figure.effect-zoe figcaption {
    top: auto;
    bottom: 0;
    padding: 1em;
    height: 4.45em;
    background: #2fadb9;
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
}

figure.effect-zoe .name-block {
    float: left;
}

figure.effect-zoe p.icon-links {
    float: right;
    margin-top: 8px;
}

figure.effect-zoe p.icon-links a {
    float: left;
    color: #323232;
    font-size: 1.4em;
}

figure.effect-zoe:hover p.icon-links a:hover,
figure.effect-zoe:hover p.icon-links a:focus {
    color: #252d31;
}

figure.effect-zoe p.description {
    position: absolute;
    bottom: 5em;
    padding: 2em;
    color: #000;
    text-transform: none;
    font-size: 15px;
    opacity: 0;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
    -webkit-backface-visibility: hidden;
    /* Fix for Chrome 37.0.2062.120 (Mac) */
    background: rgba(250, 183, 2, 0.67);
    left: 0;
}

figure.effect-zoe .name-block,
figure.effect-zoe p.icon-links a {
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    -webkit-transform: translate3d(0, 200%, 0);
    transform: translate3d(0, 200%, 0);
}

figure.effect-zoe p.icon-links a span::before {
    display: inline-block;
    padding: 8px 10px;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

figure.effect-zoe h2 {
    display: inline-block;
}

figure.effect-zoe:hover p.description {
    opacity: 1;
}

figure.effect-zoe:hover figcaption,
figure.effect-zoe:hover .name-block,
figure.effect-zoe:hover p.icon-links a {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

figure.effect-zoe:hover .name-block {
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
}

figure.effect-zoe:hover p.icon-links a:nth-child(3) {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

figure.effect-zoe:hover p.icon-links a:nth-child(2) {
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s;
}

figure.effect-zoe:hover p.icon-links a:first-child {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}


/* -------------------------------------*/


/* Carousel-Theme (Slider Elements - Bullets, Pagination, etc..) ---------*/


/* -------------------------------------*/

.owl-nav {
    position: static;
}

.owl-carousel.white .owl-controls .owl-page span {
    background: none repeat scroll 0 0 #fff;
}

.owl-carousel.white .owl-controls .owl-page.active span {
    background: #e6ae49;
}

.nf-carousel-theme {}

.nf-carousel-theme .owl-controls .owl-pagination {
    position: absolute;
    z-index: 2;
    left: 0;
    text-align: center;
    width: 100%;
    margin-top: 15px;
}

.nf-carousel-theme .owl-controls .owl-nav .owl-prev,
.nf-carousel-theme .owl-controls .owl-nav .owl-next {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    margin-top: -15px;
    color: #fff;
}

.content-carousel .owl-controls .owl-nav .owl-prev,
.content-carousel .owl-controls .owl-nav .owl-next,
.image-slider .owl-controls .owl-nav .owl-prev,
.image-slider .owl-controls .owl-nav .owl-next,
.fullwidth-slider .owl-controls .owl-nav .owl-prev,
.fullwidth-slider .owl-controls .owl-nav .owl-next,
.nf-carousel-theme .owl-controls .owl-nav .owl-prev,
.nf-carousel-theme .owl-controls .owl-nav .owl-next {
    background: #2fadb9;
    color: #fff;
    display: block;
    top: 50%;
    transition: all 0.25s ease 0s;
    -o-transition: all 0.25s ease 0s;
    -moz-transition: all 0.25s ease 0s;
    -webkit-transition: all 0.25s ease 0s;
    z-index: 3;
    position: absolute;
    text-align: center;
}

.content-carousel .owl-controls .owl-nav .owl-prev:hover,
.content-carousel .owl-controls .owl-nav .owl-next:hover,
.image-slider .owl-controls .owl-nav .owl-prev:hover,
.image-slider .owl-controls .owl-nav .owl-next:hover,
.fullwidth-slider .owl-controls .owl-nav .owl-prev:hover,
.fullwidth-slider .owl-controls .owl-nav .owl-next:hover,
.nf-carousel-theme .owl-controls .owl-nav .owl-prev:hover,
.nf-carousel-theme .owl-controls .owl-nav .owl-next:hover {
    color: #fff;
}

.content-carousel .owl-controls .owl-nav .owl-next,
.image-slider .owl-controls .owl-nav .owl-next,
.fullwidth-slider .owl-controls .owl-nav .owl-next,
.nf-carousel-theme .owl-controls .owl-nav .owl-next {
    right: 0;
}

.nf-carousel-theme .owl-controls .owl-nav .owl-prev {
    left: 0;
    margin-left: 0;
    opacity: 1;
}

.nf-carousel-theme .owl-controls .owl-nav .owl-next {
    right: 0;
    margin-right: 0;
    opacity: 1;
}

.content-carousel:hover .owl-controls .owl-nav .owl-prev,
.content-carousel:hover .owl-controls .owl-nav .owl-next,
.image-slider:hover .owl-controls .owl-nav .owl-prev,
.image-slider:hover .owl-controls .owl-nav .owl-next,
.fullwidth-slider:hover .owl-controls .owl-nav .owl-prev,
.fullwidth-slider:hover .owl-controls .owl-nav .owl-next,
.nf-carousel-theme:hover .owl-controls .owl-nav .owl-prev,
.nf-carousel-theme:hover .owl-controls .owl-nav .owl-next {
    margin-right: 0;
    margin-left: 0;
    opacity: 1;
}

.owl-prev,
.owl-next {
    position: absolute;
    top: 0;
    height: 100%;
    width: 0;
    background: rgba(25, 169, 153, 0.56);
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    font-size: 25px;
    overflow: hidden;
    color: #fff;
    text-align: center;
}

.owl-nav i {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.owl-prev {
    left: 0;
}

.owl-next {
    right: 0;
}


/*Form Style   ||-----------*/

button {
    outline: none;
    border: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    cursor: pointer;
    display: inline-block;
}

button,
input,
textarea,
select {
    font-family: 'Raleway', sans-serif;
    font-size: 12px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="password"],
input[type="radio"],
input[type="checkbox"],
textarea,
select {
    appearance: none;
    -webkit-appearance: none;
    border: 1px solid #fff;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    color: #202020;
    outline: none;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 13px;
    padding: 12px 15px;
    transition: all 0.2s ease 0s;
    -moz-transition: all 0.2s ease 0s;
    -webkit-transition: all 0.2s ease 0s;
    -o-transition: all 0.2s ease 0s;
    display: inline-block;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus,
input[type="password"]:focus,
textarea:focus {
    outline: none;
    color: #323232;
}

input,
textarea,
select {
    margin-bottom: 20px;
    font-family: 'Raleway', sans-serif;
    font-size: 12px;
}

input.input-sm,
.input-sm {
    padding: 8px 15px;
}

textarea {
    height: 120px;
}

label {
    color: #323232;
    font-size: 13px;
    margin-bottom: 10px;
    padding-right: 3px;
    margin-top: 6px;
    font-weight: 600;
    font-family: 'Raleway', sans-serif;
}

.form-full {
    width: 100%;
}

.form-field-wrapper {
    margin-bottom: 20px;
}

.form-field-wrapper input,
.form-field-wrapper textarea,
.form-field-wrapper select {
    margin-bottom: 0px;
}

select.input-sm {
    height: 37px;
    line-height: 1;
}

form .error {
    border-color: #e1534f !important;
}

form label.error {
    margin-bottom: 0px;
    margin-top: 3px;
    color: #e1534f;
    font-size: 12px;
    font-weight: 400;
}

.successContent,
.errorContent {
    margin-bottom: 20px;
    display: none;
    text-transform: none;
    letter-spacing: 1px;
}


/*-------------------------------------------------------------------------------------------*/


/* HEADER         ||---------------------------- */


/*-------------------------------------------------------------------------------------------*/


/* Header Style i*/

.wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
}

#header,
.header {
    background: #fff;
}

.logo a {
    display: inline-block;
    padding: 32px 0;
}

.menu {
    border: none;
    border-bottom: 4px solid #2fadb9;
    border-top: 4px solid #2fadb9;
    height: 29px;
    position: absolute;
    right: 29px;
    top: 40px;
    width: 44px;
    background: none;
}

.menu:after {
    position: absolute;
    top: 9px;
    right: 0;
    content: '';
    width: 100%;
    height: 4px;
    background: #2fadb9;
}

.header ul {
    float: right;
    list-style: none;
    padding: 0;
    margin: 0;
}

.header ul li {
    float: left;
    position: relative;
}

.header ul li ul.sub-nav li ul.sub-nav {
    top: 0% !important;
}

.navigation ul li a {
    font-size: 12px;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
}

.navigation>ul>li>a {
    display: block;
    padding: 38px 16px;
    color: #323232;
    transition: all 0.25s ease 0s;
    -webkit-transition: all 0.25s ease 0s;
    -moz-transition: all 0.25s ease 0s;
    -o-transition: all 0.25s ease 0s;
    outline: none;
}

.navigation>ul>li:last-child a {
    padding-right: 0;
}

.navigation .sub-nav {
    left: 0;
    border-top: 20px solid transparent;
    position: absolute;
    top: 175%;
    width: 200px;
    z-index: 3;
    background-clip: padding-box;
    padding: 0;
    transition: top 0.3s ease 0s, visibility 0.2s ease 0.2s, opacity 0.2s ease 0.1s;
    -webkit-transition: top 0.3s ease 0s, visibility 0.2s ease 0.2s, opacity 0.2s ease 0.1s;
    -moz-transition: top 0.3s ease 0s, visibility 0.2s ease 0.2s, opacity 0.2s ease 0.1s;
    -ms-transition: top 0.3s ease 0s, visibility 0.2s ease 0.2s, opacity 0.2s ease 0.1s;
    -o-transition: top 0.3s ease 0s, visibility 0.2s ease 0.2s, opacity 0.2s ease 0.1s;
    opacity: 0;
    visibility: hidden;
    background-color: rgb(33, 140, 150);
}

.navigation .sub-nav li {
    display: block;
    float: none;
    margin: 0;
    border-bottom: 1px solid rgba(255, 238, 238, 0.35);
    padding-bottom: 0;
    position: relative;
}

.navigation li:hover .sub-nav {
    opacity: 1;
    top: 80%;
    transition: top 0.3s ease 0s;
    visibility: visible;
}

.navigation li.sub-menu .sub-nav {
    border: none;
    opacity: 0;
    top: 0;
    transition: top 0.3s ease 0s;
    visibility: visible;
    left: auto;
    right: 110%;
    visibility: hidden;
    transition: all 0.3s ease 0s;
}

.navigation li.sub-menu:hover .sub-nav {
    visibility: visible;
    opacity: 1;
    right: 100%;
    transition: all 0.3s ease 0s;
}

.navigation .sub-nav li:last-child {
    border-bottom: 0;
}

.navigation .sub-nav li a {
    padding: 8px 20px;
    display: block;
    color: #fff;
}

.appointment-button {
    text-transform: uppercase;
}

.appointment-button:hover {
    color: #222;
}


/*Top bar*/

.top-bar-section {
    padding-top: 10px;
    padding-bottom: 10px;
    background: #2fadb9;
    color: #fff;
}

.top-contact {
    float: left;
    text-align: center;
}

.top-social-icon {
    float: right;
}

.top-social-icon {
    text-align: center;
}

.top-bar-section.top-bar-bg-color a {
    color: #fff;
}

.top-contact a i {
    padding-left: 5px;
    padding-right: 5px;
}

.top-social-icon {
    float: right;
}

.top-social-icon {
    text-align: center;
}

.top-social-icon ul {
    margin: 0;
    padding: 0;
}

.top-social-icon li {
    margin-left: 10px;
}

.top-bar-section a {
    text-transform: inherit;
    font-weight: 400;
    padding: 5px 0;
    letter-spacing: 1px;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 0.5px;
}

.top-bar-section a i {
    font-size: 16px;
}

.top-social-icon a.btn {
    padding: 9px 20px;
}

.top-social-icon a.btn:hover {
    color: #2fadb9;
}

.link-hover-black>a:last-child {
    margin-left: 10px;
}


/*Header style 2*/

.header-2 .primary-header {
    background: #2fadb9;
}

.header-2 .navigation>ul {
    float: left;
}

.header-2 .navigation>ul>li {
    border-right: 1px solid #218c96;
}

.header-2 .navigation>ul>li:hover {
    background: #218c96;
}

.header-2 .navigation>ul>li>a {
    color: #fff;
    padding: 18px 28px;
}

.header-2 .navigation li:hover .sub-nav {
    top: 67%;
}



.call-us ul {
    list-style-type: none;
    line-height: 1.3;
    padding-top: 35px;
}

.call-us ul li {
    display: inline-block;
    font-weight: bold;
    padding: 0 1px;
    vertical-align: top;
    position: relative;
}

.call-us ul li:first-child:after {
    content: "";
    background: #d5dce4;
    display: block;
    position: absolute;
    right: -15px;
    top: -18px;
    width: 1px;
    height: 67px;
}

.call-us ul li:last-child {
    padding: 0 0 0 25px !important;
}

.call-box {
    float: right;
    font-weight: bold;
    font-size: 13px;
    color: #545454;
}

.call-box>span {
    display: block;
}

.call-us i {
    font-size: 46px;
    float: left;
    margin-right: 12px;
    vertical-align: top;
    margin-top: -13px;
}

.call-us ul li a {
    font-size: 30px;
    color: #545454;
    line-height: 1;
    transition: 1s all ease;
    -webkit-transition: 1s all ease;
}

.call-us {
    float: right;
}

.header-2 .top-bar-section {
    background: #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    padding-top: 4px;
    padding-bottom: 4px;
}

.header-2 .top-bar-section.top-bar-bg-color a,
.header-2 .top-social-icon li {
    color: #909090;
}

.header-2 .addr {
    color: #909090;
    display: block;
    padding-top: 5px;
}

.header-2 i {
    color: #2fadb9;
}

.header-2 .top-bar-section.top-bar-bg-color a:hover,
.call-us ul li a:hover,
.call-us ul li a.active {
    color: #2fadb9;
}

.col-right {
    text-align: right;
}

.col-right .opening {
    float: right;
    text-align: left;
    padding: 25px 0 0 33px
}

.header .col-right ul,
.header .col-right ul li {
    float: none;
    position: relative;
    color: #2c3740 !important;
    font-weight: bold;
}

.header .col-right ul li span,
.header .col-right ul li * {
    color: #2c3740 !important;
}

.col-right .call-us {
    display: inline-block;
    text-align: right;
    float: none;
}

.col-right .opening ul:after {
    content: "";
    background: #d5dce4;
    display: block;
    position: absolute;
    left: -15px;
    top: -9px;
    width: 1px;
    height: 67px;
}

.header-style {
    box-shadow: 0 0px 1px 1px rgba(0, 0, 0, 0.1);
}

.header-style,
.header-1 .nav-wrap {
    box-shadow: 0 0px 1px 1px rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 767px) {
    #header.fix .nav-wrap,
    #header.fix .primary-header,
    .header-style.fix-header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999999;
    }
    #header.fix .nav-wrap,
    .header-style.fix-header {
        background: #fff;
    }
    .p-top {
        padding-top: 157px;
    }
}

.boxed #header.fix .nav-wrap,
.boxed #header.fix .primary-header,
.boxed .header-style.fix-header {
    max-width: 1170px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .header-2 .navigation>ul>li>a {
        padding: 17px 23px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .navigation {
        margin-left: 0;
    }
    .navigation>ul>li>a {
        padding: 38px 13px;
    }
    .navigation .sub-nav {
        left: -27px;
    }
    .newsletter .newsletter-input {
        padding: 12px 15px;
    }
    .header-2 .navigation>ul>li>a {
        padding-left: 14px;
        padding-right: 14px;
    }
    .col-right .opening {
        padding-left: 26px;
    }
    .col-right .call-box {
        font-size: 10px;
    }
    .col-right .call-us i {
        font-size: 37px;
        margin-right: 8px;
        margin-top: -13px;
    }
    .col-right .opening li {
        font-size: 12px;
    }
    .col-right .call-us ul li a {
        font-size: 20px;
    }
    .appointment-button {
        margin-left: -20px;
        padding-left: 11px;
        padding-right: 11px;
        font-size: 10px;
    }
}


/* ======Mobile media Query =========*/

@media (max-width: 767px) {
    .container,
    .treatment,
    .appointment-block {
        margin: 0 auto;
        max-width: 480px;
        width: 100%;
    }
    .header ul,
    .header ul li {
        float: none !important;
    }
    .top-social-icon {
        text-align: center;
    }
    .top-social-icon ul li {
        display: inline-block;
        margin: 0 4px !important;
    }
    .top-social-icon ul li:last-child,
    .top-social-icon ul li:last-child a {
        width: 100%;
        margin: 0;
    }
    .story-content,
    .video-frame {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        float: none !important;
    }
    .logo a {
        padding: 29px 0px !important;
    }
    .navigation {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 9;
        background: #2fadb9;
    }
    .navigation ul li {
        border-bottom: 1px solid #fff
    }
    .navigation>ul>li>a,
    .header-2 .navigation>ul>li>a,
    .navigation>ul>li:last-child a {
        padding: 10px 15px;
        color: #fff;
    }
    .header-2 .primary-header {
        border-bottom: 1px solid #fff;
    }
    .navigation .sub-nav,
    .navigation li.sub-menu .sub-nav,
    .navigation li.sub-menu:hover .sub-nav {
        position: static;
        opacity: 1;
        visibility: visible;
        width: 100%;
        border-top: none;
        transition: inherit;
        -webkit-transition: inherit;
        padding: 0;
    }
    .navigation ul li i {
        color: #fff;
        font-size: 25px;
        position: absolute;
        right: 15px;
        top: 9px;
        cursor: pointer;
        pointer-events: none;
    }
    .navigation ul li ul {
        display: none;
    }
    .navigation .on>.ion-ios-plus-empty:before {
        content: '\f462';
        font-family: "Ionicons";
    }
    .call-us ul li a {
        font-size: 24px;
    }
    .col-right .opening ul::after,
    .col-right {
        display: none;
    }
    .book-app {
        background: #2fadb9;
        position: absolute !important;
        top: 87px;
        left: 0 !important;
        width: 100%;
        z-index: 99;
        border-bottom: 1px solid #fff;
    }
    .book-app .appointment-button {
        width: 100%
    }
    .header-style .logo {
        padding-bottom: 56px;
    }
    .header-style .navigation {
        top: 163px;
    }
    .header-2 .navigation>ul,
    .top-social-icon {
        float: none !important;
        ;
    }
    .header-2 .top-social-icon ul li:last-child,
    .header-2 .top-social-icon ul li:last-child a {
        width: auto;
    }
    .header-2 .top-social-icon {
        padding-bottom: 0;
    }
    .header-2 .appointment-button {
        display: block;
        margin: 10px auto;
        width: 200px;
    }
    .header-2 .navigation {
        top: 61px;
    }
    .header-2.header-style .navigation {
        top: 148px;
    }
}

.btn-white-line,
.btn-color-line {
    color: #2fadb9;
    border-color: #fff;
    padding: 9px 34px;
    background: #fff;
}

.btn-white-line:hover {
    background: transparent;
    color: #fff;
}


/*==============================================================================================*/


/* FOOTER         ||---------------------------- */


/*==============================================================================================*/

.footer {
    background-color: #f5f5f5;
    background-size: cover;
    color: #222;
    position: relative;
    width: 100%;
    display: block;
    overflow: hidden;
}

.footer h4 {
    text-transform: uppercase;
}

.footer hr {
    border-color: #eee;
}

.footer .footer-info p {
    color: #222;
    margin-bottom: 0px;
}

.footer-info .col-xs-12 {
    min-height: 200px;
}

.footer-logo {
    max-width: 125px;
    display: inline-block;
    width: 100%;
}

.footer .social,
.contact .social {
    font-size: 17px;
}

.footer ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.footer ul.social li,
.contact ul.social li {
    display: inline-block;
    margin: 0 7px 0 0;
}

.footer .link a {
    font-size: 12px;
    font-weight: 400;
    color: #222;
}

.footer .link a:hover {
    color: #2fadb9;
}

.footer h5 {
    color: #f0eded;
    margin-bottom: 30px;
}

.footer .link-small a {
    text-transform: none;
    color: #222;
}

.footer address {
    color: #222;
    position: relative;
    padding-left: 30px;
}

.footer .blog-link li {
    margin-right: 5px;
    margin-bottom: 15px;
}

.footer .link-small a {
    position: relative;
    padding-left: 29px
}

.footer .link-small li {
    margin-bottom: 20px;
}

.footer .icons-hover-black i {
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    margin-bottom: 15px;
    display: inline-block;
    margin-right: 1px;
    background: #1f272f;
    color: #fff;
}

.footer .blog-link img {
    width: 60px;
    height: 40px;
}

.footer .copyright {
    text-align: center;
    padding: 18px 0 10px;
    background: #2fadb9;
    position: relative;
}

.footer .copyright p {
    color: #fff;
    margin-bottom: 0;
}

.footer .post-content {
    font-size: 12px;
    color: #222;
}

.footer .fa-icons {
    font-size: 22px;
    position: absolute;
    top: 0;
    left: 0;
}

.entry-thumbnail {
    float: left;
    margin: 0 15px 0 0;
    max-width: 60px;
    padding-right: 0;
}

.entry-thumbnail {
    float: left;
    margin: 0 15px 0 0;
    max-width: 60px;
    padding-right: 0;
}

.blog-link .fa {
    font-size: 15px;
    margin-right: 8px;
}


/* light footer css */

.light-footer {
    background-color: #EFEFEF;
    border-top: 1px solid #D6D4D4;
    color: #000;
}

.light-footer.footer h5,
.light-footer.footer .footer-info p,
.light-footer.footer .copyright,
.light-footer .copyright a {
    color: #000;
}

.light-footer.footer .copyright {
    border-top: 1px solid #E0DFDF;
    background: #FBFBFB;
}

.light-footer .newsletter .newsletter-input,
.light-footer .newsletter .newsletter-input:focus {
    background: #eee;
    color: #000;
}

.newsletter {
    position: relative;
}

.newsletter form {
    position: relative;
    margin-top: 20px;
}

.newsletter .newsletter-input,
.newsletter .newsletter-input:focus {
    width: 100%;
    border-top: 0px none;
    border-left: 0px none;
    border-right: 0px none;
    padding-right: 40px;
    background: none;
    border-radius: 0;
    border: 1px solid #B1AFAF;
    color: #7f7f7f;
}

.newsletter .newsletter-btn {
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    font-size: 18px;
    background: #7f7f7f;
    line-height: 23px;
    border-radius: 0;
    width: 49px;
}

.footer .icons-hover-black {
    padding-top: 10px;
}


/*Footer Style 2*/

.footer-style-2:before {
    content: '';
    width: 517px;
    height: 166%;
    position: absolute;
    top: 0;
    left: 0;
    background: #2fadb9;
    transform: rotate(23deg);
    transform-origin: 100% 0;
}

.footer-style-2 .icons-hover-black i {
    background: #02695e;
}

.footer-style-2 .footer-info p {
    color: #fff;
    margin-bottom: 15px;
}

.instagram ul {
    margin: 0;
    padding: 0;
}

.instagram li {
    margin: 0 0 4px;
    padding: 0;
    list-style: none;
    display: inline-block;
}

.footer-style-2 .blog-link li {
    margin-right: 0;
    margin-bottom: 15px;
    width: 50%;
    float: left;
}


/*-------------------------------------------------------------------------------------------*/


/*  Main Banner        ||---------------------------- */


/*-------------------------------------------------------------------------------------------*/

.main-banner {
    position: relative;
}

.main-banner .banner-text .sub-text {
    font-weight: 600;
    color: #ffffff;
    font-size: 16px;
    display: block;
    margin-bottom: 5px;
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 1;
}

.main-banner .banner-text h2 {
    font-size: 60px;
    color: #ffffff;
    font-weight: 800;
    margin-bottom: 10px;
    line-height: 1.3;
    text-transform: capitalize;
    padding: 10px 0;
}

.main-banner .banner-text h2 span {
    color: #2fadb9;
}

.main-banner .banner-text p {
    font-family: 'Lato', serif;
    color: #ffffff;
    font-size: 15px;
    margin-bottom: 20px;
    line-height: 1.8;
}

.main-banner .tp-parallax-wrap {}

.main-banner .tp-mask-wrap {
    height: auto;
}

.main-banner .more-btn {
    background: #2fadb9;
    line-height: 30px;
    padding-left: 38px;
    padding-right: 38px;
    font-weight: 700;
    color: #fff;
}

.main-banner li .btn-text {
    line-height: 60px;
    padding-left: 38px;
    padding-right: 38px;
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 100px;
}

#wrapper .main-banner .tp-bullets {
    top: auto;
    left: 52%;
    display: block;
    visibility: visible;
    opacity: 1;
    bottom: 66px;
}

.main-banner .tp-bullet {
    width: 26px;
    height: 3px;
    background: #8f8f8f;
}

.main-banner .tp-bullet.selected {
    background: #ffaf36;
}

.tparrows.tp-leftarrow,
.tparrows.tp-rightarrow {
    border: 1px solid #fff;
    z-index: 99;
    cursor: pointer;
    background: none;
    visibility: hidden;
}

.main-banner:hover .tparrows.tp-leftarrow,
.main-banner:hover .tparrows.tp-rightarrow {
    visibility: visible;
}

.tparrows.tp-leftarrow:hover,
.tparrows.tp-rightarrow:hover {
    background: #2fadb9;
    border: 1px solid #2fadb9;
}

.slotholder:before {
    border-top: none;
    border-bottom: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.69);
    content: '';
}

@media (max-width: 480px) {
    .main-banner .banner-text h2 {
        font-size: 18px;
    }
    .main-banner .banner-text p {
        line-height: 1.2;
        font-size: 15px;
    }
}


/*  Page Intro   ||-----------*/

.inner-intro {
    background-color: #f7f7f7;
    height: 300px;
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.inner-intro.inner-intro-small {
    height: 380px;
}

.inner-intro .container {
    position: relative;
    height: 100%;
    display: table;
}

.inner-intro .container .title {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
}

.bg-img {
    background: url(/img/hosting-slide3.jpg) 0 0 no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
}

.overlay-before:before {
    content: '';
    width: 517px;
    height: 166%;
    position: absolute;
    top: 0;
    left: 0;
    background: #2fadb9;
    transform: rotate(23deg);
    -webkit-transform: rotate(23deg);
    transform-origin: 100% 0;
    -webkit-transform-origin: 100% 0;
}

@media (max-width: 767px) {
    .inner-intro {
        height: 220px;
    }
    .overlay-before:before {
        width: 100%;
        transform: rotate(23deg);
        -webkit-transform: rotate(23deg);
        transform: rotate(0);
        -webkit-transform: rotate(0);
        transform-origin: 100% 0;
        -webkit-transform-origin: 100% 0;
        opacity: 0.9;
    }
}


/*testimonial section style*/

.testimonial_wrapper__block {
    background-image: url(/img/testimonial-bd.jpg);
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    color: #fff;
}

.testimonial_wrapper__block::before {
    background: #f5f5f5 none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.76;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.single_carousel {
    margin: 0px 15px;
}

.single_carousel>p {
    background: #2fadb9 none repeat scroll 0 0;
    padding: 25px 33px 44px;
    position: relative;
}

.single_carousel>p::after {
    background: #00717b none repeat scroll 0 0;
    border-radius: 50%;
    bottom: -23px;
    color: #ffffff;
    content: "\f10d";
    font-family: fontawesome;
    font-size: 18px;
    height: 50px;
    left: 20px;
    line-height: 49px;
    position: absolute;
    text-align: center;
    width: 50px;
}

.author_img__block {
    display: table;
    height: 130px;
    color: #222;
}

.author_tablecell__block {
    display: table-cell;
    vertical-align: middle;
}

.author_tablecell__block>img {
    border: 5px solid #ffffff;
    border-radius: 50%;
    float: left;
    margin-left: 10px;
    max-width: 80px;
}

.author_tablecell__block>p {
    display: inline-block;
    font-weight: 700;
    margin-left: 15px;
    margin-top: 15px;
}

.author_tablecell__block span {
    display: block;
    font-weight: normal;
}

.testimonial_carosule-wrap .owl-nav div {
    border: 1px solid #2fadb9;
    color: #2fadb9;
    display: inline-block;
    font-size: 18px;
    height: 40px;
    margin-left: 10px;
    text-align: center;
    transition: all 0.4s ease 0s;
    width: 40px;
    line-height: 40px;
    position: static;
    background: transparent;
    transform: translateY(0);
    -webkit-transform: translateY(0);
}

.testimonial_carosule-wrap .owl-nav {
    margin-top: 15px;
    text-align: center;
}

.testimonial_carosule-wrap .owl-nav div:hover {
    background: #2fadb9;
    color: #fff;
}

.member_say {
    font-size: 14px;
}


/*=======Counter Section Block======*/

.counter-section__img {
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(/img/bg_1.jpg);
    padding: 150px 0;
    background-attachment: fixed;
}

.counter-section__img:before {
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    background-color: rgba(0, 88, 97, 0.86);
}

.counter-section__box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
}

.counter-section__box .counter {
    color: #fff;
    font-size: 72px;
    -webkit-box-flex: 1;
    -moz-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    line-height: 1.2;
    font-weight: 600;
    padding-right: 16px;
    position: relative;
}

.counter-section__box .counter:after {
    content: '';
    width: 2px;
    height: 64px;
    background: #2fadb9;
    position: absolute;
    top: 17px;
    right: 0;
}

.counter-section__box p {
    color: #fff;
    font-size: 18px;
    padding-top: 25px;
    padding-left: 15px;
    -webkit-box-flex: 2;
    -moz-flex: 2;
    -webkit-flex: 2;
    flex: 2;
    text-transform: uppercase;
    text-align: left;
}


/*Avaiablity Section*/

.available_wrap__block {
    background-image: url(/img/world-map.png);
    height: 500px;
    display: table;
    background-position: center center;
    /* background-size: cover; */
    width: 100%;
    background-repeat: no-repeat;
    background-color: #2fadb9;
}

.available_content__block {
    display: table-cell;
    vertical-align: middle;
    color: #fff;
}

.available_content__block h2 {
    font-size: 35px;
    color: #fff;
    font-weight: 500;
}

@media (max-width: 991px) {
    .available_wrap__block {
        height: auto;
        padding: 40px 0;
    }
    .quote__block input[type="submit"] {
        float: left;
    }
}


/* ----------------------------------------------------------------------*/


/* Pagination Navigation   --------------------------------------------- */


/* ----------------------------------------------------------------------*/

.pagination-nav {
    width: 100%;
    display: block;
    position: relative;
    text-align: center;
}

.pagination-nav ul {
    margin: 0px;
    padding: 0px;
}

.pagination-nav ul li {
    display: inline-block;
    margin: 0px 2px;
}

.pagination-nav ul li a {
    display: block;
    border: 1px solid #2fadb9;
    color: #2fadb9;
    height: 36px;
    width: 36px;
    line-height: 34px;
    text-align: center;
    font-size: 14px;
}

.pagination-nav ul li.active a,
.pagination-nav ul li:hover a {
    background: #2fadb9;
    color: #fff;
}


/* ----------------------------------------------------------------------*/


/* Sidebar Widget   ---------------------------------------------------- */


/* ----------------------------------------------------------------------*/

.sidebar-widget {
    margin-bottom: 50px;
}

.sidebar-widget h4 {
    margin: 0px;
    padding-top: 0;
    padding-bottom: 15px;
}

.widget-search {
    position: relative;
}

.widget-search input[type="text"] {
    padding-right: 30px;
    border: 1px solid #DEDEDE;
    margin-bottom: 0;
}

.widget-search input[type="text"]:focus {
    border: 1px solid #2fadb9;
}

.blog-post-hr input[type="text"],
.blog-post-hr textarea {
    border: 1px solid #DEDEDE;
}

.blog-post-hr input[type="text"]:focus,
.blog-post-hr textarea:focus {
    border: 1px solid #2fadb9;
}

.widget-search input[type="submit"] {
    background-color: rgba(0, 0, 0, 0);
    border: 0 none;
    color: #8d8d8d;
    cursor: pointer;
    display: inline-block;
    float: right;
    font-family: "FontAwesome", sans-serif;
    margin: 0;
    outline: 0 none;
    white-space: nowrap;
    padding: 0 3px;
    text-decoration: none;
    transition: all 0.25s ease 0s;
    -moz-transition: all 0.25s ease 0s;
    -o-transition: all 0.25s ease 0s;
    -webkit-transition: all 0.25s ease 0s;
    position: absolute;
    top: 10px;
    right: 10px;
}

.widget-search input[type="submit"]:hover {
    color: #e6ae49;
}

.sidebar-widget hr {
    margin-bottom: 15px;
}

.sidebar-widget ul {
    margin: 0px;
    padding-left: 0px;
}

.sidebar-widget ul li {
    list-style: none;
    font-weight: 400;
}

.categories li {
    border-bottom: 1px solid #DEDEDE;
}

.sidebar-widget ul li a {
    display: inline-block;
    padding: 4px 0px;
    font-size: 13px;
    color: #323232;
    cursor: pointer;
}

.sidebar-widget .categories li a {
    padding: 10px 0px;
    text-transform: capitalize;
}

.sidebar-widget ul li a:hover {
    color: #2fadb9;
}

.widget-tag {
    display: inline-table;
    margin-top: 15px;
}

.widget-tag li {
    float: left;
    margin-bottom: 5px;
    margin-right: 5px;
    text-transform: uppercase;
}

.widget-tag li a {
    padding: 4px 10px;
    display: block;
    border: 1px solid #2fadb9;
    color: #2fadb9;
}

.sidebar-widget .widget-tag li a:hover {
    background: #2fadb9;
    color: #fff;
}

.sidebar-widget .widget-post {
    position: relative;
}

.sidebar-widget .widget-post .widget-post-media {
    width: 118px;
    display: table-cell;
    padding: 0px 15px 0px 0px;
}

.sidebar-widget .widget-post li {
    margin-bottom: 20px;
}

.sidebar-widget .widget-post a {
    padding: 0px;
}

.sidebar-widget .widget-post .widget-post-media img,
.sidebar-widget .widget-post .widget-post-media video {
    width: 100%;
    vertical-align: top;
}

.sidebar-widget .widget-post .widget-post-info {
    display: table-cell;
    vertical-align: top;
}

.sidebar-widget .widget-post .widget-post-info h5,
.sidebar-widget .widget-post .widget-post-info h6 {
    margin-bottom: 5px;
}

.sidebar-widget .widget-post .widget-post-info p {
    margin: 0px;
}

.sidebar-widget .color-widget {
    overflow: hidden;
}

.sidebar-widget .color-widget li {
    float: left;
    width: 25px;
    height: 25px;
    margin: 0 8px 8px 0;
    border: 1px solid #f1f1f1;
}

.sidebar-widget .red-box {
    background: #FF0000;
}

.sidebar-widget .yellow-box {
    background: #FFD700;
}

.sidebar-widget .blue-box {
    background: #0000FF;
}

.sidebar-widget .green-box {
    background: #006000;
}

.sidebar-widget .orange-box {
    background: #FF4500;
}

.sidebar-widget .light-b-box {
    background: #ADD8E6;
}

.sidebar-widget .black-box {
    background: #000;
}

.sidebar-widget .darkmagenta-box {
    background: #8B008B;
}

.sidebar-widget .darkturquoise-box {
    background: #00CED1;
}

.sidebar-widget .Olive-box {
    background: #00CED1;
}

.sidebar-widget .Orchid-box {
    background: #DA70D6;
}

.sidebar-widget .purple-box {
    background: #600080;
}

.sidebar-widget .darkturquoise-box {
    background: #8B4513;
}

.sidebar-widget .magenta-box {
    background: #FF00FF;
}

.sidebar-widget .SaddleBrown-box {
    background: #8B4513;
}


/***

 ====================================================================
 Single-Blog-Post
 ====================================================================

 ***/

.single-blog-post .img-box {
    position: relative;
    display: inline-block;
    width: 100%;
}

.single-blog-post .img-box img {
    width: 100%;
}

.single-blog-post .img-box .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(251, 202, 0, 0.8);
    transform: scale(0);
    -webkit-transform: scale(0);
    transition: all .5s ease;
}

.single-blog-post:hover .img-box .overlay {
    transform: scale(1);
    -webkit-transform: scale(1);
}

.single-blog-post .img-box .overlay .box {
    display: table;
    width: 100%;
    height: 100%;
}

.single-blog-post .img-box .overlay .box .content {
    display: table-cell;
    vertical-align: middle;
}

.single-blog-post .img-box .overlay .box .content ul {
    text-align: center;
    margin: 0;
    padding: 0;
}

.single-blog-post .img-box .overlay .box .content ul li {
    display: inline-block;
    list-style: none;
}

.single-blog-post .img-box .overlay .box .content ul li a {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 8px;
    background: #fff;
    color: #ffb606;
    border: 1px solid #fff;
    line-height: 38px;
    font-size: 18px;
    border-radius: 50%;
    transition: all .3s ease;
}

.single-blog-post .img-box .overlay .box .content ul li a:hover {
    background: #ffb606;
    border-color: #fff;
    color: #fff;
}

.single-blog-post .content-box .date-box,
.single-blog-post .content-box .content {
    display: table-cell;
    vertical-align: top;
}

.single-blog-post .content-box .date-box {
    padding-right: 25px;
}

.single-blog-post .content-box .date-box .inner {
    width: 65px;
    text-align: center;
    border: 2px solid #ffb606;
    background: #ffb606;
}

.single-blog-post .content-box .date-box .inner .date {
    padding: 15px 0;
}

.single-blog-post .content-box .date-box .inner .date b {
    display: block;
    font-size: 24px;
    font-weight: 500;
    color: #333;
    line-height: 20px;
}

.single-blog-post .content-box .date-box .inner .date {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    color: #fff;
    line-height: 20px;
}

.single-blog-post .content-box .date-box .comment {
    font-size: 20px;
    color: #202A30;
    line-height: 17px;
    font-weight: 500;
    padding: 16px 0;
    border: 1px solid #E6E6E6;
    border-top: 0px;
}

.single-blog-post .content-box .date-box .comment i:before {
    color: #374045;
    font-size: 16px;
}

.single-blog-post .content-box .content h3 {
    font-size: 20px;
    color: #222;
    text-transform: capitalize;
    margin: 0;
    line-height: 26px;
    font-weight: bold;
    margin-bottom: 12px;
}

.single-blog-post .content-box .content p {
    font-size: 15px;
}

.single-blog-post .content-box .content span {
    color: #222222;
    font-size: 13px;
}

.single-blog-post .content-box .content span a {
    color: #ffb606;
}

.single-blog-post .share {
    margin: 0;
    padding: 0;
}

.single-blog-post .share li {
    display: inline;
    list-style: none;
}

.single-blog-post .share li a,
.single-blog-post .share li span {
    display: inline-block;
    color: #D5DADD;
    font-size: 16px;
    margin-right: 10px;
}

.single-blog-post .share li a:hover {
    color: #ffb606;
}

.single-blog-post .share li span {
    color: #222222;
    font-size: 12px;
    text-transform: capitalize;
}

.single-blog-post .bottom-box {
    margin-top: 35px;
}

.blog-page .single-blog-post,
.blog-details .admin-info,
.blog-details .comment-box {
    padding-bottom: 0px;
    margin-bottom: 60px;
    border-bottom: 1px solid #F1F1F1;
}

.blog-details .admin-info .img-box,
.blog-details .admin-info .content {
    display: table-cell;
    vertical-align: top;
}

.blog-details .admin-info .inner-box {
    width: 100px;
}

.blog-details .admin-info .content {
    padding-left: 45px;
}

.blog-details .admin-info .content h3 {
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    margin: 0;
    margin-bottom: 10px;
    text-transform: capitalize;
    color: #222E35;
}

.blog-details .admin-info .social {
    padding: 0;
    margin: 0;
}

.blog-details .admin-info .social li {
    display: inline-block;
    list-style: none;
}

.blog-details .admin-info .social li a {
    font-size: 16px;
    margin-right: 7px;
    color: #D5DADD;
}

.blog-details .comment-box .title {
    margin-bottom: 50px;
}

.blog-details .comment-box .title h2 {
    font-size: 32px;
    text-transform: capitalize;
    color: #272727;
    font-weight: bold;
    margin: 0;
    line-height: 25px;
}

.blog-details .comment-box .single-comment-box {
    margin-bottom: 34px;
}

.blog-details .comment-box .single-comment-box:last-child {
    margin-bottom: 0;
}

.blog-details .comment-box .single-comment-box .img-box,
.blog-details .comment-box .single-comment-box .content-box {
    display: table-cell;
    vertical-align: top;
}

.blog-details .comment-box .single-comment-box .img-box .inner-box {
    width: 70px;
    margin-right: 30px;
}

.blog-details .comment-box .single-comment-box .content-box h3 {
    color: #272727;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: bold;
    line-height: 20px;
    margin: 0;
}

.blog-details .comment-box .single-comment-box .content-box span {
    color: #272727;
    display: inline-block;
    font-weight: 500;
}

.blog-details .comment-box .single-comment-box .content-box p {
    color: #9C9C9C;
    line-height: 26px;
}

.blog-details .comment-box .single-comment-box .content-box a.reply {
    font-size: 13px;
    font-weight: bold;
    font-family: 'Raleway';
    text-transform: uppercase;
    color: #262F36;
}

.blog-details .comment-box .single-comment-box .content-box .meta-box {
    margin-bottom: 15px;
    margin-top: 8px;
}

.blog-details .contact-form {
    position: relative;
    background: #fff;
    color: #000;
    padding: 40px 20px;
    background: #f4f4f4;
}

.blog-details .contact-content h2 {
    color: #23282A;
    font-size: 30px;
    text-transform: capitalize;
    font-weight: 600;
    margin: 0;
    margin-bottom: 35px;
}

.blog-details .contact-content .contact-form textarea,
.blog-details .contact-content .contact-form input {
    width: 100%;
    height: 60px;
    border: 1px solid #F0F0F0;
    outline: none;
    padding-left: 20px;
    line-height: 58px;
    margin-bottom: 20px;
    background: #fff;
}

.blog-details .contact-content .contact-form textarea {
    height: 221px;
}


/***

 ====================================================================
 single-blog-post
 ====================================================================

 ***/

.single-blog-post .content-box {
    border: 1px solid #eee;
    margin-top: px;
    padding: 30px 0;
}

.single-blog-post .img-box {
    position: relative;
    display: inline-block;
}

.single-blog-post .img-box img {
    width: 100%;
}

.single-blog-post .img-box .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(33, 33, 33, 0.50);
    transform: scale(0);
    -webkit-transform: scale(0);
    transition: all .5s ease;
}

.single-blog-post:hover .img-box .overlay {
    transform: scale(1);
    -webkit-transform: scale(1);
}

.single-blog-post .img-box .overlay .box {
    display: table;
    width: 100%;
    height: 100%;
}

.single-blog-post .img-box .overlay .box .content {
    display: table-cell;
    vertical-align: middle;
}

.single-blog-post .img-box .overlay .box .content ul {
    text-align: center;
    margin: 0;
    padding: 0;
}

.single-blog-post .img-box .overlay .box .content ul li {
    display: inline-block;
    list-style: none;
}

.single-blog-post .img-box .overlay .box .content ul li a {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 8px;
    background: #fff;
    color: #2fadb9;
    border: 1px solid #fff;
    line-height: 38px;
    font-size: 18px;
    border-radius: 50%;
    transition: all .3s ease;
}

.single-blog-post .img-box .overlay .box .content ul li a:hover {
    background: #2fadb9;
    border-color: #fff;
    color: #fff;
}

.single-blog-post .content-box .date-box,
.single-blog-post .content-box .content {
    display: table-cell;
    vertical-align: top;
}

.single-blog-post .content-box .date-box {
    padding-right: 25px;
}

.single-blog-post .content-box .date-box .inner {
    border: 1px solid #2fadb9;
    text-align: center;
    width: 65px;
    background: #fff;
}

.single-blog-post .content-box .date-box .inner .date {
    padding: 8px 0;
}

.single-blog-post .content-box .date-box .inner .date b {
    display: block;
    font-size: 24px;
    font-weight: 500;
    color: #2fadb9;
    line-height: 20px;
}

.single-blog-post .content-box .date-box .inner .date {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    color: #2fadb9;
    line-height: 20px;
}

.single-blog-post .content-box .date-box .comment {
    font-size: 20px;
    color: #202A30;
    line-height: 17px;
    font-weight: 500;
    padding: 16px 0;
    border: 1px solid #E6E6E6;
    border-top: 0px;
}

.single-blog-post .content-box .date-box .comment i:before {
    color: #374045;
    font-size: 16px;
}

.single-blog-post .content-box .content h3 {
    color: #222;
    line-height: 26px;
    margin: 0 0 15px;
    text-transform: capitalize;
}

.single-blog-post .content-box .content p {
    line-height: 26px;
}

.single-blog-post .content-box .content span {
    color: #222222;
    font-size: 13px;
}

.single-blog-post .content-box .content span a {
    color: #2fadb9;
}

.single-blog-post .share {
    margin: 0;
    padding: 0;
}

.single-blog-post .share li {
    display: inline;
    list-style: none;
}

.single-blog-post .share li a,
.single-blog-post .share li span {
    display: inline-block;
    color: #D5DADD;
    font-size: 16px;
    margin-right: 10px;
}

.single-blog-post .share li a:hover {
    color: #ffb606;
}

.single-blog-post .share li span {
    color: #222222;
    font-size: 12px;
    text-transform: capitalize;
}

.single-blog-post .bottom-box {
    margin-top: 35px;
}

.entry-meta {
    margin-bottom: 10px;
    margin-top: 10px;
}

.entry-meta i {
    font-size: 14px;
    margin-right: 5px;
}

.entry-meta li {
    margin-left: 5px;
}

.entry-meta li:first-child {
    margin-left: 0;
}

.entry-meta li a {
    color: #777;
    font-size: 13px;
}

.entry-meta a:hover,
.entry-meta li a:hover {
    color: #2fadb9;
}

.blogbtn {
    background: #2fadb9 none repeat scroll 0 0;
    border: 2px solid #2fadb9;
    border-radius: 3px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 24px;
    padding: 10px 22px;
    position: relative;
    text-transform: capitalize;
    display: inline-block;
}

.blogbtn:hover {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border-color: #2fadb9;
    color: #333;
}

.blogbtn:hover i {
    color: #000 !important;
}

.blogbtn .icon {
    color: #fff;
    padding-left: 2px;
    position: relative;
    top: 1px;
}

.blog-page .single-blog-post,
.blog-details .admin-info,
.blog-details .comment-box {
    padding-bottom: 0px;
    margin-bottom: 60px;
    border-bottom: 1px solid #F1F1F1;
}

.blog-details .content {
    padding-right: 20px;
}

.blog-details .admin-info .img-box,
.blog-details .admin-info .content {
    display: table-cell;
    vertical-align: top;
}

.blog-details .content h3 {
    font-size: 24px !important;
}

.blog-details .admin-info .inner-box {
    width: 100px;
}

.blog-details .admin-info .content {
    padding-left: 45px;
}

.blog-details .admin-info .content h3 {
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    margin: 0;
    margin-bottom: 10px;
    text-transform: capitalize;
    color: #222E35;
}

.blog-details .admin-info .social {
    padding: 0;
    margin: 0;
}

.blog-details .admin-info .social li {
    display: inline-block;
    list-style: none;
}

.blog-details .admin-info .social li a {
    font-size: 16px;
    margin-right: 7px;
    color: #D5DADD;
}

.blog-details .comment-box .title {
    margin-bottom: 50px;
}

.blog-details .comment-box .title h2 {
    font-size: 32px;
    text-transform: capitalize;
    color: #272727;
    font-weight: bold;
    margin: 0;
    line-height: 25px;
}

.blog-details .comment-box .single-comment-box {
    margin-bottom: 34px;
}

.blog-details .comment-box .single-comment-box:last-child {
    margin-bottom: 0;
}

.blog-details .comment-box .single-comment-box .img-box,
.blog-details .comment-box .single-comment-box .content-box {
    display: table-cell;
    vertical-align: top;
}

.blog-details .comment-box .single-comment-box .img-box .inner-box {
    width: 70px;
    margin-right: 30px;
}

.blog-details .comment-box .single-comment-box .content-box h3 {
    color: #272727;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: bold;
    line-height: 20px;
    margin: 0;
}

.blog-details .comment-box .single-comment-box .content-box span {
    color: #272727;
    display: inline-block;
    font-weight: 500;
}

.blog-details .comment-box .single-comment-box .content-box p {
    color: #9C9C9C;
    line-height: 26px;
}

.blog-details .comment-box .single-comment-box .content-box a.reply {
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    color: #999;
    background: #f4f4f4;
    padding: 0px 10px;
}

.blog-details .comment-box .single-comment-box .content-box .meta-box {
    margin-bottom: 15px;
    margin-top: 8px;
}

.blog-details .comment-box .single-comment-box {
    margin-bottom: 34px;
}

.single-comment-box.reply-comment {
    padding-left: 100px;
}


/*  Partner Section   ||-----------*/

.logo-group {
    overflow: hidden;
    padding: 0;
    border: 1px solid #ddd;
    border-right: none;
    border-bottom: none;
    position: relative;
}

.logo-group li {
    width: 20%;
    float: left;
    height: 150px;
    list-style: none;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.logo-group li img {
    display: block;
    margin: 0 auto;
}

@media only screen and (max-width: 767px) {
    .logo-group li {
        width: 50%;
    }
}

@media only screen and (max-width: 500px) {
    .logo-group li {
        width: 100%;
    }
}

.client-avtar {
    position: absolute;
    left: 42px;
    top: -36px;
    width: 70px;
    height: 70px;
    border: 2px solid #2fadb9;
}

.right-pos {
    left: auto;
    right: 42px;
}

.bg-dark {
    position: relative;
}

#testimonial .owl-carousel .owl-item {
    opacity: 0.5;
    transform: scale(0.8);
    transition: all 0.3s ease-in;
}

#testimonial .owl-carousel .owl-item.center {
    opacity: 1;
    transform: scale(1);
    transition: all 0.3s ease-out;
}


/*  Time Line Section  ||-----------*/

.btn-icon {
    padding: 0;
    display: inline-block;
    text-align: center;
    border-radius: 2px;
    height: 35px;
    width: 35px;
    line-height: 35px
}

.btn-icon i {
    line-height: 35px
}

.btn-icon.btn-icon-lined {
    line-height: 31px
}

.btn-icon.btn-icon-lined i {
    line-height: 31px
}

.btn-icon.btn-icon-lined.btn-icon-thin {
    line-height: 33px
}

.btn-icon.btn-icon-lined.btn-icon-thin i {
    line-height: 33px
}

.btn-icon-lined {
    display: inline-block;
    text-align: center;
    border-radius: 2px;
    background-color: #fff;
    border-width: 2px;
    border-style: solid;
    border-color: #3D4051;
    color: #3D4051;
    height: 35px;
    width: 35px;
    line-height: 35px
}

.btn-icon-lined i {
    line-height: 35px
}

.btn-icon-lined.btn-icon-lined {
    line-height: 31px
}

.btn-icon-lined.btn-icon-lined i {
    line-height: 31px
}

.btn-icon-lined.btn-icon-lined.btn-icon-thin {
    line-height: 33px
}

.btn-icon-lined.btn-icon-lined.btn-icon-thin i {
    line-height: 33px
}

.btn-icon-lined.btn-icon-thin {
    border-width: 1px
}

.btn-icon-lined:hover {
    background-color: #fff;
    color: #fff
}

.btn-icon-lined.btn-default-light {
    color: #bbb;
    border: 2px solid #bbb
}

.btn-icon-lined.btn-default-light:hover {
    color: #bbb;
    border: 2px solid #bbb
}

.btn-icon-lined.btn-default {
    color: #777;
    border-color: #777
}

.btn-icon-lined.btn-default:hover {
    color: #777;
    border-color: #777
}

.btn-icon-lined.btn-primary {
    color: #009688;
    border-color: #009688
}

.btn-icon-lined.btn-success {
    color: #8BC34A;
    border-color: #8BC34A
}

.btn-icon-lined.btn-info {
    color: #00BCD4;
    border-color: #00BCD4
}

.btn-icon-lined.btn-warning {
    color: #FFCA28;
    border-color: #FFCA28
}

.btn-icon-lined.btn-danger {
    color: #F44336;
    border-color: #F44336
}

.btn-icon-round {
    border-radius: 50%
}

.btn-icon-sm {
    height: 30px;
    width: 30px;
    line-height: 30px
}

.btn-icon-sm i {
    line-height: 30px
}

.btn-icon-sm.btn-icon-lined {
    line-height: 26px
}

.btn-icon-sm.btn-icon-lined i {
    line-height: 26px
}

.btn-icon-sm.btn-icon-lined.btn-icon-thin {
    line-height: 28px
}

.btn-icon-sm.btn-icon-lined.btn-icon-thin i {
    line-height: 28px
}

.btn-icon-md {
    height: 45px;
    width: 45px;
    line-height: 45px;
    font-size: 18px
}

.btn-icon-md i {
    line-height: 45px
}

.btn-icon-md.btn-icon-lined {
    line-height: 41px
}

.btn-icon-md.btn-icon-lined i {
    line-height: 41px
}

.btn-icon-md.btn-icon-lined.btn-icon-thin {
    line-height: 43px
}

.btn-icon-md.btn-icon-lined.btn-icon-thin i {
    line-height: 43px
}

.btn-icon-lg {
    height: 65px;
    width: 65px;
    line-height: 65px;
    font-size: 28px
}

.btn-icon-lg i {
    line-height: 65px
}

.btn-icon-lg.btn-icon-lined {
    line-height: 61px
}

.btn-icon-lg.btn-icon-lined i {
    line-height: 61px
}

.btn-icon-lg.btn-icon-lined.btn-icon-thin {
    line-height: 63px
}

.btn-icon-lg.btn-icon-lined.btn-icon-thin i {
    line-height: 63px
}

.btn-icon-lg-alt {
    height: 70px;
    width: 70px;
    line-height: 70px
}

.btn-icon-lg-alt i {
    line-height: 70px
}

.btn-icon-lg-alt.btn-icon-lined {
    line-height: 66px
}

.btn-icon-lg-alt.btn-icon-lined i {
    line-height: 66px
}

.btn-icon-lg-alt.btn-icon-lined.btn-icon-thin {
    line-height: 68px
}

.btn-icon-lg-alt.btn-icon-lined.btn-icon-thin i {
    line-height: 68px
}

.ui-timline-left .ui-timeline:before {
    left: 0
}

@media (min-width: 768px) {
    .ui-timline-left .ui-timeline .tl-item:before {
        display: none
    }
    .ui-timline-left .ui-timeline .tl-item .tl-caption {
        margin-left: -55px
    }
    .ui-timline-left .ui-timeline .tl-item .tl-body .tl-time {
        left: auto;
        right: 15px;
        color: #777
    }
}

.ui-timeline {
    display: table;
    position: relative;
    table-layout: fixed;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse
}

.ui-timeline:before {
    background-color: #d5d5d5;
    bottom: 0px;
    content: "";
    position: absolute;
    left: 0;
    top: 30px;
    width: 1px;
    z-index: 0
}

@media (min-width: 768px) {
    .ui-timeline:before {
        left: 50%
    }
}

.ui-timeline .tl-item {
    display: table-row;
    margin-bottom: 5px
}

.ui-timeline .tl-item:before {
    display: none;
    content: ""
}

@media (min-width: 768px) {
    .ui-timeline .tl-item:before {
        display: block;
        width: 50%
    }
}

.ui-timeline .tl-item .tl-caption {
    width: 150px;
    margin-left: -55px
}

@media (min-width: 768px) {
    .ui-timeline .tl-item .tl-caption {
        margin-left: -110px
    }
}

@media (min-width: 768px) {
    .ui-timeline .tl-item.alt {
        text-align: right
    }
    .ui-timeline .tl-item.alt:before {
        display: none
    }
    .ui-timeline .tl-item.alt:after {
        content: "";
        display: block;
        width: 50%
    }
    .ui-timeline .tl-item.alt .tl-body .tl-entry {
        margin: 0 35px 15px 0
    }
    .ui-timeline .tl-item.alt .tl-body .tl-time {
        right: -220px;
        left: auto;
        text-align: left
    }
    .ui-timeline .tl-item.alt .tl-body .tl-icon {
        right: -53px;
        left: auto
    }
    .ui-timeline .tl-item.alt .tl-body .tl-content:after {
        right: -16px;
        left: auto;
        border: 8px solid transparent;
        border-left: 8px solid rgba(255, 255, 255, 0.9);
        border-top: 8px solid rgba(255, 255, 255, 0.9)
    }
}

.ui-timeline .tl-item .tl-body {
    display: table-cell;
    width: 50%;
    vertical-align: top
}

.ui-timeline .tl-item .tl-body .tl-entry {
    position: relative;
    margin: 0 0 35px 36px
}

.ui-timeline .tl-item .tl-body .tl-entry .btn-primary {
    background-color: #2fadb9;
    border-color: #2fadb9;
    padding: 10px 12px;
}

.ui-timeline .tl-item .tl-body .tl-time {
    z-index: 1;
    position: absolute;
    left: auto;
    right: 15px;
    top: 0;
    width: 150px;
    color: #777;
    line-height: 35px;
    text-align: right
}

@media (min-width: 768px) {
    .ui-timeline .tl-item .tl-body .tl-time {
        left: -220px;
        right: auto;
        color: #3D4051
    }
}

.ui-timeline .tl-item .tl-body .tl-icon {
    position: absolute;
    left: -53px;
    top: 0
}

.ui-timeline .tl-item .tl-body .tl-content {
    position: relative;
    padding: 15px;
    border-radius: 2px;
    background-color: #f5f5f5;
}

.ui-timeline .tl-item .tl-body .tl-content:after {
    content: " ";
    line-height: 0;
    position: absolute;
    left: -16px;
    top: 0;
    border: 8px solid transparent;
    border-right: 8px solid rgba(255, 255, 255, 0.9);
    border-top: 8px solid rgba(255, 255, 255, 0.9)
}

.tooltip-inner {
    padding: .85em
}

.ui-timeline {
    display: table;
    position: relative;
    table-layout: fixed;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse
}

.ui-timeline:before {
    background-color: #d5d5d5;
    bottom: 0px;
    content: "";
    position: absolute;
    left: 0;
    top: 30px;
    width: 1px;
    z-index: 0
}

@media (min-width: 768px) {
    .ui-timeline:before {
        left: 50%
    }
}

.ui-timeline .tl-item {
    display: table-row;
    margin-bottom: 5px
}

.ui-timeline .tl-item:before {
    display: none;
    content: ""
}

@media (min-width: 768px) {
    .ui-timeline .tl-item:before {
        display: block;
        width: 50%
    }
}

@media (max-width: 767px) {
    .ui-timeline .tl-item .tl-caption {
        margin-left: -39px;
    }
    .ui-timeline .tl-item .tl-body .tl-icon {
        left: -48px;
    }
}

.bg-gray {
    color: #000;
    background-color: #EFF0F1;
}


/*  Team Section  ||-----------*/

.designation {
    display: block;
    text-transform: capitalize;
    font-size: 15px;
}


/*  Serve Section  ||-----------*/

#serve-section img {
    width: 100%;
}


/*  Faq Page  ||-----------*/

.faq h3 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 38px;
    text-transform: capitalize;
}

.faq .page-header {
    margin: 0;
}

.faq .faq-header h2 strong {
    border-bottom-style: solid;
    border-bottom-width: 2px;
}

.faq .faq-header h2 {
    margin-bottom: 27px;
    font-weight: 300;
}

.faq .faq-header {
    margin-bottom: 0;
}

.faq .panel-title>a {
    display: block
}

.faq .panel-group .panel+.panel {
    margin-top: 14px;
}

.faq .panel-group .panel-heading+.panel-collapse>.panel-body {
    border: none;
    padding: 0 48px 0 18px;
    background: #f2ede9;
}

.faq .panel-default>.panel-heading {
    padding: 0;
    background: #f2ede9;
    margin-bottom: 2px;
}

.faq .panel-default>.panel-heading a {
    padding: 13px 60px 14px 28px;
    color: #26211d;
    position: relative;
}

.faq .panel-default>.panel-heading a:hover {
    text-decoration: none;
}

.faq .panel-default>.panel-heading a.collapsed {
    background-position: right top
}

.faq .panel-group .panel {
    border-radius: 0;
    border: none;
    box-shadow: none
}

.faq .panel-group .panel p {
    font-weight: 300;
}

.save-lives.ask-us {
    background: #faf8f6;
}

.save-lives.ask-us h2 {
    font-weight: 300;
    margin-bottom: 30px;
}

.faq .panel-heading {
    border-radius: 0;
}

.faq .collape-plus {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 48px;
    height: 100%;
    text-align: center;
}

.collape-plus:before {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}

.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit;
    font-weight: 400;
    text-transform: capitalize;
}

.faq .collape-plus {
    background: #2fadb9;
    color: #fff;
}

.panel-title a[aria-expanded="true"] .fa-plus:before {
    content: "\f068";
    font-famiyl: 'FontAwesome';
}


/*  404 page         ||---------------------------- */

.big-font {
    font-size: 130px;
    margin-bottom: 22px;
    display: block;
}

.big-font i {
    color: #2fadb9;
}

.page-404 {
    border-top: 1px solid #f1f1f1;
}


/* Blog Page   --------------------------------------------------------- */

.blog-post {
    display: inline-block;
    width: 100%;
}

.blog-post .post-media {
    position: relative;
    overflow: hidden;
    margin-bottom: 25px;
}

.blog-post .post-media iframe {
    width: 100%;
}

.blog-post .post-meta,
.comment-list .post-meta,
.sidebar-widget .post-meta {
    text-transform: uppercase;
    color: #999;
    font-size: 13px;
}

.post-header h4 {
    font-size: 21px;
}

.post-meta span>a>i {
    color: #2fadb9;
}

.blog-post .post-meta span,
.comment-list .post-meta span {
    margin-right: 3px;
}

.blog-post .post-header h5,
.blog-post .post-header h4,
.blog-post .post-header h3 {
    margin-top: 10px;
    margin-bottom: 12px;
}

.blog-post .post-header h2 {
    margin-bottom: 30px;
    margin-top: 10px;
}

.blog-post .post-entry {
    margin-bottom: 30px;
}

.blog-post .post-entry p,
.blog-post .post-entry blockquote,
.post-entry ul {
    margin-bottom: 20px;
}

.blog-post .post-entry ul {
    color: #202020;
}

.lead-point li {
    list-style: none;
    margin-bottom: 10px;
    color: #2fadb9;
    font-weight: 600;
}

.lead-point li i {
    margin-right: 10px;
}

.blog-post .post-tag {
    color: #999;
    text-transform: uppercase;
    font-size: 13px;
}

.blog-post .post-tag span {
    margin-right: 10px;
}

.blog-post .post-more-link {
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.post-meta .post-more-link a {
    padding: 8px 18px;
    display: block;
    text-align: center;
    min-width: 0;
    height: auto;
    line-height: inherit;
}

.blog-post .post-more-link {
    position: relative;
    z-index: 2;
}

.blog-post .post-more-link a:hover i {
    color: #2fadb9;
}

.blog-post .post-more-link a i {
    font-size: 18px;
    margin: 0;
    background: none;
    color: #fff;
}

.blog-post .icons-hover-black {
    position: absolute;
    bottom: 39px;
    padding: 10px 0;
    visibility: hidden;
}

.post-section .post-more-link {
    margin-top: 17px;
}

.post-section .blog-post .icons-hover-black {
    bottom: auto;
    top: 39px;
}

.blog-section .icons-hover-black {
    bottom: auto;
    top: 39px;
}

.blog-post .post-more-link:hover .icons-hover-black {
    visibility: visible;
}

.blog-post .post-more-link:hover .btn-color-line {
    background: #2fadb9;
    color: #fff;
}

.blog-post .icons-hover-black a {
    opacity: 0;
    transition: all 0.25s ease 0s;
}

.blog-post .post-more-link:hover .icons-hover-black a {
    opacity: 1;
    transition: all 0.25s ease 0s;
}

.facebook-icon {
    background: #3b5998;
}

.twitter-icon {
    background: #00a0d1;
}

.linkedin-icon {
    background: #00a0d1;
}

.googleplus-icon {
    background: #db4a39;
}

.blog-post .icons-hover-black a {
    padding: 8px 12px;
    color: #fff;
}

.blog-post .icons-hover-black a:hover {
    opacity: 0.8;
}

.blog-post .post-more-link .icons-hover-black i {
    line-height: 28px;
}

.blog-post blockquote {
    border-left: none;
    color: #fff;
    font-size: 17px;
    font-weight: 400;
    padding: 25px 25px;
    margin: 0px;
    font-weight: 300;
}

.blog-post blockquote:after {
    content: "";
    font-family: FontAwesome;
    padding-left: 7px;
    font-size: 12px;
}

.blog-post blockquote:before {
    content: "";
    font-family: FontAwesome;
    padding-right: 7px;
    font-size: 12px;
}

.blog-post-hr hr {
    margin-bottom: 45px;
}

.post-author {
    display: inline-block;
    position: relative;
    margin-bottom: 40px;
}

.post-author img {
    width: 120px;
}

.post-author .post-author-details {
    padding-left: 20px;
}

@media (max-width: 767px) {
    .post-author .post-author-details {
        padding-left: 0;
        padding-top: 25px;
    }
}

.blog-post .fa-tag {
    padding-right: 10px;
    color: #2fadb9;
}

.post-author h6 {
    margin-top: 0px;
    margin-bottom: 0;
}

.post-author-blog {
    overflow: hidden;
}

.post-author ul.social {
    margin: 0px;
    padding: 0px;
    font-size: 16px;
}

.post-author ul.social li {
    list-style: none;
    display: inline-block;
    margin: 0 5px;
}

.post-comment {}

.post-comment .comment-numb {
    font-weight: 300;
}

.post-comment .comment-list {
    margin-left: 0px;
    padding-right: 0px;
    list-style: none;
    padding: 0px;
}

.post-comment .comment-list h6 {
    margin-top: 0px;
    margin-bottom: 3px;
}

.post-comment .comment-list li:first-child {
    padding-top: 0px;
}

.post-comment .comment-list li {
    padding-top: 15px;
}

.post-comment .comment-list li:last-child .comment-detail {
    border-bottom: 0px none;
}

.post-comment .comment-list li,
.post-comment .comment-list .comment-reply {
    position: relative;
    padding-left: 100px;
}

.post-comment .comment-list .comment-detail {
    border-bottom: 1px dotted #eee;
    padding-bottom: 24px;
}

.post-comment .comment-list .comment-reply {
    padding-top: 30px;
}

.post-comment .comment-list .comment-reply-btn i {
    padding-right: 3px;
}

.post-comment .comment-avatar {
    left: 0;
    margin-right: 15px;
    position: absolute;
    width: 85px;
}

.post-comment .comment-avatar img {
    width: 100%;
}

.post-comment-star .star-rat {
    display: inline-block;
}

.post-comment-star label {
    margin-right: 6px;
}

.blog-date {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #2fadb9;
    color: #fff;
}

.post-media {
    position: relative;
}

.post-media .event-calender,
.first-event .event-calender {
    width: 54px;
    height: 67px;
    font-size: 23px;
    padding-top: 11px;
    z-index: 9;
    text-align: center;
}

.post-media .event-calender span,
.first-event .event-calender span {
    font-size: 12px;
}

.blog-post .blog-date span,
.post-media .event-calender span {
    color: #fff;
}

.post-media img {
    display: block;
}

.event-calender span {
    font-size: 14px;
    color: #fff;
    display: block;
    text-transform: uppercase;
    margin-top: 0;
    margin-right: 0;
}


/*  Register and login Pages   ||-----------*/

.page-signin .main-body {
    max-width: 480px;
    margin: 10px auto;
}

.page-signin .body-inner {
    position: relative;
    padding: 0 20px 20px;
}

.card {
    position: relative;
    overflow: hidden;
    margin: 10px 0 20px 0;
    border-radius: 2px;
}

.mdl-shadow--2dp,
.card-panel,
.card {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.bg-white {
    background-color: #fff;
    color: #3D4051;
}

.z-depth-2,
.page-signin .card {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card .card-content {
    padding: 20px;
    border-radius: 0 0 2px 2px;
}

.page-signin .card .card-content {
    padding: 30px 20px;
}

.page-signin .logo {
    font-size: 26px;
    font-weight: normal;
}

.page-signin .form-group {
    position: relative;
    margin: 0;
}

.ui-input-group {
    position: relative;
    margin: 18px 0 12px;
}

.card .card-action {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: 20px;
}

.card .card-action.no-border {
    border: 0;
}

.color-primary,
a.color-primary {
    color: #2fadb9;
}

.card .card-action>a {
    margin-right: 20px;
    text-transform: uppercase;
}

.page-signin .additional-info {
    text-align: center;
    font-size: 12px;
}

.page-signin .additional-info a {
    color: #fafafa;
}

.page-signin .additional-info .divider-h {
    border-right: 1px solid #fafafa;
    margin: 0 15px;
}

.page-signin .additional-info {
    text-align: center;
    font-size: 12px;
}

.page-signin .body-inner:before {
    z-index: -1;
    content: ' ';
    line-height: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #2fadb9;
    height: 220px;
}

.ui-input-group>input {
    padding-left: 0;
    padding-right: 0;
    border: none;
    box-shadow: 0 0 0 #fff;
    border: none;
    border-bottom: 1px solid #CBD5DD;
    margin: 0;
}

.ui-input-group>input:focus {
    border-bottom: 1px solid #CBD5DD;
    box-shadow: 0 0 0 #fff;
}

.page-signin .ui-input-group .form-control {
    color: #555;
}

.ui-input-group .input-bar {
    position: relative;
    display: block;
    width: 100%;
}

.ui-input-group .input-bar:before {
    left: 50%;
}

.ui-input-group .input-bar:before,
.ui-input-group .input-bar:after {
    content: '';
    height: 1px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #2fadb9;
    -webkit-transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    -moz-transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

.ui-input-group .input-bar:after {
    right: 50%;
}

.ui-input-group .input-bar:before,
.ui-input-group .input-bar:after {
    content: '';
    height: 1px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #2fadb9;
    -webkit-transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    -moz-transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

.ui-input-group>label {
    color: #999;
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 10px;
    -webkit-transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    -moz-transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    margin: 0;
}

.ui-input-group>input:focus~label,
.ui-input-group>input:valid~label {
    top: -20px;
    left: 0;
    font-size: 12px;
}

.ui-input-group>input:focus~label,
.ui-input-group>textarea:focus~label {
    color: #2fadb9;
}

.ui-input-group>input:focus~.input-bar:before,
.ui-input-group>input:focus~.input-bar:after {
    width: 50%;
}

.ui-input-group>input:focus~.input-bar:before,
.ui-input-group>input:focus~.input-bar:after {
    width: 50%;
}

.checkbox-field label {
    font-size: 12px;
}

.checkbox-field #check_box {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0
}

.custom-check {
    width: 20px;
    height: 20px;
    display: inline-block;
    border: 1px solid #2fadb9;
    top: 4px;
    position: relative;
    margin-right: 10px;
}

.custom-check:before {
    content: "";
    font-family: "Ionicons";
    color: #2fadb9;
    font-size: 24px;
    position: absolute;
    top: -3px;
    left: 5px;
}

.checkbox-field #check_box:checked+.custom-check:before {
    content: "\f3fd";
}

.spacer {
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
}


/*Newslatter*/

.newsletter {
    position: relative;
}

.newsletter form {
    position: relative;
    margin-top: 20px;
}

.newsletter .newsletter-input {
    appearance: none;
    -webkit-appearance: none;
    border: 1px solid #fff;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    color: #202020;
    outline: none;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 13px;
    padding: 12px 15px;
    transition: all 0.2s ease 0s;
    -moz-transition: all 0.2s ease 0s;
    -webkit-transition: all 0.2s ease 0s;
    -o-transition: all 0.2s ease 0s;
    display: inline-block;
}

.newsletter .newsletter-input,
.newsletter .newsletter-input:focus {
    width: 100%;
    border-top: 0px none;
    border-left: 0px none;
    border-right: 0px none;
    padding-right: 40px;
    background: none;
    border-radius: 0;
    border: 1px solid #adadad;
    color: #222;
}

.newsletter .newsletter-btn {
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    font-size: 18px;
    background: #2fadb9;
    color: #fff;
    line-height: 31px;
    border-radius: 0;
    width: 49px;
}


/*project*/

#project-section .italic {
    margin-bottom: 8px;
}

#project-section .grid figure {
    float: none
}

#project-section:hover .owl-prev,
#project-section:hover .owl-next {
    width: 40px;
}


/*======================*/

.block_wrapper {
    overflow: hidden;
    transform: translateZ(0)
}

.box-block {
    border: 1px solid #ddd;
}

.title-box {
    position: relative;
    padding-left: 70px;
}

.box-icon {
    position: absolute;
    top: 0;
    left: 0;
}

.box-icon {
    color: #4b4e53;
    font-size: 54px;
    -webkit-transition: prop time;
    transition: all 0.1s ease 0s;
}

.box-block:hover .box-icon {
    font-size: 62px;
}

.title-box h3 {
    color: #4b4e53;
    line-height: 2;
    font-size: 18px;
    margin: 0;
}

.title-box p {
    margin-bottom: 0;
}

.our-point {
    padding: 0 0 0 20px;
}

.our-point li {
    list-style: none;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.61);
}

.our-point li i {
    color: rgba(0, 0, 0, 0.61);
}

.our-vision p {
    font-size: 17px;
    line-height: 1.65;
}


/*  Contact Page   ||-----------*/

.map {
    width: 100%;
    height: 450px;
}

#map {
    height: 100%;
}

#success {
    display: none;
    position: absolute;
    top: -76px;
    left: 0;
    width: 100%
}

.dark-translucent-bg {
    border-top: none;
    border-bottom: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
}

.dark-translucent-bg {
    background-color: rgba(0, 0, 0, .4);
}

.contact-form {
    position: relative;
    background: #fff;
    text-align: center;
}

.contact-form input[type="text"],
.contact-form textarea {
    padding-right: 30px;
    border: 1px solid #DEDEDE;
    margin-bottom: 0;
    font-size: 14px;
}

form .error {
    border-color: #e1534f;
}

.contact-form textarea {
    height: 202px;
}

.contact-form input[type="text"] {
    display: block;
    height: 46px;
    width: 100%;
    font-size: 14px;
    padding: 0 15px;
    border: 1px solid #e5e5e5;
    color: #878787;
    background: transparent;
    -webkit-appearance: none;
    border-radius: 0;
}

.contact-form input[type="text"]:focus,
.contact-form textarea:focus {
    border: 1px solid #2fadb9;
}

.i-block {
    display: inline-block;
}

.icon-circle {
    font-size: 50px;
    border: 1px solid #d5d5d5;
    height: 89px;
    width: 89px;
    text-align: center;
    line-height: 89px;
    margin: 0 auto;
    border-radius: 100%;
    display: block;
}

.contact .icon-circle {
    border: 1px solid #2fadb9;
    color: #2fadb9;
}

.mapmarker .ion-ios-location {
    color: #2fadb9;
    font-size: 50px;
}

@media (max-width: 767px) {
    .inner-intro .container {
        width: 100%;
        max-width: 720px;
        text-align: center;
    }
}


/*  Preloader   ||-----------*/

#preloader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999;
    background: #fff;
}

.sk-circle {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

.sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.sk-circle .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #2fadb9;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
    animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
}

.sk-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.sk-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.sk-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.sk-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

.sk-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}

.sk-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}

.sk-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

.sk-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}

.sk-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}

.sk-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}

.sk-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-circleBounceDelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}


/* ==============================
 20. Side Bar Widget styles
 ============================== */

.single-sidebar-widget ul {
    list-style: none;
    padding-left: 0;
}

.single-sidebar-widget {
    margin-bottom: 50px;
}

.single-sidebar-widget:last-child {
    margin-bottom: 0;
}

.single-sidebar-widget .sec-title {
    margin-bottom: 30px;
}

.single-sidebar-widget .sec-title h2 {
    font-size: 20px;
    font-family: 'Oswald', sans-serif;
}

.single-sidebar-widget .sec-title {
    font-size: 15px;
    font-weight: 600;
}

.single-sidebar-widget .sec-title h2:after {
    width: 35px;
}

.single-sidebar-widget .contact-form input,
.single-sidebar-widget .contact-form .select-menu+.ui-selectmenu-button {
    height: 40px;
}

.single-sidebar-widget .contact-form .select-menu+.ui-selectmenu-button {
    margin-bottom: 2px;
}

.single-sidebar-widget .contact-form .select-menu+.ui-selectmenu-button .ui-selectmenu-text {
    line-height: 40px;
}

.single-sidebar-widget .dwnld-broucher a {
    text-transform: uppercase;
    color: #08ADD0;
    font-weight: 600;
    margin-top: 30px;
    font-size: 12px;
    display: inline-block;
}

.single-sidebar-widget .special-links li:last-child {
    margin-bottom: 0;
}

.single-sidebar-widget .special-links li a {
    background: #f9f9f9;
    color: #323232;
    border: 1px solid #e9e9e9;
    font-weight: 600;
    text-transform: uppercase;
    display: block;
    margin-bottom: 5px;
    line-height: 50px;
    position: relative;
    padding: 0 20px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    position: relative;
}

.single-sidebar-widget .special-links li:hover a:after,
.single-sidebar-widget .special-links li.active a:after {
    color: inherit;
}

.single-sidebar-widget .special-links li a:after {
    content: '\f101';
    font-family: 'FontAwesome';
    position: absolute;
    top: 0;
    right: 20px;
    line-height: 50px;
    color: #6A6969;
    font-weight: normal;
}

.single-sidebar-widget .special-links li a:before {
    content: '\f0da';
    font-family: 'FontAwesome';
    position: absolute;
    top: 0;
    left: -1px;
    color: #2fadb9;
    font-size: 0px;
    line-height: 50px;
    opacity: 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    border-left: 4px solid #2fadb9;
}

.single-sidebar-widget .special-links li:hover a,
.single-sidebar-widget .special-links li a.active {
    background: #fff;
    color: #2fadb9;
}

.single-sidebar-widget .special-links li a.active:after {
    color: #2fadb9;
}

.single-sidebar-widget .special-links li a:hover:before,
.single-sidebar-widget .special-links li a.active:before {
    opacity: 1;
}

.search-box placeholder {
    color: #000;
}

.single-sidebar-widget .search-box form {
    background: #e5e5e5;
    width: 100%;
    height: 48px;
    border: 1px solid #e9e9e9;
}

.single-sidebar-widget .search-box form input {
    padding-left: 30px;
    border: none;
    outline: none;
    height: 100%;
    color: rgba(255, 255, 255, 0.5);
    width: calc(100% - 55px);
    background-color: transparent;
}

.single-sidebar-widget .search-box form input::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: rgba(255, 255, 255, 0.5);
    opacity: 1;
}

.single-sidebar-widget .search-box form input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: rgba(255, 255, 255, 0.5);
    opacity: 1;
}

.single-sidebar-widget .search-box form input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: rgba(255, 255, 255, 0.5);
    opacity: 1;
}

.single-sidebar-widget .search-box form input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(255, 255, 255, 0.5);
}

.single-sidebar-widget .search-box form input:placeholder-shown {
    /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
    color: rgba(186, 185, 185, 1);
}

.single-sidebar-widget .search-box form button {
    position: relative;
    left: 2px;
    width: 50px;
    background: #2fadb9;
    color: #000000;
    font-size: 16px;
    padding: 11px 0px;
    border: none;
    outline: none;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.single-sidebar-widget .search-box form button:hover {
    background: #1FB5C7;
}

.single-sidebar-widget .search-box form button:hover i {
    color: #fff;
}

.single-sidebar-widget .search-box form button:hover:after {
    background: #1FB5C7;
}

.single-sidebar-widget .search-box form button i {
    position: relative;
    z-index: 2;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.single-sidebar-widget .categories ul li:last-child a {
    border: none;
}

.single-sidebar-widget .categories ul li a {
    color: #898989;
    text-transform: capitalize;
    line-height: 42px;
    display: block;
    border-bottom: 1px solid #E6E6E6;
    position: relative;
}

.single-sidebar-widget .categories ul li a:after {
    content: '\f105';
    font-family: 'FontAwesome';
    color: #C8C8C8;
    position: absolute;
    top: 0;
    right: 0;
}

.single-sidebar-widget .popular-post .img-cap-effect .img-box .img-caption:before {
    border-width: 2px;
}

.single-sidebar-widget .popular-post li {
    margin-bottom: 30px;
}

.single-sidebar-widget .popular-post li:last-child {
    margin-bottom: 0;
}

.single-sidebar-widget .popular-post .img-box,
.single-sidebar-widget .popular-post .content {
    display: table-cell;
    vertical-align: middle;
}

.single-sidebar-widget .popular-post .img-box {
    width: 110px;
}

.single-sidebar-widget .popular-post .content {
    padding-left: 30px;
}

.single-sidebar-widget .popular-post .content a h4 {
    font-family: 'Open Sans', sans-serif;
    color: #242424;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.single-sidebar-widget .popular-post .content a h4:hover {
    color: #1FB5C7;
}

.single-sidebar-widget .popular-post .content p {
    color: #8E8D8D;
    font-size: 13px;
    line-height: 20px;
    margin: 0;
}

.single-sidebar-widget .popular-post .content span {
    color: #1CBAC8;
    font-size: 13px;
    display: block;
    margin-top: 4px;
    font-weight: 500;
}

.single-sidebar-widget .text-box p {
    color: #8E8D8D;
}

.single-sidebar-widget .insta-feed ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.single-sidebar-widget .insta-feed ul .img-cap-effect .img-box .img-caption:before {
    border-width: 2px;
}

.single-sidebar-widget .insta-feed {
    font-size: 0;
    display: inline-block;
}

.single-sidebar-widget .insta-feed img {
    max-width: 100%;
    display: inline-block;
    font-size: 0;
    margin-bottom: 1px;
    margin-right: 1px;
}

.single-sidebar-widget .tag-cloud {
    font-size: 0;
    margin-left: -5px;
    margin-right: -5px;
}

.single-sidebar-widget .tag-cloud li {
    display: inline-block;
}

.single-sidebar-widget .tag-cloud li a {
    display: block;
    color: #787878;
    text-transform: uppercase;
    border: 1px solid #EAEAEA;
    border-radius: 20px;
    padding: 7px 22px;
    font-size: 12px;
    margin: 0 5px;
    margin-bottom: 10px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.single-sidebar-widget .tag-cloud li a:hover {
    background: #E5E5E5;
    color: #787878;
    border-color: #E5E5E5;
}

.single-client-box {
    margin-bottom: 50px;
}

.single-client-box:last-child {
    margin-bottom: 0;
}

.single-client-box .icon-box,
.single-client-box .content {
    display: table-cell;
    vertical-align: middle;
}

.single-client-box .icon-box {
    width: 170px;
    text-align: center;
    border-top: 4px solid #1FB5C7;
    border-left: 1px solid #E9E9E9;
    border-bottom: 1px solid #E9E9E9;
}

.single-client-box .content {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 30px;
    padding-left: 30px;
    border-right: 1px solid #E9E9E9;
    border-bottom: 1px solid #E9E9E9;
    border-top: 1px solid #E9E9E9;
}

.single-client-box .content h3 {
    font-size: 18px;
    font-weight: bold;
    font-family: 'Alegreya', sans-serif;
    color: #242424;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.single-client-box .content p {
    color: #898989;
    margin: 0;
}

.single-sidebar-widget ul.brochure-btns li a {
    font-family: 'Open Sans', sans-serif;
    color: #404040;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    display: block;
    background: #FBFBFB;
    border: 1px solid #EDEDED;
    padding: 12px 20px;
}

.single-sidebar-widget ul.brochure-btns li {
    display: block;
    margin-bottom: 21px;
}

.single-sidebar-widget ul.brochure-btns li a i {
    color: #e94429;
    margin: 0 10px 0;
    font-size: 1.2em;
}

.faq.service-box .panel-group .panel-heading+.panel-collapse>.panel-body {
    padding: 20px 18px;
    background: rgba(242, 237, 233, 0.38)
}

@media (max-width: 480px) {
    h1 {
        font-size: 36px;
    }
}


/*  Project   ||-----------*/

.filter-button-group {
    list-style: none;
    padding: 0;
    overflow: hidden;
}

.filter-button-group li {
    float: left;
    margin-bottom: 10px;
    margin-right: 20px;
}

.line-btn {
    background: none;
    color: #323232;
    padding: 5px 10px;
    border: 2px solid #e1e1e1;
    display: block;
    cursor: pointer;
    -webkit-transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s;
}

.line-btn:hover,
.line-btn.active {
    background: #2fadb9;
    border: 2px solid #2fadb9;
    color: #fff;
}

.project-col-3 {
    list-style: none;
    padding-left: 0;
    padding-top: 29px;
    margin-left: -30px;
}

.project-col-3 li {
    float: left;
    padding-left: 30px;
    width: 33.33%;
    margin-bottom: 15px;
}

@media (max-width: 991px) {
    .project-col-3 li {
        width: 50%;
    }
}

@media (max-width: 767px) {
    .project-col-3 li {
        width: 100%;
    }
}


/*  Project details   ||-----------*/

.project-details {
    position: relative;
}

.project-info {
    position: absolute;
    top: 80%;
    right: 20px;
    background: #2fadb9;
    color: #fff;
    padding: 48px 30px;
    width: 400px;
}

.project-info h3 {
    color: #fff;
}

.project-box {
    overflow: hidden;
    width: 100%;
}

.project-info ul,
.project-gallery {
    list-style: none;
    padding: 0;
}

.project-info li {
    padding-top: 10px;
}

.project-info strong {
    min-width: 133px;
    display: inline-block;
}

.project-gallery {
    overflow: hidden;
}

.project-gallery li {
    margin-bottom: 20px;
    width: 50%;
    float: right;
    padding-right: 20px;
}

.project-gallery li img {
    width: 100%;
    max-width: none;
}

#related-project .owl-prev,
#related-project .owl-next {
    top: -18px;
    right: 0;
    left: auto;
    border: 2px solid #e9e9e9;
    color: #ccc;
    font-size: 22px;
    height: 1.8em;
    line-height: 1.6em;
    padding: 0;
    text-align: center;
    width: 1.8em;
    margin-right: 0;
    margin-left: 0;
    opacity: 1;
    background: none;
}

#related-project .owl-prev {
    right: 50px;
}

#related-project .owl-prev:hover,
#related-project .owl-next:hover {
    border: 2px solid #2fadb9;
    color: #fff;
    background: #2fadb9;
}

.project-col-3 li.width-2 {
    width: 66.66%;
}

.project-col-3 li.grid-sizer {
    margin-bottom: 0;
}

@media (min-width: 768px) and (max-width: 991px) {
    .project-info {
        width: 100%;
    }
    .project-info {
        position: static;
    }
    .project-info li {
        float: left;
        width: 50%;
    }
    .project-gallery {
        margin-left: -20px;
    }
    .project-gallery li {
        float: left;
        width: 25%;
        padding-right: 0;
        padding-left: 20px;
        margin-bottom: 0;
    }
    .project-col-3 li.width-2 {
        width: 50%;
    }
}

@media (max-width: 767px) {
    .project-info {
        width: 100%;
    }
    .project-info {
        position: static;
    }
    .project-info li {
        float: none;
    }
    .project-col-3 li.width-2 {
        width: 100%;
    }
    .project-gallery li {
        margin-bottom: 0;
    }
    #related-project .owl-prev,
    #related-project .owl-next {
        bottom: 0;
        top: auto;
    }
}


/*Testimonial 2*/

.owl-item {
    cursor: move;
}

.box-width {
    margin: 0 auto;
    text-align: center;
    max-width: 598px;
}

.name-text {
    color: #fff;
    font-size: 14px;
    display: block;
    text-transform: uppercase;
}

.site-text {
    color: #fff;
    font-style: italic;
    font-size: 14px;
}

.panel-group .panel {
    border-radius: 0;
}

.get-touch .contact-form {
    text-align: left;
}

.xs-size {
    position: relative;
    font-size: 16px;
    right: 0;
    top: 0;
    -webkit-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s;
}

.btn-text:hover .xs-size {
    top: -6px;
    right: -10px;
}

.date-bg {
    background: #314555;
}

.chooose-box strong {
    display: block;
    margin-bottom: 15px;
}

.chooose-box strong i {
    font-size: 20px;
    margin-right: 10px;
    color: #2fadb9;
}

#blog-section {
    position: relative;
    overflow: hidden;
}

#blog-section .blog-post .post-more-link {
    float: none;
}

.project-info-box li {
    padding-top: 10px;
}

.project-info-box strong {
    min-width: 133px;
    display: inline-block;
}

@media (max-width: 991px) {
    .project-info-box {
        padding-left: 26px;
    }
    .project-info-box strong {
        min-width: 100px;
    }
}

.top-btn {
    min-width: 0;
    padding: 0 29px;
    line-height: 50px;
    height: 50px;
    margin-left: 20px;
    background: #314555;
}

.top-btn:hover,
.header-1 .icons-hover-black i:hover {
    background: #a57a06;
}

.half-width {
    overflow: hidden;
}

.none-responsive {
    max-width: none;
}

.bg-img-1 {
    background: url(/img/new_slider02.jpg) 0 0 no-repeat;
    background-size: cover
}

.bg-img-2 {
    background: url(/img/slider-home-construction-02.jpg) 0 0 no-repeat;
    background-size: cover
}


/*Gallery*/

.gallery {
    padding-bottom: 20px;
}

.isotope .item {
    width: 25%;
    float: left;
    padding-bottom: 30px;
}

.gallery .item {
    padding-bottom: 30px;
}

.gallery-col-2.isotope .item {
    width: 50%;
}

.gallery-col-3.isotope .item {
    width: 33.33%;
}

.icon-box {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    font-size: 43px;
    line-height: 100px;
}

.gallery-item-detail .icon-box {
    color: #fff;
}

.nf-col-padding {
    padding-left: 15px;
    padding-right: 15px;
}

.gallery-item-detail a {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: #fff;
}

.gallery-item-img {
    position: relative;
    overflow: hidden;
}

.gallery-item-img img {
    transform: scale(1);
    -webkit-transform: scale(1);
    transition: all 0.2s ease-in-out 0s;
    -moz-transition: all 0.2s ease-in-out 0s;
    -webkit-transition: all 0.2s ease-in-out 0s;
    -o-transition: all 0.2s ease-in-out 0s;
}

.gallery-item-img:hover img {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
}

.gallery-item-img .gallery-item-detail {
    background-color: #cb7b52;
    text-align: center;
    color: #fff;
    display: -webkit-flex;
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: all 0.5s ease-in-out 0s;
    -moz-transition: all 0.5s ease-in-out 0s;
    -webkit-transition: all 0.5s ease-in-out 0s;
    -o-transition: all 0.5s ease-in-out 0s;
}

.team-item-img .team-item-detail,
.gallery-item-img .gallery-item-detail {
    background-color: rgba(203, 123, 82, 0.32);
}

.gallery-item:hover .gallery-item-detail {
    opacity: 1;
    visibility: visible;
}

@media (max-width: 991px) {
    .isotope .item {
        width: 33.33%;
    }
}

@media (max-width: 767px) {
    .isotope .item,
    .gallery-col-3.isotope .item {
        width: 50%;
    }
}

@media (max-width: 480px) {
    .isotope .item,
    .gallery-col-3.isotope .item,
    .gallery-col-2.isotope .item {
        width: 100%;
    }
}


/* ----------------------------------------------------------------
 feature bg box
 -----------------------------------------------------------------*/

.feature-bg-box {
    position: relative;
    padding: 28px;
    border-radius: 4px;
}

.gray-bg {
    background: rgb(247, 247, 247);
}

.feature-bg-box,
.feature-bg-box:hover .icon {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.feature-bg-box .icon {
    position: absolute;
    top: -40px;
    left: 50%;
    width: 80px;
    height: 80px;
    margin-left: -40px;
    text-align: center;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 0 0 5px #f6f6f6;
    line-height: 80px;
}

.featured-item.feature-bg-box .icon i {
    color: #2fadb9;
    font-size: 40px;
    line-height: 80px;
}

.featured-item.feature-bg-box .icon i.sub-color {
    color: #ffaf36;
}

.feature-bg-box .title {
    display: inline-block;
    margin-top: 40px;
}

.featured-item.feature-bg-box:hover {
    background: #2fadb9;
    color: #fff;
}

.featured-item.sub-color_hover:hover {
    background: #ffaf36;
}

#statement {
    background-image: url(/img/02.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

#statement:before {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    z-index: -1;
}

.lead-box {
    padding: 20px 0 30px;
}

.lead-box p {
    font-size: 20px;
    line-height: 1.5;
}

.about-text strong {
    display: block;
    font-size: 25px;
}

.about-text span {
    font-size: 18px;
    display: block;
    margin-top: 10px;
}

.about-section figure {
    width: 100%;
    margin-bottom: 30px;
}

@media (max-width: 991px) {
    .top-space {
        padding-top: 10px;
    }
}


/* ----------------------------------------------------------------------*/


/* Portfolio   --------------------------------------------------------- */


/* ----------------------------------------------------------------------*/

.container-filter {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 10px;
    padding: 0;
    text-align: center;
}

.container-filter li {
    list-style: none;
    display: inline-block;
    margin-bottom: 10px;
}

.container-filter li a {
    display: block;
    margin-left: 5px;
    margin-right: 5px;
    padding: 6px 10px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 12px;
    font-family: 'Raleway', sans-serif;
    position: relative;
    cursor: pointer;
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    color: #fff;
    background: #2fadb9;
}

.container-filter li a:before,
.container-filter li a:after {
    content: '';
    left: 0;
    position: absolute;
    background: #2fadb9;
    width: 100%;
    height: 1px;
    z-index: -1;
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    opacity: 0;
}

.container-filter li a:before {
    top: -10px;
}

.container-filter li a:after {
    bottom: -10px;
}

.container-filter li a.active,
.container-filter li a:hover {
    background: #2fadb9;
}

.container-filter li a.active:before {
    width: 100%;
}

.container-filter li a.active:before {
    top: -4px;
    opacity: 1;
}

.container-filter li a.active:after {
    bottom: -4px;
    opacity: 1;
}

.item-box {
    position: relative;
    overflow: hidden;
    display: block;
}

.item-box a {
    display: inline-block;
}

.item-box:hover .item-mask {
    opacity: 1;
    visibility: visible;
}

.item-box:hover .item-mask .item-caption,
.item-box:hover .item-mask .link {
    bottom: 30px;
    opacity: 1;
}

.item-box:hover .item-mask .link {
    bottom: 107px;
}

.item-box:hover .item-container {
    transform: scale(1.1);
    transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    width: 100%;
}

.item-container {
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    width: 100%;
    transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    -moz-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    -webkit-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    -o-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}

.item-box {
    position: relative;
    overflow: hidden;
    display: block;
}

.item-box img {
    width: 100%;
}

.item-box:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .85);
    opacity: 0;
    transition: all 0.3s linear 0s;
    -webkit-transition: all 0.3s linear 0s;
}

.item-box:hover:after {
    transition: all 0.3s linear 0s;
    -webkit-transition: all 0.3s linear 0s;
    opacity: 1;
}

.gallery-heading h4 {
    margin-bottom: 0;
}

.gallery-heading h4 a {
    color: #fff;
}

.gallery-heading h4 a:hover {
    color: #2fadb9;
}

.gallery-heading {
    transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
    position: absolute;
    bottom: -100px;
    left: 0;
    width: 100%;
    padding-left: 25px;
    color: #fff;
    z-index: 1;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.69+49,0.83+71 */
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.69) 49%, rgba(0, 0, 0, 0.83) 71%, rgba(0, 0, 0, 0.83) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.69) 49%, rgba(0, 0, 0, 0.83) 71%, rgba(0, 0, 0, 0.83) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.69) 49%, rgba(0, 0, 0, 0.83) 71%, rgba(0, 0, 0, 0.83) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#d4000000', GradientType=0);
    /* IE6-9 */
}

.item-box .gallery-heading {
    bottom: 0;
    transition: all 0.3s linear 0.1s;
    -webkit-transition: all 0.3s linear 0.1s;
}

.link-zoom {
    transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
    height: 40px;
    position: absolute;
    top: 55%;
    left: 50%;
    color: #fff;
    z-index: 1;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    opacity: 0;
}

.item-box:hover .link-zoom {
    top: 50%;
    opacity: 1;
    transition: all 0.2s ease-out 0s;
    -webkit-transition: all 0.2s ease-out 0s;
}

.link-zoom a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #2fadb9;
    font-size: 17px;
    color: #fff;
}

.link-zoom a.project_links {
    background: #2fadb9;
}

.item-box a {
    display: inline-block
}

.item-box>a {
    display: block;
}

.item-box:hover .item-mask {
    opacity: 1;
    visibility: visible;
}

.item-box:hover .item-mask .item-caption,
.item-box:hover .item-mask .link {
    bottom: 30px;
    opacity: 1;
}

.item-box:hover .item-mask .link {
    bottom: 107px;
}

.item-box:hover .item-container {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    width: 100%;
}

.item-container {
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    width: 100%;
    transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    -moz-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    -webkit-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    -o-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}


/* ===================================================================================================== */


/* Contants (Portfolio, Blog, Shop, etc) -------------------  */


/* ===================================================================================================== */

.container.nf-container1 {
    padding-left: 15px;
    padding-right: 15px;
}

.nf-row1 {
    margin-left: -15px;
    margin-right: -15px;
}

.nf-col-padding {
    padding-left: 25px;
    padding-right: 25px;
}

.nf-row1:before,
.nf-row1:after,
.nf-row2:before,
.nf-row2:after {
    display: table;
    content: " ";
}

.nf-row1:after,
.nf-row2:after {
    clear: both;
}

.nf-row1 .nf-item.spacing,
.nf-row1 .grid-sizer.spacing {
    padding: 15px;
}

.nf-row2 .nf-item.spacing,
.nf-item.spacing,
.nf-row2 .grid-sizer.spacing,
.spacing-box {
    padding: 10px;
}

.spacing-grid {
    padding-top: 25px;
    padding-bottom: 25px;
}

.nf-col-1 .nf-item,
.nf-col-1 .grid-sizer {
    width: 100%;
    min-height: 1px;
}

.nf-col-2 .nf-item,
.nf-col-2 .grid-sizer {
    width: 50%;
    min-height: 1px;
}

.nf-col-3 .nf-item,
.nf-col-3 .grid-sizer {
    width: 33.3333%;
    min-height: 1px;
}

.nf-col-4 .nf-item,
.nf-col-4 .grid-sizer {
    width: 25%;
    min-height: 1px;
}

.nf-col-4 .w2x {
    width: 50%;
}

.nf-col-5 .nf-item,
.nf-col-5 .grid-sizer {
    width: 20%;
    min-height: 1px;
}

.nf-col-6 .nf-item,
.nf-col-6 .grid-sizer {
    width: 16.6666%;
    min-height: 1px;
}

.nf-col-1 .nf-item.w1,
.nf-col-2 .nf-item.w1,
.nf-col-3 .nf-item.w1,
.nf-col-4 .nf-item.w1 {
    width: 100%;
}

.nf-col-1 .nf-item.w2,
.nf-col-2 .nf-item.w2,
.nf-col-3 .nf-item.w2,
.nf-col-4 .nf-item.w2 {
    width: 50%;
}

.nf-col-1 .nf-item.w3,
.nf-col-2 .nf-item.w3,
.nf-col-3 .nf-item.w3,
.nf-col-4 .nf-item.w3 {
    width: 33.3333%;
}

.nf-col-1 .nf-item.w4,
.nf-col-2 .nf-item.w4,
.nf-col-3 .nf-item.w4,
.nf-col-4 .nf-item.w4 {
    width: 25%;
}

.nf-col-1 .nf-item.w4-2,
.nf-col-2 .nf-item.w4-2,
.nf-col-3 .nf-item.w4-2,
.nf-col-4 .nf-item.w4-2,
.nf-col-1 .nf-item.w3-2,
.nf-col-2 .nf-item.w3-2,
.nf-col-3 .nf-item.w3-2,
.nf-col-4 .nf-item.w3-2 {
    width: 50%;
}

.nf-col-1 .nf-item.w4-1,
.nf-col-2 .nf-item.w4-1,
.nf-col-3 .nf-item.w4-1,
.nf-col-4 .nf-item.w4-1 {
    width: 75%;
}

.nf-col-1 .nf-item.w3-1,
.nf-col-2 .nf-item.w3-1,
.nf-col-3 .nf-item.w3-1,
.nf-col-4 .nf-item.w3-1 {
    width: 66.6666%;
}

@media (max-width: 991px) {
    .nf-col-2 .nf-item,
    .nf-col-3 .nf-item,
    .nf-col-4 .nf-item,
    .nf-col-1 .nf-item.w2,
    .nf-col-2 .nf-item.w2,
    .nf-col-3 .nf-item.w2,
    .nf-col-4 .nf-item.w2,
    .nf-col-1 .nf-item.w3,
    .nf-col-2 .nf-item.w3,
    .nf-col-3 .nf-item.w3,
    .nf-col-4 .nf-item.w3,
    nf-col-1 .nf-item.w4,
    .nf-col-2 .nf-item.w4,
    .nf-col-3 .nf-item.w4,
    .nf-col-4 .nf-item.w4,
    .nf-col-1 .nf-item.w4-1,
    .nf-col-2 .nf-item.w4-1,
    .nf-col-3 .nf-item.w4-1,
    .nf-col-4 .nf-item.w4-1,
    .nf-col-1 .nf-item.w3-1,
    .nf-col-2 .nf-item.w3-1,
    .nf-col-3 .nf-item.w3-1,
    .nf-col-4 .nf-item.w3-1 {
        width: 50%;
    }
    .nf-col-5 .nf-item,
    .nf-col-6 .nf-item {
        width: 33.3333%;
    }
    .nf-row2 .nf-item.spacing,
    .nf-row2 .grid-sizer.spacing {
        padding: 25px;
    }
}

@media (max-width: 767px) {
    .container {
        margin: 0 auto;
        max-width: 480px;
        width: 100%;
    }
    .nf-col-padding {
        padding-left: 15px;
        padding-right: 15px;
    }
    .spacing-grid {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .nf-col-2 .nf-item,
    .nf-col-3 .nf-item,
    .nf-col-4 .nf-item,
    .nf-col-1 .nf-item.w2,
    .nf-col-2 .nf-item.w2,
    .nf-col-3 .nf-item.w2,
    .nf-col-4 .nf-item.w2,
    .nf-col-1 .nf-item.w3,
    .nf-col-2 .nf-item.w3,
    .nf-col-3 .nf-item.w3,
    .nf-col-4 .nf-item.w3,
    nf-col-1 .nf-item.w4,
    .nf-col-2 .nf-item.w4,
    .nf-col-3 .nf-item.w4,
    .nf-col-4 .nf-item.w4,
    .nf-col-1 .nf-item.w4-1,
    .nf-col-2 .nf-item.w4-1,
    .nf-col-3 .nf-item.w4-1,
    .nf-col-4 .nf-item.w4-1,
    .nf-col-1 .nf-item.w3-1,
    .nf-col-2 .nf-item.w3-1,
    .nf-col-3 .nf-item.w3-1,
    .nf-col-4 .nf-item.w3-1 {
        width: 100%;
        max-width: 480px;
        margin: 0 auto;
    }
    .nf-col-3,
    .nf-col-4 {
        margin: 0 auto;
        max-width: 480px;
    }
    .nf-col-5 .nf-item,
    .nf-col-6 .nf-item {
        width: 100%;
    }
    .nf-row2 .nf-item.spacing,
    .nf-row1 .nf-item.spacing,
    .spacing-box {
        padding: 15px;
    }
    body .xs-hidden {
        visibility: hidden;
        display: none;
        opacity: 0;
    }
}

@media (max-width: 480px) {
    .nf-col-5 .nf-item,
    .nf-col-6 .nf-item {
        width: 100%;
    }
}


/*-------------------------------------------------------------------------------------------*/


/* Counter STYLE    ||---------------------------- */


/*-------------------------------------------------------------------------------------------*/

.fact-counter-wrapper {
    position: relative;
}

.single-fact {
    text-align: center;
    display: inline-block;
}

.single-fact .icon-boxed {
    width: 100px;
    height: 100px;
    font-size: 70px;
    color: #ffaf36;
    text-align: center;
    line-height: 121px;
    margin: 0 auto;
    position: static;
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
}

.single-fact span {
    font-size: 50px;
    font-weight: 600;
    color: #fff;
    padding: 4px 0 10px;
    line-height: 1.2;
    display: block;
}

.fact-counter-wrapper h2 {
    font-size: 36px;
    line-height: 42px;
    color: #fff;
    margin: 0;
    margin-bottom: 27px;
}

.single-fact p {
    margin: 0;
    font-size: 15px;
    text-transform: uppercase;
    color: #fff;
}

.single-fact .icon-box i:before {
    font-size: 50px;
}


/*-------------------------------------------------------------------------------------------*/


/*  Testimonials     ||---------------------------- */


/*-------------------------------------------------------------------------------------------*/

.quote {
    position: relative;
    padding: 40px;
    min-height: 290px;
}

.quote p {
    font-size: 16px;
    line-height: 1.6;
    position: relative;
}

.quote:before {
    font: normal normal normal 45px/1 FontAwesome;
    content: "\f10d";
    color: #0894D6;
    position: absolute;
    left: 26px;
    top: 16px;
    transform: rotate(-18deg);
    -webkit-transform: rotate(-18deg);
    opacity: 0.6;
}

.quote:after {
    content: '';
    width: 14px;
    height: 29px;
    background: #2fadb9;
    position: absolute;
    left: 61px;
    bottom: -14px;
    transform: rotate(61deg);
    -webkit-transform: rotate(61deg);
}

.testimonial-pic {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 20px 0 0;
    overflow: hidden;
    float: left;
}

.testimonial-outher {
    overflow: hidden;
    text-align: left;
    float: left;
    padding-top: 37px;
    padding-left: 20px;
}

.testimonial-outher strong,
.testimonial-outher span {
    display: block;
}

.video-btn {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    z-index: 9;
    color: #fff;
    border: 1px solid #fff;
    cursor: pointer;
}

.video-btn:hover {
    color: #2fadb9;
    border: 1px solid #2fadb9;
}

.bg-color {
    background: #2fadb9;
}

.img-scale figure {
    overflow: hidden;
}

.img-scale img {
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    width: 100%;
    transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    -moz-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    -webkit-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    -o-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}

.img-scale:hover img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    -moz-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    -webkit-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    -o-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}


/*-------------------------------------------------------------------------------------------*/


/* Team    ||---------------------------- */


/*-------------------------------------------------------------------------------------------*/

.team-block {
    color: #ffffff;
    transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -webkit-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    background: #232833;
    padding: 30px 0;
}

.box-hover:hover .team-block {
    background: #ffaf36;
}

.box-hover:hover * {
    color: #323232;
}

.team-block strong {
    display: block;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
}

.team-block span {
    font-family: raleway;
    font-size: 13px;
    font-style: normal;
    color: #b8bcc6;
}

.team-block .small-divider {
    margin-bottom: 15px;
    margin-top: 15px;
    width: 36px;
    border-color: rgba(255, 255, 255, 0.2);
}

.social-icons {
    padding-left: 0;
}

.team-block .social-icons>li {
    margin: 0 7px;
    display: inline-block;
}

.team-block .social-icons .fa {
    font-size: 16px;
}

.team-block .social-icons .fa {
    color: #fff;
}

.box-hover:hover .fa {
    color: #323232;
}


/*--carousel arrow--*/

.owl-prev,
.owl-next {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.33);
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    background: rgba(245, 245, 245, 0.8);
    color: #252525;
    display: block;
    top: 50%;
    transition: all 0.25s ease 0s;
    -o-transition: all 0.25s ease 0s;
    -moz-transition: all 0.25s ease 0s;
    -webkit-transition: all 0.25s ease 0s;
    z-index: 3;
    position: absolute;
    text-align: center;
}

.owl-prev {
    left: 0;
    opacity: 1;
}

.owl-next {
    right: 0;
    opacity: 1;
}

.nf-carousel-theme:hover .owl-controls .owl-prev,
.nf-carousel-theme:hover .owl-controls .owl-next {
    margin-right: 0;
    margin-left: 0;
    opacity: 1;
}


/* ----------------------------------------------------------------------*/


/* Pagination Navigation   --------------------------------------------- */


/* ----------------------------------------------------------------------*/

.pagination-nav {
    width: 100%;
    display: block;
    position: relative;
    text-align: center;
}

.pagination-nav ul {
    margin: 0px;
    padding: 0px;
}

.pagination-nav ul li {
    display: inline-block;
    margin: 0px 2px;
}

.pagination-nav ul li a {
    display: block;
    border: 1px solid #2fadb9;
    color: #2fadb9;
    height: 36px;
    width: 36px;
    line-height: 34px;
    text-align: center;
    font-size: 14px;
}

.pagination-nav ul li.active a,
.pagination-nav ul li:hover a {
    background: #2fadb9;
    color: #fff;
}


/*============================================
 =          [07] Waste time quote css         =
 =============================================*/

.waste-time-area {
    background: #cb011b;
    padding: 50px 0;
}

.waste-time-area .waste-time-content {
    color: #ffffff;
}

.waste-time-area .waste-time-content h3 {
    color: #ffffff;
    font-size: 30px;
    margin-bottom: 5px;
}

.waste-time-area .waste-time-content p {
    margin-bottom: 0;
}

.waste-time-area .waste-time-button {
    text-align: right;
}

.waste-time-area .waste-time-button .btn-default {
    display: inline-block;
    margin-top: 6px;
}


/*  Whate We Do   ||-----------*/

.about_service .feature-bg-box .icon {
    position: static;
    margin: 0 auto;
}

.about_service .feature-bg-box .title {
    display: inline-block;
    margin-top: 25px;
}

.about_service .feature-bg-box .icon {
    background: #3a3a3a;
}

.about_service .thm-btn {
    font-size: 14px;
    font-weight: 400;
    margin-top: 15px;
}

.about_service .bg-clr1 {
    background: #31312f;
}

.whate_about p {
    line-height: 1.4;
    font-size: 17px;
}


/*Testimonial 2*/

.owl-item {
    cursor: move;
}

.box-width {
    margin: 0 auto;
    text-align: center;
    max-width: 598px;
}

.name-text {
    color: #2fadb9;
    font-size: 14px;
    display: block;
    text-transform: uppercase;
}

.site-text {
    color: #fff;
    font-style: italic;
    font-size: 14px;
}

.arrow_theme .owl-prev,
.arrow_theme .owl-next {
    top: 40%;
    border: 2px solid #e9e9e9;
    color: #ccc;
    font-size: 22px;
    height: 1.8em;
    line-height: 1.6em;
    padding: 0;
    text-align: center;
    width: 1.8em;
    margin-right: 0;
    margin-left: 0;
    opacity: 1;
    background: none;
}

.arrow_theme .owl-prev:hover,
.arrow_theme .owl-next:hover {
    border: 2px solid #2fadb9;
    color: #fff;
    background: #2fadb9;
}

.overlay-dark {
    position: relative;
    z-index: 1;
}

.overlay-dark:before {
    background-color: rgba(0, 0, 0, 0.23);
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    z-index: -1;
}

.overlay-dark:before {
    background-color: rgba(0, 0, 0, 0.69);
}

.bold {
    font-weight: bold;
}

.thin {
    font-weight: 300;
}

.img-bg {
    background: url(/img/white-texture.jpg) 0 0 no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
}

.light-color {
    color: #fff;
}

.parallax,
.bg_img1 {
    background: url(/img/parallax2.jpg) no-repeat;
}

#overlay_block,
.bg_img {
    background: url(/img/parallax1.jpg) no-repeat;
}

.parallax,
#overlay_bloc {
    background-size: cover;
    width: 100%;
    background-attachment: fixed;
    background-position: center center;
}

.fact-counter-wrapper.bg_img1 {
    background-position: center center !important;
}

.btn-wrap a {
    margin: 0 10px;
}


/*Testimonial*/

.testimonial-box {
    text-align: left;
}

.quote-box {
    padding-left: 30px;
}

.quote-text {
    padding-left: 30px;
    padding-top: 10px;
}

.quote-icon i {
    font-size: 50px;
}

.quote-left {
    padding-top: 60px;
}

.quote-right {
    text-align: right;
}

.quote-author {
    float: left;
    text-transform: uppercase;
    padding-left: 30px;
}

.pt-130 {
    padding-top: 130px;
}

@media (max-width: 991px) {
    .pt-130 {
        padding-top: 0;
    }
}

.testimonial-block__section .quote-left {
    padding-top: 0;
}

.testimonial-block__section .quote-icon i {
    font-size: 30px;
}

.testimonial-block__section .quote-box {
    padding-left: 0;
}

.nf-carousel-theme.nf-carousel-arrow .owl-controls .owl-prev,
.nf-carousel-theme.nf-carousel-arrow .owl-controls .owl-next {
    top: -42px;
    right: 0;
    left: auto;
    border: 2px solid #e9e9e9;
    color: #ccc;
    font-size: 22px;
    height: 1.8em;
    line-height: 1.6em;
    padding: 0;
    text-align: center;
    width: 1.8em;
    margin-right: 0;
    margin-left: 0;
    opacity: 1;
    background: none;
}

.nf-carousel-theme.nf-carousel-arrow .owl-controls .owl-prev {
    right: 50px;
}

.nf-carousel-theme.nf-carousel-arrow .owl-controls .owl-prev:hover,
.nf-carousel-theme.nf-carousel-arrow .owl-controls .owl-next:hover {
    border: 2px solid #2fadb9;
    color: #fff;
    background: #2fadb9;
}

@media (max-width: 767px) {
    .testimonial-block__section .quote-left {
        padding-top: 26px;
    }
    .nf-carousel-theme.nf-carousel-arrow .owl-controls .owl-prev,
    .nf-carousel-theme.nf-carousel-arrow .owl-controls .owl-next {
        top: auto;
        bottom: -85px;
    }
}


/*Corve Section*/

.corve {
    position: relative;
}

.corve .container {
    position: relative;
    z-index: 1;
}

.corve:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: url(/img/corve.png) center 45px no-repeat;
    background-size: 100% auto;
}

.about-box {
    background: #fff;
}

.about-box p {
    padding-bottom: 10px;
}

.about-box {
    box-shadow: 1px 1px 20px 5px rgba(0, 0, 0, 0.09);
    padding: 35px 40px 40px;
}


/*Contact section*/

.bottom-section {
    background: #2fadb9;
}

.contact_block-text {
    padding-top: 32px;
}

.contact_block-text strong {
    text-transform: uppercase;
    font-size: 31px;
    letter-spacing: 0;
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    display: block;
    padding-bottom: 10px;
}

.contact_block-text p {
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    font-size: 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .contact_block-text strong {
        font-size: 25px;
    }
    .contact_block-text p {
        font-size: 28px
    }
}

@media (max-width: 767px) {
    .contact_block-text strong {
        font-size: 20px;
    }
    .contact_block-text p {
        font-size: 19px
    }
}

.bottom-section .input-sm {
    height: 42px;
}

.bottom-section textarea {
    resize: none;
    height: 60px;
    margin-bottom: 0;
    display: block;
}

.bottom-section .btn-text {
    border: 2px solid #2fadb9;
    background: #2fadb9;
    max-width: 160px;
    width: 100%;
    margin-top: 10px;
}

.light-gray {
    background: #fafafa;
}

.top-padding {
    padding-top: 86px;
}

@media (min-width: 991px) and (max-width: 1199px) {
    .top-padding {
        padding-top: 50px;
    }
}

@media (max-width: 991px) {
    .top-padding {
        padding-top: 0;
    }
    .contact_block-text strong {
        font-size: 26px;
    }
    .contact_block-text p {
        font-size: 29px;
    }
}


/*Footer*/

#footer {
    background-color: #2fadb9;
    padding: 70px 0 0;
    position: relative;
    z-index: 1;
}

#footer-widgets .widget .widget-title {
    font-size: 1.428em;
    margin-bottom: 25px;
    z-index: 1;
    position: relative;
}

#footer-widgets .widget .widget-title {
    margin-bottom: 36px;
    color: #fff;
}

#sidebar .widget .widget-title>span:after,
#sidebar .widget .widget-title>span:after,
#footer-widgets .widget .widget-title>span:after,
#footer-widgets .widget .widget-title>span:after {
    width: 0;
    height: 1px;
    background-color: #585252;
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    z-index: -2;
}

#footer-widgets .widget {
    position: relative;
    z-index: 1;
    margin-top: 50px;
    color: #979797;
}

#footer-widgets .widget:first-child {
    margin: 0;
}

#footer-widgets .widget .widget-title>span {
    display: block;
    position: relative;
    padding-bottom: 12px;
    color: #fff;
}

.margin-top-5 {
    margin-top: 5px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}

#footer-widgets .widget ul {
    margin: 0;
    list-style: none;
    padding: 0;
}

#footer-widgets .widget.widget_links ul.col2 li {
    width: 50%;
    float: left;
    padding: 0 0 12px;
}

#footer-widgets .widget.widget_links ul li a {
    position: relative;
    display: inline-block;
    padding-left: 25px;
    color: #b7b7b7;
    text-decoration: none;
}

#footer-widgets .widget.widget_links ul li a:before {
    content: "\f105";
    font-family: "FontAwesome";
    font-size: 14px;
    position: absolute;
    left: 0;
    top: 0;
}

#footer-widgets .widget.widget_links ul li a:before {
    content: "\f101";
}

#footer-widgets .widget.widget_links ul li a:before {
    color: #4f4f4f;
}

#footer-widgets .widget.widget.widget_information ul li {
    position: relative;
    padding: 0;
    border: 0;
    margin-bottom: 5px;
}

#footer-widgets .widget.widget.widget_information ul .hl {
    font-weight: bold;
    width: 20%;
    float: left;
}

#footer-widgets .widget.widget.widget_information ul .text {
    width: 80%;
    float: left;
}

#footer-widgets .widget {
    position: relative;
    z-index: 1;
    margin-top: 50px;
    color: #b7b7b7;
}

#footer-widgets .widget.widget_socials .socials a {
    position: relative;
    display: inline-block;
    width: 38px;
    height: 38px;
    color: #fff;
    line-height: 38px;
    text-align: center;
    margin-right: 3px;
    margin-bottom: 10px;
    z-index: 1;
}

#footer-widgets .widget.widget_socials .socials a:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border: 1px solid #eaeaea;
    background-color: transparent;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all ease .238s;
    -moz-transition: all ease .238s;
    transition: all ease .238s;
}

#footer-widgets .widget.widget_socials .socials a:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border: 1px solid #0f2644;
    background-color: #07172b;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    -webkit-transition: all ease .238s;
    -moz-transition: all ease .238s;
    transition: all ease .238s;
}

#footer-widgets .widget .widget-title>span::after {
    width: 0;
    height: 1px;
    background-color: #585252;
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    z-index: -2;
    width: 60px;
    height: 3px;
    background-color: #ffbc13;
}

#footer-widgets .widget.widget_socials .socials a:hover:before {
    background-color: #ffbc13;
    border-color: #ffbc13;
}

.footer-logo {
    width: 115px;
    margin-bottom: 20px;
}


/* Bottom Bar
 -------------------------------------------------------------- */

#bottom {
    font-size: 0.857em;
    background-color: #07172b;
    padding: 19px 0;
    color: #b7b7b7;
    margin-top: 60px;
}

#bottom a:hover {
    color: #fff;
}

#bottom .bottom-bar-inner-wrap {
    display: table;
    overflow: hidden;
    width: 100%;
}

#bottom .bottom-bar-content {
    display: table-cell;
    margin: 0;
    text-align: left;
    vertical-align: middle;
    width: 50%;
}

#bottom .bottom-bar-menu {
    display: table-cell;
    text-align: right;
    vertical-align: middle;
    width: 50%;
}

#bottom .bottom-bar-menu li:last-child a {
    padding-right: 0;
}

.wprt-container {
    width: 1170px;
    margin: 0 auto;
    max-width: 90%;
}

#bottom .bottom-bar-content {
    display: table-cell;
    margin: 0;
    text-align: left;
    vertical-align: middle;
    width: 50%;
}

#bottom .bottom-bar-menu {
    display: table-cell;
    text-align: right;
    vertical-align: middle;
    width: 50%;
}

#bottom ul.bottom-nav {
    list-style: none;
    margin: 0;
    padding: 0;
}

#bottom ul.bottom-nav>li {
    display: inline-block;
}

#bottom ul.bottom-nav>li>a {
    display: block;
    color: #b7b7b7;
    padding: 0 10px;
    position: relative;
}

@media only screen and (max-width: 991px) {
    #footer-widgets .widget .widget-title {
        font-size: 1.142em;
        margin-bottom: 28px;
    }
}

@media only screen and (max-width: 767px) {
    #footer {
        padding: 50px 0 10px;
    }
    #footer-widgets [class^="col-md-"] .widget:last-child {
        margin-bottom: 30px;
    }
    #bottom .bottom-bar-content,
    #bottom .bottom-bar-menu {
        width: 100%;
        display: block;
        text-align: center;
        padding: 5px 0;
    }
}


/*Progress css*/

.content-wd650 {
    max-width: 650px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.cont-h-100per {
    height: 100%;
}

.parallax-section.overlay-dark:before {
    z-index: 1;
}

.section-split-right h2 span {
    color: #fff;
}

.section-split-right h2 {
    color: #2fadb9;
}

.bg-color3 {
    background: #6aaf08;
}

.section-bar h3 {
    line-height: 1.5;
}

.align-center {
    margin: 0 auto;
}

.overflow_hidden {
    overflow: hidden;
    position: relative;
}

.image_cover {
    background-image: url(/img/bg-10.jpg);
    background-size: cover;
    display: block;
    position: absolute;
    width: 33.33%;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    background-position: 50% 50%;
}

.image_cover_right {
    width: 50%;
    right: 0;
    left: auto;
    background-image: url(/img/bg-11.jpg);
}

@media (min-width: 768px) and (max-width: 991px) {
    #choose_us .image_cover {
        display: none;
    }
}

@media (max-width: 767px) {
    .image_cover {
        position: static;
        width: 100%;
        height: 300px;
        margin-top: 30px;
    }
}

.section_blck_bg {
    background: #2fadb9;
}

.m-b-5 {
    margin-bottom: 5px;
}


/* #Accordion-box
 ================================================== */

.accordion-box-1 .accordion {
    background: #fff;
}

.accordion-box-1 .accordion .accordion-title {
    cursor: pointer;
    padding: 10px 20px 10px 30px;
    border: 1px solid #e6e6e6;
    margin: 0;
    font-size: 18px;
    color: #333333;
}

.accordion-box-1 .accordion .accordion-title span {
    width: 30px;
    position: relative;
    height: 30px;
    float: right;
    vertical-align: bottom;
}

.accordion-box-1 .accordion .accordion-title span:before {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    content: "";
    display: inline-block;
    width: 2px;
    height: 12px;
    background: #aaaaaa;
}

.accordion-box-1 .accordion .accordion-title span:after {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    content: "";
    display: inline-block;
    width: 12px;
    height: 2px;
    background: #aaaaaa;
}

.accordion-box-1 .accordion .accordion-title p {
    margin: 0;
}

.accordion-box-1 .accordion.open .accordion-title span:before {
    display: none;
}

.accordion-box-1 .content-inner {
    border: 1px solid #ededed;
    padding: 25px 30px 20px 30px;
}

.accordion-box-2 .accordion.open .accordion-title {
    background: #2fadb9;
}

.accordion-box-2 .accordion .accordion-title {
    border: none;
    background: #031123;
}

.accordion-box-2 .accordion .accordion-title p {
    color: #fff;
}

.accordion-box-2 .accordion .accordion-content .content-inner {
    background: #2fadb9;
    border: none;
}

.accordion-box-2 .accordion .accordion-content .content-inner p {
    color: #c5c5c5;
    padding-left: 20px;
    border-left: 1px solid #031123;
}


/*Feature Section */

.featured-icon-wrap {
    list-style: none;
    padding-left: 0;
    padding-top: 25px;
}

@media (max-width: 991px) {
    .featured-icon-wrap {
        padding-top: 0;
    }
}

.featured-content-wrap {
    width: 73%;
    width: calc(100% - 100px);
    width: -webkit-calc(100% - 100px);
}

.text-right {
    text-align: right;
}

.float-left {
    float: left;
}

.mb-25 {
    margin-bottom: 25px;
}

.bold {
    font-weight: 700;
}

.feature-icon-wrap {
    margin-top: 0px;
    width: 85px;
    position: relative;
    text-align: center;
}

.feature-icon {
    height: 85px;
    width: 85px;
    font-size: 32px;
    line-height: 54px;
    color: #fff;
    padding: 15px;
    display: block;
    transition: all 0.4s linear 0s;
    -webkit-transition: all 0.4s linear 0s;
    position: relative;
    z-index: 3;
}

.feature-icon-wrap:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 85px;
    height: 85px;
    border: 1px solid #2fadb9;
    border-radius: 50%;
    background: transparent;
    transition: all 0.4s linear 0s;
    -webkit-transition: all 0.4s linear 0s;
    background: #2fadb9;
}

.featured-icon-wrap li:hover .feature-icon {
    color: #fff;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.moc-wrap img {
    display: block;
    margin: 0 auto;
}

.bg-color {
    background: rgba(0, 0, 0, 0.04);
}

.btn-block a {
    margin: 0 10px;
}

#statements h4 {
    color: #fff;
}

.gmnoprint {
    display: none;
}

.paragraphs-block p {
    margin-bottom: 20px;
}

.grid-boxed {
    border: 1px solid transparent;
    padding: 20px;
}

.buttons {
    margin-bottom: 60px;
}

.buttons h2 {
    font-size: 20px;
}

.buttons a {
    margin: 5px;
}


/*||===================About 2=============||*/


/*About top Section*/

.abt-block {
    overflow: hidden;
}

.abt-block__wrap {
    display: table;
    width: 100%;
}

.abt-block__left,
.abt-block__right {
    width: 60%;
    display: table-cell;
    padding: 30px;
    vertical-align: middle;
}

.abt-block__right {
    width: 40%;
}

@media (max-width: 767px) {
    .abt-block__wrap {
        display: block;
        height: auto;
    }
    .abt-block__right {
        height: 300px;
    }
    .abt-block__right,
    .abt-block__left {
        width: 100%;
        display: block;
        padding: 30px;
    }
}

.abt-block__bg {
    background: #000;
}

.abt-block__img1 {
    background: url(/img/people.jpg) center 0 no-repeat;
    background-size: cover;
}

.abt-block__img2 {
    background: url(/img/people1.jpg) center 0 no-repeat;
    background-size: cover;
}

.abt-block__img3 {
    background: url(/img/people2.jpg) center 0 no-repeat;
    background-size: cover;
}

.abt-block__left h2 {
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 40px;
}

.abt-block__left p {
    color: #fff;
    font-size: 16px;
    line-height: 1.5;
}

.abt-block_link {
    color: #fff;
    font-weight: bold;
    display: inline-block;
    margin: 20px 0;
    -webkit-transition: color 0.22s ease-in-out;
    transition: color 0.22s ease-in-out;
}

.abt-block_link:hover {
    color: #2fadb9;
}

.abt-block_link-arrow {
    font-size: 18px;
    vertical-align: middle;
    padding-left: 5px;
    color: #2fadb9;
}


/*dots style*/

.abt-block__slider .owl-dots {
    position: absolute;
    bottom: 26px;
    right: 43px;
}

.abt-block__slider .owl-dots .owl-dot {
    width: 20px;
    height: 20px;
    background: #fb9513;
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px;
}

.abt-block__slider .owl-dots .owl-dot.active {
    background: #fff;
}


/*mission and vision*/

.mv-block {
    display: table;
    width: 100%;
    height: 300px;
}

.mv-block__mission,
.mv-block__vision {
    width: 60%;
    display: table-cell;
    padding: 30px;
    vertical-align: middle;
}

.mv-block__vision {
    width: 40%;
}

@media (max-width: 767px) {
    .mv-block {
        display: block;
        height: auto;
    }
    .mv-block__mission,
    .mv-block__visiont {
        width: 100%;
        display: block;
        padding: 30px;
    }
    .row-table {
        padding: 15px;
    }
}

.mv-block__mission h3,
.mv-block__vision h3 {
    font-size: 34px;
    margin: 0px 0 30px 0;
    font-weight: 600;
    color: #222;
}

.mv-block__vision h3,
.mv-block__vision p {
    color: #fff;
}

.mv-block p {
    font-size: 20px;
    line-height: 1.5;
}

.mv-block__mission-bg {
    background: #f0f0f0;
}

.mv-block__vision-bg {
    background: #000;
}

.mv-block__vision_link,
.mv-block__mission_link {
    color: #fff;
    font-weight: bold;
    display: inline-block;
    margin: 20px 0;
    -webkit-transition: color 0.22s ease-in-out;
    transition: color 0.22s ease-in-out;
}

.mv-block__mission_link {
    color: #222;
}

.mv-block__vision_link:hover,
.mv-block__mission_link:hover {
    color: #2fadb9;
}

.mv-block__vision_link-arrow,
.mv-block__mission_link-arrow {
    font-size: 18px;
    vertical-align: middle;
    padding-left: 5px;
    color: #2fadb9;
}


/*====*/

.tab-content .tab-block.on {
    display: block;
}

.tab-title ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.tab-title ul li a,
.mobile-heading {
    font-size: 18px;
    font-weight: 700;
    color: #222;
    background: #f0f0f0;
    padding: 15px 15px 18px;
    cursor: pointer;
    display: block;
}

.mobile-heading {
    float: none;
    width: 100%;
    font-size: 16px !important;
    color: #fff !important;
    padding: 15px 15px 15px;
    text-transform: capitalize !important;
    font-weight: 400 !important;
    margin-bottom: 10px;
    background: #000;
}

.tab-title ul li a.active,
.tab-title ul li a:hover {
    color: #fff;
    background: #222;
}

.tab-title ul li span {
    display: block;
    font-size: 20px;
    font-family: 'Playball', cursive;
    font-weight: 400;
}

.feature .feature-desc .feature-info {
    position: absolute;
}

@media (max-width: 767px) {
    .menu-item-name {
        width: 62%;
    }
}


/*Tabing content*/

.abt-box__content {
    padding: 30px 30px 30px;
}

.abt-box__content h2 {
    color: #fff;
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 40px;
}

.abt-box__content p {
    color: #fff;
    font-size: 16px;
    line-height: 1.5;
}

.abt-box__content-link,
.abt-box_link {
    color: #fff;
    font-weight: bold;
    display: inline-block;
    margin: 20px 0;
    -webkit-transition: color 0.22s ease-in-out;
    transition: color 0.22s ease-in-out;
}

.abt-box__content-link:hover,
.abt-box_link:hover {
    color: #2fadb9;
}

.abt-box_link {
    margin: 0;
}

.abt-box_link {
    color: #222;
}

.abt-box__content-link-arrow,
.abt-box_link-arrow {
    font-size: 18px;
    vertical-align: middle;
    padding-left: 5px;
    color: #2fadb9;
}

.row-table {
    display: table;
    height: 300px;
}

.row-table .t_cell {
    display: table-cell;
    float: none;
    vertical-align: middle;
}

.history-block__img1 {
    background: url(/img/graph.jpg) center 0 no-repeat;
    background-size: cover;
}

.career_sub-title {
    font-weight: 600;
    margin-bottom: 10px;
    display: block;
}

.company-brochure a {
    display: block;
    transition: all .3s ease;
}

.company-brochure a:hover {
    box-shadow: 2px 8px 25px rgba(99, 103, 107, 0.6);
    transition: all .3s ease;
}


/*TimeLine*/

.history-timeline {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
}

.history-timeline li {
    position: relative;
    margin-bottom: 50px;
}

.history-timeline:after {
    background: #e5f1fc;
}

.history-timeline:after {
    content: '';
    position: absolute;
    top: 0;
    left: -30px;
    height: 100%;
    width: 4px;
    background: #d7e4ed;
}

.history-timeline .icon-dot {
    top: 50%;
    left: -36px;
    width: 10px;
    background: #ccc;
    height: 10px;
    border: 3px solid #fff;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    position: absolute;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    z-index: 9;
}

.history-timeline .icon-dot {
    background: #2fadb9;
}

.history-timeline .date {
    color: #2fadb9;
}

.history-timeline .date {
    position: absolute;
    width: 100%;
    left: -129px;
    top: 49.5%;
    font-weight: bold;
    font-size: 25px;
}

@media (max-width: 767px) {
    .history-timeline .date {
        font-size: 14px;
        left: 0;
    }
    .history-timeline .icon-dot {
        left: 50px;
    }
    .history-timeline li {
        padding-left: 100px;
    }
    .history-timeline:after {
        left: 56px;
    }
}


/* #Accordion-box
 ================================================== */

.accordion-box-1 .accordion {
    background: #fff;
    margin-bottom: 10px;
}

.accordion-box-1 .accordion .accordion-title {
    cursor: pointer;
    padding: 10px 20px 10px 30px;
    border: 1px solid #e6e6e6;
    margin: 0;
    font-size: 18px;
    color: #333333;
}

.accordion-box-1 .accordion .accordion-title span {
    width: 30px;
    position: relative;
    height: 30px;
    float: right;
    vertical-align: bottom;
}

.accordion-box-1 .accordion .accordion-title span:before {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    content: "";
    display: inline-block;
    width: 2px;
    height: 12px;
    background: #aaaaaa;
}

.accordion-box-1 .accordion .accordion-title span:after {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    content: "";
    display: inline-block;
    width: 12px;
    height: 2px;
    background: #aaaaaa;
}

.accordion-box-1 .accordion .accordion-title p {
    margin: 0;
}

.accordion-box-1 .accordion.open .accordion-title span:before {
    display: none;
}

.accordion-box-1 .content-inner {
    border: 1px solid #ededed;
    padding: 25px 30px 20px 30px;
}

.accordion-box-2 .accordion.open .accordion-title {
    background: #2fadb9;
}

.accordion-box-2 .accordion .accordion-title {
    border: none;
    background: #031123;
}

.accordion-box-2 .accordion .accordion-title p {
    color: #fff;
}

.accordion-box-2 .accordion .accordion-content .content-inner {
    background: #2fadb9;
    border: none;
    color: #fff;
}

.accordion-box-2 .accordion .accordion-content .content-inner ul,
.accordion-box-2 .accordion .accordion-content .content-inner strong {
    display: block;
    margin-bottom: 20px;
}

.accordion-box-2 .accordion .accordion-content .content-inner p {
    color: #fff;
    margin-bottom: 0;
}

.accordion-box-2 .accordion .accordion-content .content-inner p strong {
    display: inline-block;
}

.partner-blck__brdr {
    border: 1px solid #afafaf;
    padding: 0 20px 0 0;
}

.partner__content {
    padding: 0 20px;
}

.partner-blck__brdr-r {
    border-right: 1px solid #afafaf;
}

.bottom__brdr {
    border-bottom: 1px solid #afafaf;
}

.abt__link {
    color: #000;
    font-weight: bold;
    display: inline-block;
    margin: 20px 0;
    -webkit-transition: color 0.22s ease-in-out;
    transition: color 0.22s ease-in-out;
}

.abt__link-arrow {
    font-size: 18px;
    vertical-align: middle;
    padding-left: 5px;
    color: #fb9513;
}

.abt__link:hover {
    color: #fb9513;
}


/*||=============About 3===============||*/


/*Our History*/

.history-box {
    background: #fff;
    padding: 20px;
    min-height: 330px;
    margin-top: 20px;
    position: relative;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
}

.history_date {
    padding: 6px 20px;
    display: inline-block;
    color: #fff;
    font-size: 18px;
    position: absolute;
    top: -17px;
    z-index: 9;
    left: 0;
    margin: 0 auto;
    right: 0;
    width: 100px;
    text-align: center;
}

.bg-color3 {
    background: #e73300;
}

.bg-color {
    background: #2fadb9;
}

div .secondary_bg {
    background: #2fadb9;
}


/*-------------------------------------------------------------------------------------------*/


/* Testimonial    ||---------------------------- */


/*-------------------------------------------------------------------------------------------*/

.testimonial-block {
    text-align: center;
    position: relative;
    padding: 0 30px 30px;
    width: 100%;
}

@media (max-width: 767px) {
    .mb-sm-80 {
        margin-bottom: 80px;
    }
}

.cyan-background {
    background: #fff;
    color: #2f3542;
}

.testimonial-img {
    position: relative;
    top: -40px;
}

.img-circle.img-border {
    border: 6px solid #f5f0f0;
    width: 80px !important;
    margin-left: auto;
    margin-right: auto;
}

.testimonial-author {
    color: #fff;
    font-weight: 800;
    display: block;
    font-size: 18px;
    margin-top: -25px;
    margin-bottom: 5px;
}

.testimonial-block .sub-title {
    color: #4d5056;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
    display: block;
}

.testimonial-block .small-divider {
    border-color: rgba(47, 53, 66, 0.5);
    margin-bottom: 20px;
    margin-top: 20px;
    width: 41px;
}

.cyan-background p {
    color: #2f3542;
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.5;
}

.testimonial-block>span {
    color: #d1d5de;
    font-size: 12px;
}

.star i {
    color: #fff;
    font-size: 14px;
    margin-bottom: 25px;
}

.list {
    list-style: none;
}

.list .text-color {
    color: #2fadb9;
}


/*carousel slider*/

.carousel-slider .carousel-item {
    width: 100% !important;
}

.testimonial-section .owl-carousel .owl-stage-outer {
    padding-top: 43px;
}

.arrow_theme .owl-prev,
.arrow_theme .owl-next {
    top: 40%;
    border: 2px solid #e9e9e9;
    color: #ccc;
    font-size: 22px;
    height: 1.8em;
    line-height: 1.6em;
    padding: 0;
    text-align: center;
    width: 1.8em;
    margin-right: 0;
    margin-left: 0;
    opacity: 1;
    background: none;
    position: absolute;
}

.testimonial-section .owl-prev,
.testimonial-section .owl-next {
    top: 60% !important;
}

.arrow_theme .owl-prev:hover,
.arrow_theme .owl-next:hover {
    border: 2px solid #2fadb9;
    color: #fff;
    background: #2fadb9;
}

.arrow_theme .owl-prev,
.arrow_theme .owl-next {
    top: 58%;
}

.arrow_theme .owl-prev {
    left: -44px;
}

.arrow_theme .owl-next {
    right: -44px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .arrow_theme .owl-prev {
        left: 0;
    }
    .arrow_theme .owl-next {
        right: 0;
    }
}

@media (max-width: 767px) {
    .arrow_theme .owl-prev {
        left: 0;
    }
    .arrow_theme .owl-next {
        right: 0;
    }
}


/******************************************************************************
 Shopping Cart
 *******************************************************************************/

.top-pad-80 {
    padding-top: 80px;
}

.shop .product-item {
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 0;
    max-width: 300px;
    margin: 0px auto 60px;
    text-align: center;
}

.shop.grid-3 .product-item,
.shop.grid-2 .product-item {
    max-width: 400px;
}

.shop .product-item:hover img {
    opacity: .6;
}

.shop .product-img {
    z-index: -1;
    position: relative;
}

.shop .product-details {
    background-color: #f7f7f7;
    padding-top: 5px;
    padding-bottom: 5px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.shop .product-details h4,
.shop .product-details h5 {
    margin-bottom: 0px;
    margin-top: 0px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.shop .product-details h5 {
    font-size: 18px;
    font-weight: 400;
}

.shop .product-details h5 del {
    padding-right: 10px;
    color: #1e1e1e;
    font-size: 16px;
}

.shop .product-overlay {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0px auto;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 9999;
}

.shop .product-overlay div {
    background-color: #f7f7f7;
    display: block;
    float: left;
    width: 50%;
    padding: 10px;
    color: #1e1e1e;
    margin: 0px;
}

.shop .product-overlay div:hover {
    background-color: #1e1e1e;
}

.shop .product-overlay .add-to-cart {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.shop .product-overlay a {
    color: #1e1e1e;
    text-decoration: none;
}

.shop .product-overlay div:hover a {
    color: #ffffff;
}

.shop .product-overlay a {
    color: #1e1e1e;
}

.shop .product-item:hover .product-overlay {
    opacity: 1;
}

.product-name a {
    color: #1e1e1e;
    text-decoration: none;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
}

.product-name a:hover {
    opacity: 0.8;
    filter: alpha(opacity=80);
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
}

.product-image {
    background: #f2f2f2;
    display: inline-block;
    line-height: 0;
}

.product-image>img {
    width: 100%;
    -webkit-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}

.product-image:hover>img {
    opacity: .9;
    filter: alpha(opacity=90);
    -webkit-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}

.rotation .product-image:hover>img {
    opacity: 1;
    filter: alpha(opacity=100);
}

.actual-price {
    color: #7f7f7f;
    display: inline-block;
    margin-right: 6px;
    position: relative;
}

.actual-price:before {
    background: #ff0000;
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    margin: 0 0 0 -28px;
    position: absolute;
    top: 50%;
    -webkit-transform: rotate(330deg);
    transform: rotate(330deg);
    width: 46px;
}

.price-details {
    border-bottom-width: 1px;
    font-size: 30px;
    line-height: 1;
    margin: 0 0 15px;
    padding: 0 0 15px;
}

.price-details h3 {
    display: inline-block;
    position: relative;
    padding-right: 40px;
    margin-right: 10px;
    margin-bottom: 0px;
}

.price-details h3:after {
    content: "\f07e";
    display: block;
    font-size: 16px;
    font-family: FontAwesome;
    right: 0;
    position: absolute;
    top: 15px;
}

.product-page .price-details .actual-price {
    font-size: 15px;
}

.price {
    font-size: 30px;
    line-height: 1;
    font-family: Oswald, sans-serif;
}

.arrow-style,
.list-style {
    list-style: none;
}


/* Zoom Product */

.single-product {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0;
    overflow: hidden;
    position: relative;
}

.zoom-product-thumb {
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.zoom-product-thumb img {
    display: inline-block;
    width: 80px;
}

.zoomWrapper img,
.zoomContainer img {
    width: 100%;
}

.zoomWindow {
    border: none !important;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    z-index: 99 !important;
}

.product-regulator {
    display: inline-block;
    margin-bottom: 40px;
    vertical-align: top;
}

@media (max-width: 767px) {
    .product-regulator {
        margin-bottom: 20px;
    }
}

.product-regulator button {
    border: 1px solid #e1e1e1;
    border-radius: 0;
    color: #2fadb9;
    height: 44px;
    padding: 11px 12px;
    display: inline-block;
}

.product-regulator .output {
    color: #7f7f7f;
    display: inline-block;
    height: 44px;
    padding: 11px 12px;
    text-align: center;
    vertical-align: top;
    width: 80px;
    border: 1px solid #e1e1e1;
}

.product-meta-details {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 15px;
    font-size: 14px;
}

.btn-default,
.bar {
    background-color: #2fadb9;
}


/* Ribbon */

.ribbon-wrapper {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    top: -3px;
    right: -3px;
}

.ribbon {
    text-align: center;
    font-size: 12px;
    position: relative;
    padding: 5px 0;
    left: -5px;
    top: 15px;
    width: 120px;
    -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}

.ribbon:before,
.ribbon:after {
    content: "";
    border-top: 3px solid #6e8900;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    position: absolute;
    bottom: -3px;
}

.ribbon:before {
    left: 0;
}

.ribbon:after {
    right: 0;
}

.ribbon.red {
    background-color: #ff0000;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ff0000), to(#bf0c0c));
    background-image: -webkit-linear-gradient(top, #ff0000, #bf0c0c);
    background-image: -moz-linear-gradient(top, #ff0000, #bf0c0c);
    background-image: -ms-linear-gradient(top, #ff0000, #bf0c0c);
    background-image: -o-linear-gradient(top, #ff0000, #bf0c0c);
}

.ribbon.blue {
    background-color: #03b6f7;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#03b6f7), to(#0c8fbf));
    background-image: -webkit-linear-gradient(top, #03b6f7, #0c8fbf);
    background-image: -moz-linear-gradient(top, #03b6f7, #0c8fbf);
    background-image: -ms-linear-gradient(top, #03b6f7, #0c8fbf);
    background-image: -o-linear-gradient(top, #03b6f7, #0c8fbf);
}

.ribbon.green {
    background-color: #BFDC7A;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#BFDC7A), to(#8EBF45));
    background-image: -webkit-linear-gradient(top, #BFDC7A, #8EBF45);
    background-image: -moz-linear-gradient(top, #BFDC7A, #8EBF45);
    background-image: -ms-linear-gradient(top, #BFDC7A, #8EBF45);
    background-image: -o-linear-gradient(top, #BFDC7A, #8EBF45);
}

.ribbon.yellow {
    background-color: #2fadb9;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#2fadb9), to(#eab505));
    background-image: -webkit-linear-gradient(top, #2fadb9, #eab505);
    background-image: -moz-linear-gradient(top, #2fadb9, #eab505);
    background-image: -ms-linear-gradient(top, #2fadb9, #eab505);
    background-image: -o-linear-gradient(top, #2fadb9, #eab505);
}

.ribbon.no-gradiant {
    background-image: none;
}

.ribbon.white {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    -moz-text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    -webki-text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}


/******************************************************************************
 sidebar_shop
 *******************************************************************************/

.sidebar_shop .widget_shop {
    margin-bottom: 40px;
}

.widget_shop .list-group.panel>.list-group-item {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

.widget_shop .list-group-submenu {
    margin-left: 20px;
}

.widget_shop .list-group-item {
    border: 0;
}

.widget_shop .panel {
    border: 0;
    box-shadow: none;
}

.widget_shop a.list-group-item:focus,
.widget_shop a.list-group-item:hover {
    background: none;
}

.list-group-item {
    list-style: outside none none;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-top: 5px;
    position: relative;
}

.list-group-item:before {
    content: "\f105";
    display: block;
    font-family: FontAwesome;
    left: 0;
    position: absolute;
    top: 5px;
}

.list-group-item .fa {
    margin-left: 7px;
    display: none;
}


/* Tags */

ul.tags,
.filter-price-box {
    display: table;
    margin: 0 auto;
}

ul.tags li {
    float: left;
    margin: 0 3px 3px 0;
}

.tags li a {
    border-radius: 2px;
    color: #ffffff !important;
    display: block;
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    padding: 0 5px;
    text-decoration: none;
    transition: all 0.2s linear 0s;
}

.tags li a:hover {
    opacity: .5;
}


/* Social Feed widget_shop */

.social-feed.loaded {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
}

.social-feed {
    display: table;
    margin: 0 auto;
}

.social-feed li {
    float: left;
    height: 62px;
    list-style: outside none none;
    margin-bottom: 5px;
    margin-right: 5px;
    width: 60px;
}

.social-feed li img {
    height: 62px;
    width: 62px;
}


/* Shop widget_shops */

.widget_shop .price {
    font-size: 18px;
}

.widget_shop .shop h5 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.widget_shop .price {
    padding-bottom: 10px;
}

.widget_shop .price del {
    margin-right: 10px;
    font-size: 14px;
}


/* Poll */

.new-radio {
    border-radius: 7px;
    height: 14px;
    width: 14px;
}

.new-radio,
.new-checkbox {
    background: none repeat scroll 0 0 #fff;
    cursor: pointer;
    float: left;
    margin: 0px 0 0 -20px;
    position: absolute;
}


/* Price Range Filter */

.filter .filter-price-box {
    padding-bottom: 15px;
}

.nstSlider {
    background: none #CCCCCC;
    cursor: pointer;
    height: 2px;
    position: relative;
    width: 260px;
    max-width: 100%;
    margin: 25px auto 40px;
}

.bar {
    height: 3px;
    cursor: pointer;
    position: absolute;
}

.nstSlider .rightGrip,
.nstSlider .leftGrip {
    position: absolute;
    top: 5px;
    margin-left: -2px;
    border-width: 0px 5px 0;
}

.range .leftLabel {
    left: 0px;
    top: 12px;
    font-size: 12px;
    font-family: 'Lato';
    position: absolute;
}

.range .rightLabel {
    font-family: 'Lato';
    top: 12px;
    left: 0px;
    font-size: 12px;
    position: absolute;
}

.bar:after {
    height: 8px;
    width: 1px;
    position: absolute;
    right: -1px;
    top: -3px;
    content: "";
    cursor: pointer;
}

.bar:before {
    height: 8px;
    width: 1px;
    position: absolute;
    left: 0px;
    top: -3px;
    content: "";
    cursor: pointer;
}

.nstSlider:after {
    height: 8px;
    width: 1px;
    background: #bebebe;
    position: absolute;
    right: -1px;
    top: -3px;
    content: "";
    cursor: pointer;
}

.nstSlider:before {
    height: 8px;
    width: 1px;
    background: #bebebe;
    position: absolute;
    left: 0px;
    top: -3px;
    content: "";
    cursor: pointer;
}

.rightGrip:before {
    content: "";
    height: 15px;
    width: 2px;
    position: absolute;
    right: -8px;
    top: -5px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 10px solid;
}

.leftGrip:before {
    content: "";
    height: 15px;
    width: 2px;
    position: absolute;
    left: 0;
    top: -5px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 10px solid;
}

.rightGrip:after {
    content: "";
    height: 35px;
    width: 36px;
    position: absolute;
    right: -26px;
    top: 0px;
}

.leftGrip:after {
    content: "";
    height: 35px;
    width: 36px;
    position: absolute;
    left: -9px;
    top: 0px;
}

.rightGrip:before,
.leftGrip:before {
    border-bottom: 10px solid #2fadb9;
}

.filter-print-price {
    padding: 7px 0px 0px;
    margin: 0px 17px;
}

.filter-print-price a h4 {
    padding: 5px 31px;
    color: #FFFFFF;
    text-align: center;
    font-family: 'Lato';
    font-size: 14px;
    margin: 0;
    width: 95px;
    border: 1px solid;
    text-transform: capitalize;
}

.filter-print-price a h4:hover {
    background-color: #FFFFFF;
    border: 1px solid;
}

.filter-print-price .price {
    font-family: 'Lato';
    color: #656565;
    font-weight: 600;
    font-size: 14px;
    margin-left: 6px;
    text-align: center;
}

.drag {
    font-family: 'Lato';
    color: #656565;
    font-size: 12px;
    font-weight: 600;
    margin: 12px 0 0 -9px;
    display: block;
}

.filter-print-price li {
    display: inline-block;
    list-style: none;
}

.shop-carousel .owl-nav {
    position: absolute;
    top: 0;
    right: 18px;
    width: 100%;
}


/******************************************************************************
 widget_shop
 *******************************************************************************/

.widget_shop ul {
    padding: 0px;
    margin: 0px;
}

.widget_shop li {
    list-style: none;
}

.widget_shop li a,
.widget_shop li a:visited {
    color: #7f7f7f;
    text-decoration: none;
}

.widget_shop li {
    line-height: 21px;
}

.widget_shop a,
.widget_shop a:visited {
    color: #7f7f7f;
    text-decoration: none;
    -webkit-transition: color .2s linear;
    transition: color .2s linear;
}

.widget_shop a:hover {
    color: #000;
    -webkit-transition: color .2s linear;
    transition: color .2s linear;
}

.widget_shop .widget_shop-title .title {
    font-weight: 600;
    line-height: 1.3;
    margin: 0 0 12px;
    font-size: 20px;
}

.widget_shop .widget_shop-title {
    border-bottom: 1px solid #e1e1e1;
    margin: 0 0 12px;
    position: relative;
}

.sidebar_shop.widget_shop .widget_shop-title {
    border-bottom: 1px solid #e1e1e1;
    margin: 0 0 16px;
    position: relative;
}


/* Latest News */

.latest-posts {
    margin: 0px;
    padding: 0;
}

.latest-posts li {
    list-style: none;
    clear: both;
    margin-bottom: 35px;
}

.latest-posts li:last-child {
    margin-bottom: 10px;
}

.latest-posts .post-thumb {
    width: 100px;
}

.latest-posts .post-thumb {
    float: left;
}

.latest-posts li img {
    margin-right: 10px;
}


/* Tabs */

.tab-content {
    margin-bottom: 20px;
    margin-top: 20px;
}

.left-tabs>.nav-tabs {
    border-width: 0;
    float: left;
    margin: 0 -1px 0 0;
}

.right-tabs>.nav-tabs {
    border-width: 0;
    float: right;
    margin: 0 -1px 0 0;
}

.left-tabs>.nav-tabs li,
.right-tabs>.nav-tabs li {
    float: none;
    display: block;
}

.left-tabs>.nav-tabs>li.active>a,
.left-tabs>.nav-tabs>li.active>a:focus,
.left-tabs>.nav-tabs>li.active>a:hover {
    border-width: 1px;
    border-style: solid none solid solid;
    border-color: #ddd;
}

.right-tabs>.nav-tabs>li.active>a,
.right-tabs>.nav-tabs>li.active>a:focus,
.right-tabs>.nav-tabs>li.active>a:hover {
    border-width: 1px;
    border-style: solid solid solid none;
    border-color: #ddd;
}

.left-tabs>.nav-tabs li a {
    border-right: 1px solid #ddd;
}

.right-tabs>.nav-tabs li a {
    border-left: 1px solid #ddd;
}

.left-tabs>.nav-tabs {
    margin-right: 20px;
}

.nav-tabs {
    margin-bottom: 0;
    margin-left: 0;
}

.nav-tabs>li>a {
    -webkit-border-radius: 2px 2px 0 0;
    -moz-border-radius: 2px 2px 0 0;
    border-radius: 2px 2px 0 0;
    color: #1e1e1e;
    font-size: 12px;
    padding: 10px 20px 11px;
    -webkit-transition: background-color .2s linear;
    transition: background-color .2s linear;
}

.nav>li>a:hover,
.nav>li>a:focus {
    background-color: #f7f7f7;
    -webkit-transition: background-color .2s linear;
    transition: background-color .2s linear;
}

.nav-tabs>li>a:hover,
.nav-tabs>li>a:focus {
    border-color: #f7f7f7 #f7f7f7 #e1e1e1;
}

.nav-tabs>.active>a,
.nav-tabs>.active>a:hover,
.nav-tabs>.active>a:focus {
    border: 1px solid #e1e1e1;
    border-bottom-color: transparent;
    color: #1e1e1e;
    font-weight: bold;
}

.nav-tabs>.active.dropdown>a {
    z-index: 911;
}

.tab-content>.tab-pane,
.pill-content>.pill-pane {
    padding: 0;
}

.tab-content.border {
    padding: 0 20px 20px 20px;
    border: 1px solid #ddd;
    border-top: 0;
}

.tabs-left>.nav-tabs>li,
.tabs-right>.nav-tabs>li {
    float: none;
    margin: 0;
}

.tabs-bottom .tab-content>.tab-pane,
.tabs-bottom .pill-content>.pill-pane,
.tabs-bottom .tab-content {
    padding: 0px 0 0;
    margin: 0;
}

.tabs-bottom.tab-style.dark .nav-tabs {
    text-align: center;
    float: none;
    border: 0;
}

.tabs-bottom>.nav-tabs>li {
    float: none;
    margin: 0;
    display: inline-block;
}

.tabs.border {
    border: 0;
}

.tabs.border>.nav-tabs>li>a {
    border: 1px solid #ddd;
}

.tabs.border>.nav-tabs>li.active>a {
    border-bottom: 1px solid transparent;
}

.tabs-right>.nav-tabs>li>a,
.tabs-bottom>.nav-tabs>li>a {
    min-width: 110px;
    margin-right: 0;
    margin-bottom: 3px;
    border: 1px solid #ddd;
}

.tabs-left>.nav-tabs>li>a,
.tabs-right>.nav-tabs>li>a {
    min-width: 110px;
    margin-right: 0;
    margin-bottom: 3px;
    border: 1px solid #ddd;
}

.tabs-left>.nav-tabs {
    border: 0 none;
    border-right: 1px solid #ddd;
    float: left;
    margin: 0 -1px 0 0;
}

.tabs-left>.nav-tabs>li>a {
    margin-right: -1px;
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
}

.tabs-left>.nav-tabs>li>a:hover,
.tabs-left>.nav-tabs>li>a:focus {
    border-color: #f7f7f7 #ddd #f7f7f7 #f7f7f7;
}

.tabs-left>.nav-tabs .active>a,
.tabs-left>.nav-tabs .active>a:hover,
.tabs-left>.nav-tabs .active>a:focus {
    border-color: #ddd transparent #ddd #ddd;
}

.tabs-left>.tab-content {
    border-left: 1px solid #ddd;
    margin: 0;
    overflow: auto;
}

.tabs-left>.tab-content>.tab-pane {
    padding: 0 0 0 20px;
}

.tabs-right>.nav-tabs {
    border: 0 none;
    border-left: 1px solid #ddd;
    float: right;
    margin: 0 0 0 -1px;
}

.tabs-right>.nav-tabs>li>a {
    margin-left: -1px;
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
}

.tabs-right>.nav-tabs>li>a:hover,
.tabs-right>.nav-tabs>li>a:focus {
    border-color: #f7f7f7 #f7f7f7 #f7f7f7 #ddd;
}

.tabs-right>.nav-tabs .active>a,
.tabs-right>.nav-tabs .active>a:hover,
.tabs-right>.nav-tabs .active>a:focus {
    border-color: #ddd #ddd #ddd transparent;
}

.tabs-right>.tab-content {
    border-right: 1px solid #ddd;
    margin: 0;
    overflow: auto;
}

.tabs-right>.tab-content>.tab-pane {
    padding: 0 20px 0 0;
}

.tabs-right.no-pad>.tab-content>.tab-pane,
.tabs-left.no-pad>.tab-content>.tab-pane {
    padding: 0 0px 0 0;
}

.tab-style .nav-tabs {
    border-left: 0;
}

.tabs-bottom.tab-style>.nav-tabs>li {
    border-radius: 4px;
}

.tabs-bottom.tab-style>.nav-tabs>li>a {
    border-radius: 4px;
    margin-left: 0px;
    margin-bottom: 0px;
    color: #ffffff;
    border: 0;
}

.tab-style>.nav-tabs>li>a {
    border-radius: 4px;
    margin-left: -1px;
    margin-bottom: 5px;
    color: #ffffff;
}

.tab-style>.nav-tabs>li {
    background: rgba(0, 0, 0, .2);
}

.tab-style.dark>.nav-tabs>li {
    background: rgba(0, 0, 0, .7);
}

.tab-style.dark .nav-tabs {
    border-left: inherit;
    margin: 0 !important;
}

.tab-style>.nav-tabs .active>a,
.tab-style>.nav-tabs .active>a:hover,
.tab-style>.nav-tabs .active>a:focus,
.tab-style>.nav-tabs>li>a:hover,
.tab-style>.nav-tabs>li>a:focus {
    background: rgba(0, 0, 0, .7);
    color: #f7f7f7;
    font-weight: normal;
}

.tab-style>.tab-content {
    border: 0;
}

.tab-style>.nav-tabs .active>a,
.tab-style>.nav-tabs .active>a:hover,
.tab-style>.nav-tabs .active>a:focus {
    border: 1px solid rgba(0, 0, 0, .2);
}

.shop img {
    height: auto;
    max-width: 100%;
}


/* ==================================== Check-Out Page Css ======================= */

.romana_check_out_form .common_input input,
.romana_check_out_form .common_input select {
    width: 100%;
    border: 1px solid #e3e3e3;
    background: #fff;
    border-radius: 3px;
    margin-bottom: 30px;
}

.romana_check_out_form .select_option_one::after {
    color: #b8b8b8;
}

.check_form_left {
    margin-right: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .check_form_left {
        margin-right: 10px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .check_form_left {
        margin-right: 0px;
    }
}

.check_form_left h3 {
    margin-bottom: 50px;
    font-size: 24px;
}

.check_form_right {
    padding: 35px 30px 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .check_form_right {
        padding: 35px 10px 40px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .check_form_right {
        padding: 35px 10px 40px;
    }
}

.check_form_right h3 {
    font-size: 24px;
    margin-bottom: 22px;
}

.product_order ul,
.romana_select_method ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}

.check_form_right .product_order ul li {
    padding: 8px 5px;
}

.check_form_right .product_order ul li:first-child {
    border-bottom: 1px solid #e3e3e3;
}

.check_form_right .product_order ul li:nth-child(2) {
    font-weight: 300;
    font-size: 15px;
    padding: 15px 5px;
    border-bottom: 1px solid #e3e3e3;
    margin-bottom: 8px;
}

.check_form_right .product_order ul li span {
    float: right;
}

.check_form_right .paypal_img {
    margin-top: 20px;
}

.check_form_right .common_btn {
    width: 100%;
    color: #fff;
    margin-top: 36px;
}

.romana_select_method {
    margin-top: 30px;
}

.romana_select_method li {
    margin-top: 20px;
}

.romana_select_method label {
    padding-left: 14px;
}


/* ==================================== Cart Page Css ======================= */

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .table_scroll {
        overflow-x: scroll;
    }
}

.padding_all {
    padding-top: 66px !important;
}

.cart_amount {
    margin-top: -12px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .cart_amount {
        margin-top: 4px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .cart_amount {
        margin-top: 0px;
    }
}

.table.table-striped.table-responsive a {
    border-radius: 14px;
    color: #fff;
    font-size: 12px;
    height: 14px;
    line-height: 15px;
    position: absolute;
    right: 50px;
    text-align: center;
    top: 47%;
    width: 14px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .table.table-striped.table-responsive a {
        top: 52%;
        right: 25px;
    }
}

.table>tr>th {
    border-bottom: medium none;
    color: #fff;
    padding: 15px;
    vertical-align: bottom;
    padding-left: 20px;
}

.table>thead>tr>th {
    border-bottom: medium none;
    color: #fff;
    vertical-align: bottom;
    padding-left: 48px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.table>tbody>tr>td,
.table>tbody>tr>th {
    border-bottom: 1px solid #f6f6f6;
    padding-left: 48px;
    position: relative;
    padding-top: 28px;
    padding-bottom: 28px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .table>tbody>tr>td,
    .table>tbody>tr>th {
        padding-left: 2px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .table>tbody>tr>td,
    .table>tbody>tr>th {
        padding-left: 20px;
    }
}

.table.table-striped.table-responsive p {
    margin-top: 37px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .table.table-striped.table-responsive p {
        font-size: 15px;
    }
}

.table-striped>tbody>tr:nth-of-type(2n+1) {
    background-color: #ffff;
}

.table.table-striped.table-responsive img {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}

.cart_page_area .cart_btns {
    margin-top: 36px;
}

.cart_page_area .common_btn {
    background: #4daefe;
    color: #fff;
    width: 200px;
    box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.11);
    height: 48px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .cart_page_area .common_btn {
        width: 0% !important;
        margin-top: 20px;
        line-height: 45px;
    }
}

.cart_page_area .hvr-bounce-to-right::before {
    background: #373737 !important;
}

.cart_page_area .cart_page_btn1 {
    background: #fff;
    border: 1px solid #e3e3e3;
    color: #8b8b8b;
    font-weight: 300;
    font-size: 15px;
    box-shadow: none;
    width: 300px !important;
    margin-right: 18px;
    position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .cart_page_area .cart_page_btn1 {
        width: 200px !important;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .cart_page_area .cart_page_btn1 {
        width: 99% !important;
    }
}

.cart_page_area .cart_page_btn3 {
    width: 150px !important;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .cart_page_area .cart_page_btn3 {
        width: 100% !important;
    }
}

.cart_page_btn2 {
    left: 43%;
    position: absolute;
    top: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .cart_page_btn2 {
        left: 51%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .cart_page_btn2 {
        left: 47%;
    }
}

.cart_amount>input {
    background: #fbfbfb;
    border: medium none;
    border-radius: 50px;
    height: 48px;
    padding: 6px 22px;
    width: 130px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}

.cart_wrpaer img {
    width: 93px
}

.padding_all .product-regulator {
    margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .cart_amount>input {
        margin-top: -16px;
    }
}

@media (max-width: 767px) {
    .cart_amount>input {
        margin-top: -16px;
    }
}

.cart_totals_area {
    overflow: hidden;
    margin-top: 82px;
    padding: 54px 48px 30px;
}

.cart_totals_area ul {
    margin: 0;
}

@media (max-width: 767px) {
    .cart_totals_area {
        padding: 54px 25px 30px;
    }
}

.cart_totals_area h3 {
    margin-bottom: 40px;
}

.cart_totals_area li {
    overflow: hidden;
    padding-bottom: 14px;
}

.cart_totals_area li:last-child {
    border-top: 1px solid #dbdbdb;
    padding-top: 13px;
}

.cart_totals_area li span {
    float: right;
}

.cart_totals_area .common_btn {
    width: 250px !important;
    float: right;
    margin-top: 25px;
}

@media (max-width: 767px) {
    .cart_totals_area .common_btn {
        width: 100% !important;
        float: none;
    }
    .cart_page_btn2 {
        left: 0;
        top: 88px;
    }
    .mt-xs-20 {
        margin-top: 20px;
    }
}


/* ==============================
 20. Side Bar Widget styles
 ============================== */

.single-sidebar-widget ul {
    list-style: none;
    padding-left: 0;
}

.single-sidebar-widget {
    margin-bottom: 50px;
}

.single-sidebar-widget:last-child {
    margin-bottom: 0;
}

.single-sidebar-widget .sec-title {
    margin-bottom: 30px;
}

.single-sidebar-widget .sec-title h2 {
    font-size: 20px;
    font-family: 'Oswald', sans-serif;
}

.single-sidebar-widget .sec-title {
    font-size: 15px;
    font-weight: 600;
}

.single-sidebar-widget .sec-title h2:after {
    width: 35px;
}

.single-sidebar-widget .contact-form input,
.single-sidebar-widget .contact-form .select-menu+.ui-selectmenu-button {
    height: 40px;
}

.single-sidebar-widget .contact-form .select-menu+.ui-selectmenu-button {
    margin-bottom: 2px;
}

.single-sidebar-widget .contact-form .select-menu+.ui-selectmenu-button .ui-selectmenu-text {
    line-height: 40px;
}

.single-sidebar-widget .dwnld-broucher a {
    text-transform: uppercase;
    color: #08ADD0;
    font-weight: 600;
    margin-top: 30px;
    font-size: 12px;
    display: inline-block;
}

.single-sidebar-widget .special-links li:last-child {
    margin-bottom: 0;
}

.single-sidebar-widget .special-links li a {
    background: #f9f9f9;
    color: #323232;
    border: 1px solid #e9e9e9;
    font-weight: 600;
    text-transform: uppercase;
    display: block;
    margin-bottom: 5px;
    line-height: 50px;
    position: relative;
    padding: 0 20px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    position: relative;
}

.single-sidebar-widget .special-links li:hover a:after,
.single-sidebar-widget .special-links li.active a:after {
    color: inherit;
}

.single-sidebar-widget .special-links li a:after {
    content: '\f101';
    font-family: 'FontAwesome';
    position: absolute;
    top: 0;
    right: 20px;
    line-height: 50px;
    color: #6A6969;
    font-weight: normal;
}

.single-sidebar-widget .special-links li a:before {
    content: '\f0da';
    font-family: 'FontAwesome';
    position: absolute;
    top: 0;
    left: -1px;
    color: #2fadb9;
    font-size: 0px;
    line-height: 50px;
    opacity: 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    border-left: 4px solid #2fadb9;
}

.single-sidebar-widget .special-links li:hover a,
.single-sidebar-widget .special-links li a.active {
    background: #fff;
    color: #2fadb9;
}

.single-sidebar-widget .special-links li a.active:after {
    color: #2fadb9;
}

.single-sidebar-widget .special-links li a:hover:before,
.single-sidebar-widget .special-links li a.active:before {
    opacity: 1;
}

.search-box placeholder {
    color: #000;
}

.single-sidebar-widget .search-box form {
    background: #e5e5e5;
    width: 100%;
    height: 48px;
    border: 1px solid #e9e9e9;
}

.single-sidebar-widget .search-box form input {
    padding-left: 30px;
    border: none;
    outline: none;
    height: 100%;
    color: rgba(255, 255, 255, 0.5);
    width: calc(100% - 55px);
    background-color: transparent;
}

.single-sidebar-widget .search-box form input::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: rgba(255, 255, 255, 0.5);
    opacity: 1;
}

.single-sidebar-widget .search-box form input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: rgba(255, 255, 255, 0.5);
    opacity: 1;
}

.single-sidebar-widget .search-box form input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: rgba(255, 255, 255, 0.5);
    opacity: 1;
}

.single-sidebar-widget .search-box form input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(255, 255, 255, 0.5);
}

.single-sidebar-widget .search-box form input:placeholder-shown {
    /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
    color: rgba(186, 185, 185, 1);
}

.single-sidebar-widget .search-box form button {
    position: relative;
    left: 2px;
    width: 50px;
    background: #2fadb9;
    color: #000000;
    font-size: 16px;
    padding: 11px 0px;
    border: none;
    outline: none;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.single-sidebar-widget .search-box form button:hover {
    background: #1FB5C7;
}

.single-sidebar-widget .search-box form button:hover i {
    color: #fff;
}

.single-sidebar-widget .search-box form button:hover:after {
    background: #1FB5C7;
}

.single-sidebar-widget .search-box form button i {
    position: relative;
    z-index: 2;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.single-sidebar-widget .categories ul li:last-child a {
    border: none;
}

.single-sidebar-widget .categories ul li a {
    color: #898989;
    text-transform: capitalize;
    line-height: 42px;
    display: block;
    border-bottom: 1px solid #E6E6E6;
    position: relative;
}

.single-sidebar-widget .categories ul li a:after {
    content: '\f105';
    font-family: 'FontAwesome';
    color: #C8C8C8;
    position: absolute;
    top: 0;
    right: 0;
}

.single-sidebar-widget .popular-post .img-cap-effect .img-box .img-caption:before {
    border-width: 2px;
}

.single-sidebar-widget .popular-post li {
    margin-bottom: 30px;
}

.single-sidebar-widget .popular-post li:last-child {
    margin-bottom: 0;
}

.single-sidebar-widget .popular-post .img-box,
.single-sidebar-widget .popular-post .content {
    display: table-cell;
    vertical-align: middle;
}

.single-sidebar-widget .popular-post .img-box {
    width: 110px;
}

.single-sidebar-widget .popular-post .content {
    padding-left: 30px;
}

.single-sidebar-widget .popular-post .content a h4 {
    font-family: 'Open Sans', sans-serif;
    color: #242424;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.single-sidebar-widget .popular-post .content a h4:hover {
    color: #1FB5C7;
}

.single-sidebar-widget .popular-post .content p {
    color: #8E8D8D;
    font-size: 13px;
    line-height: 20px;
    margin: 0;
}

.single-sidebar-widget .popular-post .content span {
    color: #1CBAC8;
    font-size: 13px;
    display: block;
    margin-top: 4px;
    font-weight: 500;
}

.single-sidebar-widget .text-box p {
    color: #8E8D8D;
}

.single-sidebar-widget .insta-feed ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.single-sidebar-widget .insta-feed ul .img-cap-effect .img-box .img-caption:before {
    border-width: 2px;
}

.single-sidebar-widget .insta-feed {
    font-size: 0;
    display: inline-block;
}

.single-sidebar-widget .insta-feed img {
    max-width: 100%;
    display: inline-block;
    font-size: 0;
    margin-bottom: 1px;
    margin-right: 1px;
}

.single-sidebar-widget .tag-cloud {
    font-size: 0;
    margin-left: -5px;
    margin-right: -5px;
}

.single-sidebar-widget .tag-cloud li {
    display: inline-block;
}

.single-sidebar-widget .tag-cloud li a {
    display: block;
    color: #787878;
    text-transform: uppercase;
    border: 1px solid #EAEAEA;
    border-radius: 20px;
    padding: 7px 22px;
    font-size: 12px;
    margin: 0 5px;
    margin-bottom: 10px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.single-sidebar-widget .tag-cloud li a:hover {
    background: #E5E5E5;
    color: #787878;
    border-color: #E5E5E5;
}

.single-client-box {
    margin-bottom: 50px;
}

.single-client-box:last-child {
    margin-bottom: 0;
}

.single-client-box .icon-box,
.single-client-box .content {
    display: table-cell;
    vertical-align: middle;
}

.single-client-box .icon-box {
    width: 170px;
    text-align: center;
    border-top: 4px solid #1FB5C7;
    border-left: 1px solid #E9E9E9;
    border-bottom: 1px solid #E9E9E9;
}

.single-client-box .content {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 30px;
    padding-left: 30px;
    border-right: 1px solid #E9E9E9;
    border-bottom: 1px solid #E9E9E9;
    border-top: 1px solid #E9E9E9;
}

.single-client-box .content h3 {
    font-size: 18px;
    font-weight: bold;
    font-family: 'Alegreya', sans-serif;
    color: #242424;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.single-client-box .content p {
    color: #898989;
    margin: 0;
}

.single-sidebar-widget ul.brochure-btns li a {
    font-family: 'Open Sans', sans-serif;
    color: #404040;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    display: block;
    background: #FBFBFB;
    border: 1px solid #EDEDED;
    padding: 12px 20px;
}

.single-sidebar-widget ul.brochure-btns li {
    display: block;
    margin-bottom: 21px;
}

.single-sidebar-widget ul.brochure-btns li a i {
    color: #e94429;
    margin: 0 10px 0;
    font-size: 1.2em;
}

.faq.faq-service .panel-group .panel-heading+.panel-collapse>.panel-body {
    padding: 20px 18px;
    background: rgba(242, 237, 233, 0.38)
}

@media (max-width: 480px) {
    h1 {
        font-size: 36px;
    }
}

.nf-carousel-theme-top .owl-controls .owl-nav .owl-prev,
.nf-carousel-theme-top .owl-controls .owl-nav .owl-next {
    top: -45px;
    right: 0;
    left: auto;
    border: 2px solid #e9e9e9;
    color: #ccc;
    font-size: 22px;
    height: 1.8em;
    line-height: 1.6em;
    padding: 0;
    text-align: center;
    width: 1.8em;
    margin-right: 0;
    margin-left: 0;
    opacity: 1;
    background: none;
}

.nf-carousel-theme-top .owl-controls .owl-nav .owl-prev {
    right: 50px;
}

.nf-carousel-theme-top .owl-controls .owl-nav .owl-prev:hover,
.nf-carousel-theme-top .owl-controls .owl-nav.owl-next:hover {
    border: 2px solid #2fadb9;
    color: #fff;
    background: #2fadb9;
}


/*  14  - Contact Us
 ----------------------------------------------*/

.contact-info {
    background-color: #fff;
    margin-top: 50px;
}

.contact-info h4 {
    font-size: 20px;
    color: #65ac4c;
    padding: 20px;
    border-bottom: 1px solid #eeeeee;
}

.contact-info .info {
    padding: 20px 30px;
    margin: 0px;
}

.contact-info .info li {
    list-style: none;
    border-bottom: 1px solid #eeeeee;
    overflow: hidden;
    padding: 15px 0px;
}

.contact-info .info li .icon {
    float: left;
    margin-right: 15px;
    font-size: 36px;
    color: #65ac4c;
    line-height: 1;
}

.contact-info .info li .icon:before {
    font-size: 26px;
}

.contact-info .info li .content {
    display: table;
}

.contact-info .info li .content p {
    margin: 0px;
    color: #696969;
    font-size: 14px;
    font-weight: 500;
}

.contact-info .event-social {
    padding: 0px 30px 30px;
}

.contact-info .event-social li {
    margin-right: 5px;
}

.contact-info .event-social li a {
    height: 40px;
    width: 40px;
    line-height: 2;
}

.contact .contact-form h3 {
    font-size: 30px;
    margin-bottom: 30px;
}

.contact .contact-info {
    margin: 0px;
}

.contact .contact-info h3 {
    font-size: 30px;
    margin-bottom: 10px;
}

.contact .contact-info .info {
    padding: 0px;
    margin-bottom: 20px;
}

.contact .contact-info .event-social {
    padding: 0px;
}

.event-social {
    overflow: hidden;
    margin-bottom: 30px;
}

.event-social li {
    list-style: none;
    display: inline-block;
    margin-right: 7px;
    margin-bottom: 5px;
}

.event-social li a {
    color: #fff;
    font-size: 22px;
    background-color: #3b5998;
    height: 50px;
    width: 50px;
    display: inline-block;
    text-align: center;
    border-radius: 100%;
    line-height: 2.5em;
}

.event-social li:nth-child(2) a {
    background-color: #55acee;
}

.event-social li:nth-child(3) a {
    background-color: #ff0084;
}

.event-social li:nth-child(4) a {
    background-color: #f26798;
}

.event-social li:nth-child(5) a {
    background-color: #cb2027;
}

.event-social li:last-child a {
    background-color: #dc4e41;
}


/*-------------------------------------------------------------------------------------------*/


/* Project  ||---------------------------- */


/*-------------------------------------------------------------------------------------------*/

.filter-button-group {
    list-style: none;
    padding: 0;
    overflow: hidden;
}

.filter-button-group li {
    float: left;
    margin-bottom: 10px;
    margin-right: 20px;
}

.line-btn {
    background: none;
    color: #323232;
    padding: 5px 10px;
    border: 2px solid #e1e1e1;
    display: block;
    cursor: pointer;
    -webkit-transition: all 0.2s linear 0s;
    -moz-transition: all 0.2s linear 0s;
    -ms-transition: all 0.2s linear 0s;
    -o-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s;
}

.line-btn:hover,
.line-btn.active {
    background: #6aaf08;
    border: 2px solid #6aaf08;
    color: #fff;
}

.project-col-3 {
    list-style: none;
    padding-left: 0;
    margin-left: -30px;
}

.project-col-3 li {
    float: left;
    padding-left: 30px;
    width: 33.33%;
    margin-bottom: 15px;
}

@media (max-width: 991px) {
    .project-col-3 li {
        width: 50%;
    }
}

@media (max-width: 767px) {
    .project-col-3 li {
        width: 100%;
    }
}

.bg-img1 {
    background: url(/img/slider-home-construction-02.jpg) no-repeat;
}


/***

 ====================================================================
 Team Section
 ====================================================================

 ***/

.team-section {
    position: relative;
}

.team-member {
    position: relative;
    margin-bottom: 60px;
}

.owl-carousel .team-member {
    width: 100% !important;
    max-width: 100% !important;
}

.team-member .inner-box {
    position: relative;
    overflow: hidden;
}

.team-member .inner-box .image-box {
    position: relative;
}

.team-member .inner-box .image-box img {
    position: relative;
    width: 100%;
    display: block;
}

.team-member .inner-box .overlay-box {
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;
    padding: 13px 30px;
}

.team-member .inner-box .overlay-box .user-info {
    position: relative;
}

.team-member .inner-box .overlay-box .user-info .text {
    position: relative;
    color: #777777;
    font-size: 14px;
    font-family: 'Courgette', cursive;
}

.team-member .inner-box .overlay-box .user-info h4 {
    color: #444444;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
}

.team-member .inner-box .overlay-box:before {
    position: absolute;
    content: '';
    left: 0px;
    bottom: 0px;
    border-bottom: 88px solid #f8f8f8;
    border-right: 372px solid transparent;
}

.team-member .inner-box .overlay-box:after {
    position: absolute;
    content: '';
    right: -100%;
    bottom: 0px;
    border-bottom: 88px solid #2fadb9;
    border-left: 240px solid transparent;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.team-member .inner-box .overlay-box .social-icon-two {
    position: absolute;
    right: 30px;
    bottom: 12px;
    z-index: 99;
    opacity: 0;
    transition: all 1s ease;
    -webkit-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
}

.team-member .inner-box:hover .overlay-box:after {
    right: 0px;
}

.team-member .inner-box:hover .social-icon-two {
    opacity: 1;
}


/*Social Icon Two*/

.social-icon-two {
    position: relative;
}

.social-icon-two li {
    position: relative;
    margin-left: 12px;
    display: inline-block;
}

.social-icon-two li a {
    color: #ffffff;
    font-size: 14px;
}


/*Social Icon Three*/

.social-icon-three {
    position: relative;
}

.social-icon-three a {
    color: #aaaaaa;
    margin-left: 10px;
    font-size: 14px;
}

.social-icon-three a:hover {
    color: #2fadb9;
}

.theme_color {
    color: #2fadb9;
}


/*-------------------------------------------------------------------------------------------*/


/* Pricing Table 1    ||---------------------------- */


/*-------------------------------------------------------------------------------------------*/

#pricing_table {
    background: #f8f8f8
}

.pricing_title {
    padding-bottom: 70px
}

.pricing_table {
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 3px;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 10px
}

.package-price {
    font-size: 26px;
    color: #fff;
    border-radius: 55px;
    height: 80px;
    width: 110px;
    padding-top: 21px;
    margin: 0 auto;
    position: relative
}

.package_title p {
    font-size: 22px;
    letter-spacing: 0;
    font-weight: 500;
    color: #888;
    padding: 40px 0 20px
}

span.currency-symbol {
    display: inline-block;
    font-size: 16px;
    position: absolute;
    top: -12px;
    left: 0;
    margin-left: 27px;
}

span.price {
    font-size: 36px;
    display: inline-block
}

span.duration {
    font-size: 13px;
    display: block
}

ul.price_feature {
    padding: 20px 50px 35px 50px
}

.price_feature li {
    padding: 18px 0;
    font-size: 15px;
    border-bottom: 1px solid #eee;
    color: #888
}

.section_title-2.pricing_title {
    padding-bottom: 75px
}

.pricing-2 .package_title {
    padding-top: 20px;
    border-bottom: 1px solid #eee
}

.pricing-2 .package-price {
    background: none
}

.pricing-2 .currency-symbol {
    margin-left: 5px;
    margin-top: 28px
}

.pricing-2 .price {
    font-size: 48px;
    font-weight: 300
}

.pricing-2 .duration {
    color: #888;
}

.pricing-2 .package_title p {
    color: #333;
    font-size: 16px;
    padding: 5px 0 16px;
    font-weight: 600;
}

.pricing-2 ul.price_feature {
    padding: 0 0px 35px 0
}

.pricing-2 .price_feature li {
    padding: 20px 0;
    font-size: 14px;
    color: #333;
    font-weight: 400;
    list-style: none;
}

.pricing-2 .pricing_table.active {
    box-shadow: none
}

.pricing-2 .price,
.pricing-2 .currency-symbol {
    color: #2fadb9;
}

.pricing-2 .pricing_table.active {
    border: 1px solid #2fadb9;
}

a.btn.xplus-btn.pricing {
    color: #333;
    padding: 0 45px;
    background: none;
    border: 1px solid #ddd;
    height: 50px;
    line-height: 50px;
    margin-bottom: 50px;
    transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
    box-shadow: none;
}

a.btn.xplus-btn.pricing:hover {
    color: #fff
}

a.btn.xplus-btn.pricing.active {
    color: #fff
}

a.btn.xplus-btn.pricing.active {
    background: #2fadb9;
    color: #fff;
    border-color: #2fadb9
}

a.btn.xplus-btn.pricing.active:hover {
    background: #2fadb9;
    color: #fff;
    border-color: #2fadb9;
    border: 1px solid #2fadb9;
}

.btn.xplus-btn,
a.btn.xplus-btn.pricing:hover {
    background: #2fadb9;
}


/*Style 2*/

.pricing-style2 .pricing_table,
.pricing-style2 .pricing_table.active {
    box-shadow: none;
    border-radius: 0;
    border: none;
    transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
}

.pricing-style2 .package_title {
    padding-top: 0;
}

.pricing-style2 .pricing-top {
    padding: 20px;
    margin-bottom: 20px;
}

.pricing-style2 .pricing-top p {
    margin-bottom: 0;
    padding: 0;
    color: #fff;
}

.pricing-style .package-price {
    padding-bottom: 20px;
}

.pricing-style2 .pricing_table.shadow,
.pricing-style2 .pricing_table:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 11px;
    transition: all 0.2s linear 0s;
    -webkit-transition: all 0.2s linear 0s;
}


/*-------------------------------------------------------------------------------------------*/


/* Pricing Table 2    ||---------------------------- */


/*-------------------------------------------------------------------------------------------*/

.pricing-wrap {
    background: #fff;
    border: 1px solid #d2d7e2;
    border-top: 5px solid #168be8;
    font-family: 'Open Sans', sans-serif;
}

.price-title {
    background: #f5f5f5;
    padding: 20px 0;
}

.price-title h3 {
    margin: 0;
}

.price-header {
    width: 100%;
    min-height: 159px;
    padding-top: 15px;
}

.price-header h5 {
    font-weight: 300;
    font-size: 15px;
    margin: 0;
    padding-bottom: 12px;
}

.price-header .price-value {
    font-size: 45px;
    font-weight: 600;
    margin: 0;
}

.price-header .price-value sup {
    font-size: 17px;
    top: -19px;
    margin-right: 3px;
}

.price-header p {
    font-size: 15px;
    font-weight: 600;
    margin: 0;
    color: #545454;
}

.price-header .past-price {
    font-size: 15px;
    font-weight: 600;
    display: block;
    color: rgba(109, 109, 109, 0.75);
}

.skinonebg .price-header p,
.skinonebg .price-header h5 {
    color: rgba(255, 255, 255, 0.78);
}

.skinonebg .price-header .past-price {
    color: rgba(255, 255, 255, 0.47);
}

.features {
    width: 100%;
    text-align: center;
}

.features .get-start {
    width: 80%;
}

.features ul {
    padding: 0;
    margin: 0;
}

.features li {
    list-style: none;
    font-size: 12px;
    font-weight: 400;
    color: #131924;
    padding: 12px 15px;
    border-top: 1px solid #d2d7e2;
}

.plancolor {
    background: #f8f8f8;
}

.pricing-btn {
    width: 100%;
    padding: 25px 0;
    border-top: 1px solid #d2d7e2;
}

.pricing-btn .get-start {
    background: #168be8;
    color: #fff;
    margin-top: 0;
}

.pricing-btn .get-start.white-btn {
    background: #fff;
    color: #168be8;
}

.pricing-btn li {
    list-style: none;
}

.pricing-btn li a {
    width: 60%;
    float: left;
    margin-left: 20%;
    background: #ccc;
    border-radius: 30px;
    text-decoration: none;
    font-size: 12px;
    font-weight: 300;
    padding-top: 9px;
    color: #fff;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;
}

.pricing-btn li a:hover {
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;
}

.skinonebg {
    background: #168be8;
}

.price-blue--color {
    color: #165cb2;
}

.pricing-btn--bg li a {
    background: #2682bc;
}

.pricing-btn--bg li a:hover {
    background: #3497d6;
}

.featuredcolor {
    color: #fff;
}

.skinonefeatures li {
    color: #fff;
    border-top: 1px solid #3a9cda;
}

.skinoneplancolor {
    background: #165cb2;
}

.skinonefeaturedreq {
    border-top: 1px solid #3a9cda;
}


/*.skinonefeaturedreq {background:#308fcb;}*/

.skinonecheck {
    color: #ceff68;
    margin-right: 8px;
}


/*Pricing table*/

.pricing--item {
    margin-bottom: 60px
}

.pricing--content {
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, .05);
    text-align: center;
    z-index: 0
}

.pricing--content.ribbon:before {
    content: "Recommended";
    position: absolute;
    top: 22px;
    left: -45px;
    padding: 15px 40px;
    color: #119ee6;
    background-color: #fff;
    font-family: 'Droid Serif', serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.pricing--header {
    padding: 17px 15px 30px;
    color: #fff;
    -webkit-transition: background-color .25s ease-in-out;
    transition: background-color .25s ease-in-out
}

.pricing--content.active .pricing--header,
.pricing--content.active .pricing--icon .fa,
.pricing--content.active .pricing--icon .fa:after,
.pricing--content.active .pricing--icon:before,
.pricing--content:hover .pricing--header,
.pricing--content:hover .pricing--icon .fa,
.pricing--content:hover .pricing--icon .fa:after,
.pricing--content:hover .pricing--icon:before {
    background-color: #165cb2
}

.pricing--header .h5 {
    margin-top: 18px;
    font-style: italic;
    font-weight: 400
}

.pricing--header .h5 strong {
    display: block;
    font-size: 30px;
    font-style: normal;
    margin-bottom: 25px;
}

.pricing--header .h5 strong small {
    color: #fff;
    font-size: 18px
}

.pricing--icon {
    position: relative;
    margin-top: -33px;
    color: #fff;
    font-size: 30px;
    line-height: 30px;
    z-index: -1
}

.pricing--icon:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #000;
    -webkit-transition: background-color .25s ease-in-out;
    transition: background-color .25s ease-in-out;
    z-index: -1
}

.pricing--icon:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 85px;
    margin: 5px auto;
    border: 1px solid #fff;
    border-radius: 50%;
    z-index: 0
}

.pricing--icon .fa,
.pricing--icon .fa:after {
    background-color: #000;
    -webkit-transition: background-color .25s ease-in-out;
    transition: background-color .25s ease-in-out
}

.pricing--icon .fa {
    min-width: 95px;
    padding: 32.5px 0;
    border-radius: 50%
}

.pricing--icon .fa:before {
    position: relative;
    z-index: 2
}

.pricing--icon .fa:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 85px;
    height: 56%;
    margin: 0 auto;
    z-index: 1
}

.pricing--features {
    font-size: 16px;
    line-height: 20px
}

.pricing--features ul {
    padding: 0;
    margin: 28px 0 0;
    list-style: none
}

.pricing--features li {
    margin-bottom: 16px;
    padding: 0 15px
}

.pricing--features li:last-child {
    margin-bottom: 0
}

.coming-soon--content .social .nav>li>a:hover,
.f0f--content p a,
.footer--widget>ul>li:hover:before,
.pricing--features .fa-check,
.product--img figcaption .nav>li>a:hover,
.product--rating .br-theme-bootstrap-stars .br-widget a.br-active:after,
.product--rating .br-theme-bootstrap-stars .br-widget a.br-selected:after,
.product--single-summery-meta .table td a.active {
    color: #119ee6
}

.pricing--features li .h3 {
    margin: 1px 0 4px;
    line-height: 37px
}

.pricing--footer {
    padding: 30px 0
}

.pricing--content.active .pricing--footer .btn-primary,
.pricing--content:hover .pricing--footer .btn-primary {
    background-color: #000;
    border-color: #000
}

.pricing--items.shadow--off .pricing--content {
    box-shadow: none
}

.pricing--items.shadow--off .pricing--footer {
    padding-bottom: 0
}

.pricing--items.border--on .pricing--content {
    border-radius: 4px 4px 0 0
}

.pricing--items.border--on .pricing--features {
    position: relative;
    z-index: 0
}

.pricing--items.border--on .pricing--features:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-style: solid;
    border-width: 0 0 0 1px;
    border-color: #eee
}

.pricing--items.border--on .pricing--item:last-child .pricing--features:before {
    border-right-width: 1px
}

.pricing--items.border--on .pricing--icon+.pricing--features:before {
    top: -77px
}

.pricing--items.border--on .pricing--features ul {
    margin-top: 0
}

.pricing--items.border--on .pricing--features li {
    margin-bottom: 0;
    padding: 10px 30px;
    border-style: solid;
    border-width: 1px 0 0;
    border-color: #ccc
}

.pricing--items.border--on .pricing--features li:last-child {
    border-bottom-width: 1px
}

#pricingIncluded .pricing--item {
    float: none;
    display: inline-block;
    margin-bottom: 60px;
    vertical-align: bottom
}

#pricingIncluded .pricing--item:first-child {
    padding-bottom: 74px
}

.pricing--table {
    margin-bottom: 0;
    text-align: center
}

.pricing--table th {
    position: relative;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
    color: #222;
    border: 0 !important;
    font-family: 'Droid Serif', serif;
    font-size: 18px;
    text-align: center;
    z-index: 0
}

.pricing--table th:before {
    top: 0;
    height: 100%;
    background-color: #eee
}

.pricing--table td:before,
.pricing--table th:before {
    content: " ";
    position: absolute;
    left: 0;
    width: 100%;
    border-style: solid;
    border-width: 1px 0;
    border-color: #ccc;
    z-index: -1
}

.pricing--table td:first-child:before,
.pricing--table th:first-child:before {
    border-left-width: 1px;
    border-radius: 4px 0 0 4px
}

.pricing--table td:last-child:before,
.pricing--table th:last-child:before {
    border-right-width: 1px;
    border-radius: 0 4px 4px 0
}

.pricing--table th:after {
    top: 17.5px;
    bottom: 17.5px
}

.pricing--table td:after,
.pricing--table th:after {
    content: " ";
    position: absolute;
    right: 0;
    width: 1px;
    background-color: #ccc
}

.pricing--table td:last-child:after,
.pricing--table th:last-child:after {
    display: none
}

.pricing--table td {
    position: relative;
    padding-top: 21px !important;
    padding-bottom: 11px !important;
    border: 0 !important;
    font-size: 16px;
    vertical-align: middle !important;
    z-index: 0
}

.pricing--table td:before {
    top: 10px;
    bottom: 0;
    height: auto;
    background-color: transparent
}

.pricing--table td:after {
    top: 26px;
    bottom: 16px
}

.pricing--table .btn-default {
    color: #222;
    background-color: #eee;
    border-color: #eee;
    font-weight: 700
}

.pricing--table .btn-default:focus,
.pricing--table .btn-default:hover {
    color: #fff;
    background-color: #119ee6;
    border-color: #119ee6
}

.reset-gutter>[class*=col-],
.reset-padding {
    padding-right: 0;
    padding-left: 0;
}

#pricingIncluded .pricing--items,
.gallery--content-sidebar .tags {
    font-size: 0;
}

.bg-color--black {
    background: #168be8;
}

#pricingIncluded .plan-list {
    font-size: 15px;
    background: #165cb2;
    padding: 26px 20px 21px;
    display: inline-block;
    color: #fff;
    position: relative;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 30px;
}

#pricingIncluded .plan-list:after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(153, 153, 153, 0);
    border-left-color: #165cb2;
    border-width: 18px;
    margin-top: -18px;
}

@media screen and (max-width: 991px) {
    #pricingIncluded .pricing--item {
        display: block
    }
    #pricingIncluded .pricing--item:first-child {
        display: none
    }
    #pricingIncluded .pricing--features {
        text-align: right
    }
    #pricingIncluded .pricing--features li strong {
        float: left;
        color: #222;
        font-family: 'Droid Serif', sans-serif;
        font-weight: 400
    }
    #pricingIncluded .pricing--items.border--on .pricing--features:before {
        border-right-width: 1px
    }
    .pricing--table thead {
        display: none
    }
    .pricing--table tr {
        display: block;
        margin-bottom: 30px;
        border-top: 1px solid #ccc;
        border-radius: 4px
    }
    .pricing--table tr:last-child {
        margin-bottom: 0
    }
    .pricing--table td {
        display: block;
        padding-top: 11px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        text-align: right
    }
    .pricing--table td:before {
        top: 0;
        border-width: 0 1px 1px;
        border-radius: 0
    }
    .pricing--table td:first-child:before {
        border-radius: 4px 4px 0 0
    }
    .pricing--table td:last-child:before {
        border-radius: 0 0 4px 4px
    }
}


/*=======Blog Section=========*/

.latest__block-post {
    padding: 20px;
}

.latest__block .more_btn__block {
    margin-top: 10px;
}

.latest__block-title {
    font-size: 20px;
    font-weight: 800;
}

.team__block-section .team-member {
    margin-bottom: 0;
}

.more_btn__block {
    color: #feb20e;
    font-weight: bold;
    display: inline-block;
    margin-top: 30px;
}

.project-detail-block {
    padding: 30px;
}

.panel-body {
    padding: 15px;
}


/******************************************************************************
 Pages
 *******************************************************************************/

.page-header {
    border-bottom: 1px solid #e1e1e1;
    border-top: 1px solid #e1e1e1;
    padding: 30px 0;
    background-color: #f2f2f2;
    margin: 0px;
}

.page-header h1 {
    margin: 0;
    font-size: 42px;
    text-transform: capitalize;
}

.breadcrumb.white a {
    color: #ffffff;
}

.breadcrumb {
    border-radius: 0;
    background: none !important;
    color: #7f7f7f;
    font-size: 11px;
    line-height: 18px;
    margin: 0;
    padding: 11px 0;
}

.breadcrumb>li>a {
    color: #7f7f7f;
    text-decoration: none;
    transition: opacity 0.2s linear 0s;
}

.breadcrumb>li>a:hover {
    opacity: 0.8;
    transition: opacity 0.2s linear 0s;
}

.project-item {
    border: 1px solid #f2f2f2;
}

.project-item .text-box {
    padding: 0 20px 20px;
}
