$theme-color: #00b5c7; // Change here your theme color

/*
============== background Color====================
*/

.header-2 .primary-header,
.dark-bg,
.hover-bg:hover,
.ui-timeline .tl-item .tl-body .tl-entry .btn-primary,
.btn-text,
.faq .collape-plus,
.container-filter li a.active,
.container-filter li a:hover,
.container-filter li a,
.link-zoom a,
.link-zoom a.project_links,
.project-info,
#related-project .owl-prev:hover,
#related-project .owl-next:hover,
.sidebar-widget .widget-tag li a:hover,
.content-carousel .owl-controls .owl-nav .owl-prev,
.content-carousel .owl-controls .owl-nav .owl-next,
.image-slider .owl-controls .owl-nav .owl-prev,
.image-slider .owl-controls .owl-nav .owl-next,
.fullwidth-slider .owl-controls .owl-nav .owl-prev,
.fullwidth-slider .owl-controls .owl-nav .owl-next,
.nf-carousel-theme .owl-controls .owl-nav .owl-prev,
.nf-carousel-theme .owl-controls .owl-nav .owl-next,
.blog-date,
.pagination-nav ul li.active a,
.pagination-nav ul li:hover a,
.page-signin .body-inner:before,
.b-line,
.cd-slider-nav a:hover,
.cd-slider-nav li.selected a,
.cd-slider-nav li.selected a:hover,
.cd-slider-nav li .slide-number,
.feature-icon-wrap:after,
.single_carousel>p,
.testimonial_carosule-wrap .owl-nav div:hover,
.footer .copyright,
.newsletter .newsletter-btn,
.counter-section__img:before {
    background: $theme-color;
}

.available_wrap__block,
.navigation .sub-nav {
    background-color: $theme-color;
}

.header-2 .navigation>ul>li:hover,
.counter-section__box .counter:after,
.single_carousel>p::after {
    background: darken($theme-color, 10%);
}


/*
============== Text Color====================
*/

a:focus,
a:hover,
h2,
h3,
h4,
h5,
h6,
.gallery-heading h4 a:hover,
.post-meta span>a>i,
.sidebar-widget ul li a:hover,
.lead-point li,
.blog-post .fa-tag,
.pagination-nav ul li a,
.big-font i,
.btn-text:hover,
.color-primary,
a.color-primary,
.contact .icon-circle,
.single-sidebar-widget .special-links li:hover a,
.single-sidebar-widget .special-links li a.active,
.custom-check:before,
.header-2 i,
.header-2 .top-bar-section.top-bar-bg-color a:hover,
.header-2 .top-bar-section.top-bar-bg-color a.active,
.call-us ul li a:hover,
.btn-white-line,
.btn-color-line,
.cd-hero-slider h2 span,
.cd-slider-nav li.selected .slide-number,
.cd-slider-nav li:hover .slide-number,
.testimonial_carosule-wrap .owl-nav div,
.footer .link a:hover {
    color: $theme-color;
}


/*
============== Border Color====================
*/

.ui-timeline .tl-item .tl-body .tl-entry .btn-primary,
.btn-text,
#related-project .owl-prev:hover,
#related-project .owl-next:hover,
.widget-tag li a,
.pagination-nav ul li a,
.client-avtar,
.contact .icon-circle,
.contact-form input[type="text"]:focus,
.contact-form textarea:focus,
.single-sidebar-widget .special-links li a:before,
.widget-search input[type="text"]:focus,
.custom-check,
.feature-icon-wrap:after,
.testimonial_carosule-wrap .owl-nav div,
.v-line {
    border-color: $theme-color;
}

.header-2 .navigation>ul>li:hover,
.header-2 .navigation>ul>li {
    border-color: darken($theme-color, 10%);
}

.logo-img {
    width: 203px;
    height: auto;
}

.alert {
    &.alert-success, &.alert-danger {
        border-radius: 0;
    }
}

.form {
    .form-field {
        width: 100%;
        margin-bottom: 2rem;

        &.is-invalid {
            margin-bottom: 0.2rem;
            .form-full {
                border-color: #dc3545;
            }
        }

        .invalid-feedback {
            display: block;
            width: 100%;
            text-align: left;
            color: #dc3545;
            font-size: 90%;
            margin-top: 0.25rem;
        }
    }
}