.no-js .cd-hero-slider li {
    display: none;
}

.no-js .cd-hero-slider li.selected {
    display: block;
}

.no-js .cd-slider-nav {
    display: none;
}

.cd-slider-nav li .slide-number {
    background-color: #daa106;
}

.cd-slider-nav li.selected .slide-number,
.cd-slider-nav li:hover .slide-number {
    color: #2fadb9;
}

.cd-slider-nav li .slide-number {
    background-color: #2fadb9;
}

.cd-slider-nav a:hover,
.cd-slider-nav li.selected a {
    background-color: #2fadb9 !important;
}

.cd-hero-slider .light-color h2,
.cd-hero-slider .light-color p {
    color: #fff;
}

.cd-hero-slider h2 span {
    color: #2fadb9;
}

.color-white {
    color: #fff !important;
}


/* -------------------------------- 

Main Components 

-------------------------------- */

.cd-header {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #21272c;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media only screen and (min-width: 768px) {
    .cd-header {
        height: 70px;
        background-color: transparent;
    }
}

#cd-logo {
    float: left;
    margin: 13px 0 0 5%;
}

#cd-logo img {
    display: block;
}

@media only screen and (min-width: 768px) {
    #cd-logo {
        margin: 23px 0 0 5%;
    }
}

.cd-primary-nav {
    /* mobile first - navigation hidden by default, triggered by tap/click on navigation icon */
    float: right;
    margin-right: 5%;
    width: 44px;
    height: 100%;
    background: url("../assets/cd-icon-menu.html") no-repeat center center;
}

.cd-primary-nav ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
}

.cd-primary-nav ul.is-visible {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
}

.cd-primary-nav a {
    display: block;
    height: 50px;
    line-height: 50px;
    padding-left: 5%;
    background: #21272c;
    border-top: 1px solid #333c44;
    color: #ffffff;
}

@media only screen and (min-width: 768px) {
    .cd-primary-nav {
        /* reset navigation values */
        width: auto;
        height: auto;
        background: none;
    }
    .cd-primary-nav ul {
        position: static;
        width: auto;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        line-height: 70px;
    }
    .cd-primary-nav ul.is-visible {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
    .cd-primary-nav li {
        display: inline-block;
        margin-left: 1em;
    }
    .cd-primary-nav a {
        display: inline-block;
        height: auto;
        font-weight: 600;
        line-height: normal;
        background: transparent;
        padding: .6em 1em;
        border-top: none;
    }
}


/* -------------------------------- 

Slider

-------------------------------- */

.cd-hero {
    position: relative;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cd-hero-slider {
    position: relative;
    height: 360px;
    overflow: hidden;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.cd-hero-slider li {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
}

.cd-hero-slider li:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: #000;
    opacity: 0.5;
}

.cd-hero-slider li.selected {
    /* this is the visible slide */
    position: relative;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}

.cd-hero-slider li.move-left {
    /* slide hidden on the left */
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
}

.cd-hero-slider li.is-moving,
.cd-hero-slider li.selected {
    /* the is-moving class is assigned to the slide which is moving outside the viewport */
    -webkit-transition: -webkit-transform 0.5s;
    -moz-transition: -moz-transform 0.5s;
    transition: transform 0.5s;
}

@media only screen and (min-width: 768px) {
    .cd-hero-slider {
        height: 500px;
    }
}

@media only screen and (min-width: 1170px) {
    .cd-hero-slider {
        height: 680px;
    }
}


/* -------------------------------- 

Single slide style

-------------------------------- */

.cd-hero-slider li {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.cd-hero-slider li:first-of-type {
    background-color: #ccc;
    background-image: url("/img/header.jpg");
}

/* .cd-hero-slider li:nth-of-type(2) {
    background-color: #ccc;
    background-image: url("/img/main-banner/1/2.jpg");
} */

.cd-hero-slider .cd-full-width,
.cd-hero-slider .cd-half-width {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    /* this padding is used to align the text */
    padding-top: 100px;
    /* Force Hardware Acceleration in WebKit */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
}

.cd-hero-slider .cd-img-container {
    /* hide image on mobile device */
    display: none;
}

@media only screen and (max-width: 767px) {
    .cd-hero-slider .cd-full-width,
    .cd-hero-slider .cd-half-width {
        padding-top: 26px;
    }
}

.cd-hero-slider .cd-img-container img {
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.cd-hero-slider .cd-bg-video-wrapper {
    /* hide video on mobile device */
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.cd-hero-slider .cd-bg-video-wrapper video {
    /* you won't see this element in the html, but it will be injected using js */
    display: block;
    min-height: 100%;
    min-width: 100%;
    max-width: none;
    height: auto;
    width: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.cd-hero-slider h2,
.cd-hero-slider p {
    margin: 0 auto 14px;
    color: #222;
    width: 100%;
}

.cd-hero-slider h2 {
    font-size: 2.4rem;
}

.cd-hero-slider p {
    font-size: 1.4rem;
    line-height: 1.4;
}

.cd-hero-slider .cd-btn {
    display: inline-block;
    padding: 1.2em 1.4em;
    margin-top: .8em;
    background-color: rgba(212, 68, 87, 0.9);
    font-size: 1.3rem;
    font-weight: 700;
    letter-spacing: 1px;
    color: #ffffff;
    text-transform: uppercase;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    -webkit-transition: background-color 0.2s;
    -moz-transition: background-color 0.2s;
    transition: background-color 0.2s;
}

.cd-hero-slider .cd-btn.secondary {
    background-color: rgba(22, 26, 30, 0.8);
}

.cd-hero-slider .cd-btn:nth-of-type(2) {
    margin-left: 1em;
}

.cd-hero-slider .cd-btn:hover {
    background-color: #d44457;
}

.cd-hero-slider .cd-btn.secondary:hover {
    background-color: #161a1e;
}

@media only screen and (min-width: 768px) {
    .cd-hero-slider .cd-full-width,
    .cd-hero-slider .cd-half-width {
        padding-top: 150px;
    }
    .cd-hero-slider .cd-bg-video-wrapper {
        display: block;
    }
    .cd-hero-slider .cd-half-width {
        width: 45%;
    }
    .cd-hero-slider .cd-half-width:first-of-type {
        left: 5%;
    }
    .cd-hero-slider .cd-half-width:nth-of-type(2) {
        right: 5%;
        left: auto;
    }
    .cd-hero-slider .cd-img-container {
        display: block;
    }
    .cd-hero-slider h2 {
        font-size: 2.4em;
    }
    .cd-hero-slider .cd-btn {
        font-size: 1.4rem;
    }
}

@media only screen and (min-width: 1170px) {
    .cd-hero-slider .cd-full-width,
    .cd-hero-slider .cd-half-width {
        padding-top: 220px;
    }
    .header-transparent .cd-hero-slider .cd-full-width,
    .header-transparent .cd-hero-slider .cd-half-width {
        padding-top: 300px;
    }
    .cd-hero-slider h2,
    .cd-hero-slider p {
        margin-bottom: 15px;
    }
    .cd-hero-slider h2 {
        font-size: 48px;
    }
    .cd-hero-slider p {
        font-size: 18px;
        color: #222;
        font-family: "Montserrat", sans-serif;
        margin-bottom: 30px;
    }
}


/* -------------------------------- 

Single slide animation

-------------------------------- */

@media only screen and (min-width: 768px) {
    .cd-hero-slider .cd-half-width {
        opacity: 0;
        -webkit-transform: translateX(40px);
        -moz-transform: translateX(40px);
        -ms-transform: translateX(40px);
        -o-transform: translateX(40px);
        transform: translateX(40px);
    }
    .cd-hero-slider .move-left .cd-half-width {
        -webkit-transform: translateX(-40px);
        -moz-transform: translateX(-40px);
        -ms-transform: translateX(-40px);
        -o-transform: translateX(-40px);
        transform: translateX(-40px);
    }
    .cd-hero-slider .selected .cd-half-width {
        /* this is the visible slide */
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
    .cd-hero-slider .is-moving .cd-half-width {
        /* this is the slide moving outside the viewport 
    wait for the end of the transition on the <li> parent before set opacity to 0 and translate to 40px/-40px */
        -webkit-transition: opacity 0s 0.5s, -webkit-transform 0s 0.5s;
        -moz-transition: opacity 0s 0.5s, -moz-transform 0s 0.5s;
        transition: opacity 0s 0.5s, transform 0s 0.5s;
    }
    .cd-hero-slider li.selected.from-left .cd-half-width:nth-of-type(2),
    .cd-hero-slider li.selected.from-right .cd-half-width:first-of-type {
        /* this is the selected slide - different animation if it's entering from left or right */
        -webkit-transition: opacity 0.4s 0.2s, -webkit-transform 0.5s 0.2s;
        -moz-transition: opacity 0.4s 0.2s, -moz-transform 0.5s 0.2s;
        transition: opacity 0.4s 0.2s, transform 0.5s 0.2s;
    }
    .cd-hero-slider li.selected.from-left .cd-half-width:first-of-type,
    .cd-hero-slider li.selected.from-right .cd-half-width:nth-of-type(2) {
        /* this is the selected slide - different animation if it's entering from left or right */
        -webkit-transition: opacity 0.4s 0.4s, -webkit-transform 0.5s 0.4s;
        -moz-transition: opacity 0.4s 0.4s, -moz-transform 0.5s 0.4s;
        transition: opacity 0.4s 0.4s, transform 0.5s 0.4s;
    }
    .cd-hero-slider .cd-full-width h2,
    .cd-hero-slider .cd-full-width p,
    .cd-hero-slider .cd-full-width .cd-btn {
        opacity: 0;
        -webkit-transform: translateX(100px);
        -moz-transform: translateX(100px);
        -ms-transform: translateX(100px);
        -o-transform: translateX(100px);
        transform: translateX(100px);
    }
    .cd-hero-slider .move-left .cd-full-width h2,
    .cd-hero-slider .move-left .cd-full-width p,
    .cd-hero-slider .move-left .cd-full-width .cd-btn {
        opacity: 0;
        -webkit-transform: translateX(-100px);
        -moz-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        -o-transform: translateX(-100px);
        transform: translateX(-100px);
    }
    .cd-hero-slider .selected .cd-full-width h2,
    .cd-hero-slider .selected .cd-full-width p,
    .cd-hero-slider .selected .cd-full-width .cd-btn {
        /* this is the visible slide */
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
    .cd-hero-slider li.is-moving .cd-full-width h2,
    .cd-hero-slider li.is-moving .cd-full-width p,
    .cd-hero-slider li.is-moving .cd-full-width .cd-btn {
        /* this is the slide moving outside the viewport 
    wait for the end of the transition on the li parent before set opacity to 0 and translate to 100px/-100px */
        -webkit-transition: opacity 0s 0.5s, -webkit-transform 0s 0.5s;
        -moz-transition: opacity 0s 0.5s, -moz-transform 0s 0.5s;
        transition: opacity 0s 0.5s, transform 0s 0.5s;
    }
    .cd-hero-slider li.selected h2 {
        -webkit-transition: opacity 0.4s 0.2s, -webkit-transform 0.5s 0.2s;
        -moz-transition: opacity 0.4s 0.2s, -moz-transform 0.5s 0.2s;
        transition: opacity 0.4s 0.2s, transform 0.5s 0.2s;
    }
    .cd-hero-slider li.selected p {
        -webkit-transition: opacity 0.4s 0.3s, -webkit-transform 0.5s 0.3s;
        -moz-transition: opacity 0.4s 0.3s, -moz-transform 0.5s 0.3s;
        transition: opacity 0.4s 0.3s, transform 0.5s 0.3s;
    }
    .cd-hero-slider li.selected .cd-btn {
        -webkit-transition: opacity 0.4s 0.4s, -webkit-transform 0.5s 0.4s, background-color 0.2s 0s;
        -moz-transition: opacity 0.4s 0.4s, -moz-transform 0.5s 0.4s, background-color 0.2s 0s;
        transition: opacity 0.4s 0.4s, transform 0.5s 0.4s, background-color 0.2s 0s;
    }
}


/* -------------------------------- 

Slider navigation

-------------------------------- */

.cd-slider-nav {
    position: relative;
    width: 100%;
    bottom: 0;
    z-index: 2;
    text-align: center;
    height: 55px;
    background-color: #fff;
    border-bottom: 1px solid #cdcfd3;
    -webkit-box-shadow: 0px 1px 0px 0px rgba(230, 232, 236, 1);
    -moz-box-shadow: 0px 1px 0px 0px rgba(230, 232, 236, 1);
    box-shadow: 0px 1px 0px 0px rgba(230, 232, 236, 1);
}

.cd-slider-nav ul {
    padding: 0;
    margin: 0;
}

.cd-slider-nav nav,
.cd-slider-nav ul,
.cd-slider-nav li,
.cd-slider-nav a {
    height: 100%;
}

.cd-slider-nav nav {
    display: inline-block;
    position: relative;
    padding: 0;
}

.cd-slider-nav .cd-marker {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 100%;
    color: #fff;
    -webkit-transition: -webkit-transform 0.2s, box-shadow 0.2s;
    -moz-transition: -moz-transform 0.2s, box-shadow 0.2s;
    transition: transform 0.2s, box-shadow 0.2s;
}

.cd-slider-nav .cd-marker.item-2 {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
}

.cd-slider-nav .cd-marker.item-3 {
    -webkit-transform: translateX(200%);
    -moz-transform: translateX(200%);
    -ms-transform: translateX(200%);
    -o-transform: translateX(200%);
    transform: translateX(200%);
}

.cd-slider-nav .cd-marker.item-4 {
    -webkit-transform: translateX(300%);
    -moz-transform: translateX(300%);
    -ms-transform: translateX(300%);
    -o-transform: translateX(300%);
    transform: translateX(300%);
}

.cd-slider-nav .cd-marker.item-5 {
    -webkit-transform: translateX(400%);
    -moz-transform: translateX(400%);
    -ms-transform: translateX(400%);
    -o-transform: translateX(400%);
    transform: translateX(400%);
}

.cd-slider-nav ul::after {
    clear: both;
    content: "";
    display: table;
}

.cd-slider-nav li {
    display: inline-block;
    width: 60px;
    float: left;
}

.cd-slider-nav li.selected a {
    color: #fff;
}

.cd-slider-nav li.selected a:hover {
    background-color: transparent;
}

.cd-slider-nav a {
    display: block;
    position: relative;
    padding-top: 35px;
    font-size: 1rem;
    font-weight: 700;
    color: #a8b4be;
    -webkit-transition: background-color 0.2s;
    -moz-transition: background-color 0.2s;
    transition: background-color 0.2s;
}

.cd-slider-nav a::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 129px;
    top: 0;
    left: 0;
    right: auto;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    background: #e3dfdc;
}

.cd-slider-nav li:last-child a::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 129px;
    top: 0;
    right: 0;
    left: auto;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    background: #e3dfdc;
}

.cd-slider-nav li .slide-number {
    float: left;
    width: 46px;
    height: 46px;
    font-size: 22px;
    line-height: 45px;
    border-radius: 100%;
    color: #fff;
    margin-right: 10px;
    text-align: center;
    cursor: pointer;
}

.cd-slider-nav li.selected .slide-number,
.cd-slider-nav li:hover .slide-number {
    background: #fff;
}

.cd-slider-nav a:hover {
    color: #fff;
}

.cd-slider-nav li:first-of-type a::before {
    background-position: 0 0;
}

.cd-slider-nav li.selected:first-of-type a::before {
    background-position: 0 -24px;
}

.cd-slider-nav li:nth-of-type(2) a::before {
    background-position: -24px 0;
}

.cd-slider-nav li.selected:nth-of-type(2) a::before {
    background-position: -24px -24px;
}

.cd-slider-nav li:nth-of-type(3) a::before {
    background-position: -48px 0;
}

.cd-slider-nav li.selected:nth-of-type(3) a::before {
    background-position: -48px -24px;
}

.cd-slider-nav li:nth-of-type(4) a::before {
    background-position: -72px 0;
}

.cd-slider-nav li.selected:nth-of-type(4) a::before {
    background-position: -72px -24px;
}

.cd-slider-nav li:nth-of-type(5) a::before {
    background-position: -96px 0;
}

.cd-slider-nav li.selected:nth-of-type(5) a::before {
    background-position: -96px -24px;
}

@media only screen and (min-width: 768px) {
    .cd-slider-nav {
        height: 129px;
    }
    .cd-slider-nav .cd-marker,
    .cd-slider-nav li {
        width: 25%;
    }
    .cd-slider-nav a {
        padding: 40px 20px;
        font-size: 19px;
        color: #121212;
        text-transform: none;
        text-align: left;
    }
    .cd-slider-nav a span {
        display: block;
        font-size: 14px;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        overflow: hidden;
    }
    .cd-slider-nav a::before {
        top: 0;
    }
    .cd-slider-nav li:hover a,
    .cd-slider-nav li.selected a {
        border-color: transparent;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .cd-slider-nav li .slide-number {
        display: none;
    }
    .cd-slider-nav a {
        padding: 27px 19px 40px;
        font-size: 14px;
    }
}


/* -------------------------------- 

Main content

-------------------------------- */

.cd-main-content {
    width: 90%;
    max-width: 768px;
    margin: 0 auto;
    padding: 2em 0;
}

.cd-main-content p {
    font-size: 1.4rem;
    line-height: 1.8;
    color: #999999;
    margin: 2em 0;
}

@media only screen and (min-width: 1170px) {
    .cd-main-content {
        padding: 3em 0;
    }
    .cd-main-content p {
        font-size: 1.6rem;
    }
}

.cd-hero-slider .cd-full-width .btn {
    margin: 0 3px;
}

@media only screen and (max-width: 767px) {
    .cd-slider-nav li {
        float: none;
        width: 74px;
        float: none;
        overflow: hidden;
    }
    .cd-slider-nav a {
        padding-top: 4px;
        font-size: 0;
    }
    .cd-slider-nav li .slide-number {
        float: none;
        margin: 0 auto 0;
    }
}