@media (max-width: 991px) {
    .bg-video {
        width: auto !important;
        height: 100% !important;
    }
    .intro-video .mejs-inner,
    .intro-video .mejs-mediaelement {
        height: 100% !important;
    }
}

.mejs-offscreen {
    /* Accessibility: hide screen reader texts (and prefer "top" for RTL languages). */
    position: absolute !important;
    top: -10000px;
    overflow: hidden;
    width: 1px;
    height: 1px;
}

.mejs-container {
    position: relative;
    background: #000;
    font-family: Helvetica, Arial;
    text-align: left;
    vertical-align: top;
    text-indent: 0;
}

.me-plugin {
    position: absolute;
}

.mejs-embed,
.mejs-embed body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: #000;
    overflow: hidden;
}

.mejs-fullscreen {
    /* set it to not show scroll bars so 100% will work */
    overflow: hidden !important;
}

.mejs-container-fullscreen {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 1000;
}

.mejs-container-fullscreen .mejs-mediaelement,
.mejs-container-fullscreen video {
    width: 100%;
    height: 100%;
}

.mejs-clear {
    clear: both;
}


/* Start: LAYERS */

.mejs-background {
    position: absolute;
    top: 0;
    left: 0;
}

.mejs-mediaelement {
    position: relative;
    /*top: 0;
	left: 0;*/
    width: 100%;
}

.mejs-poster {
    position: absolute;
    top: 0;
    left: 0;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

:root .mejs-poster img {
    display: none;
}

.mejs-poster img {
    border: 0;
    padding: 0;
    border: 0;
}

.mejs-overlay {
    position: absolute;
    top: 0;
    left: 0;
}

.mejs-overlay-play {
    cursor: pointer;
}

.mejs-overlay-button {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    margin: -50px 0 0 -50px;
    background: url('/img/icons/bigplay.png') no-repeat;
    background-position: 0 -100px;
}

.no-svg .mejs-overlay-button {
    background-image: url('/img/icons/bigplay.png');
    background-position: 0 -100px;
}

.mejs-overlay:hover .mejs-overlay-button {
    background-position: 0 -100px;
}

.mejs-overlay-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    margin: -40px 0 0 -40px;
    /*background: url('/img/icons/background.png');*/
}

.mejs-overlay-loading span {
    display: block;
    width: 80px;
    height: 80px;
    background: transparent url('/img/icons/loading.gif') 50% 50% no-repeat;
}


/* End: LAYERS */


/* Start: CONTROL BAR */

.mejs-container .mejs-controls {
    position: absolute;
    list-style-type: none;
    margin: 0;
    padding: 0;
    bottom: 0;
    left: 0;
    background: #f00;
    background: #252525;
    height: 30px;
    width: 100%;
}

.mejs-container .mejs-controls div {
    list-style-type: none;
    background-image: none;
    display: block;
    float: left;
    margin: 0;
    padding: 0;
    width: 26px;
    height: 26px;
    font-size: 11px;
    line-height: 11px;
    font-family: Helvetica, Arial;
    border: 0;
}

.mejs-controls .mejs-button button {
    cursor: pointer;
    display: block;
    font-size: 0;
    line-height: 0;
    text-decoration: none;
    margin: 7px 5px;
    padding: 0;
    position: absolute;
    height: 16px;
    width: 16px;
    border: 0;
    background: transparent url('/img/icons/controls.png') no-repeat;
}

.no-svg .mejs-controls .mejs-button button {
    background-image: url('/img/icons/controls.png');
}


/* :focus for accessibility */

.mejs-controls .mejs-button button:focus {
    outline: dotted 1px #999;
}


/* End: CONTROL BAR */


/* Start: Time (Current / Duration) */

.mejs-container .mejs-controls .mejs-time {
    color: #fff;
    display: block;
    height: 17px;
    width: auto;
    padding: 10px 3px 0 3px;
    overflow: hidden;
    text-align: center;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.mejs-container .mejs-controls .mejs-time a {
    color: #fff;
    font-size: 11px;
    line-height: 12px;
    display: block;
    float: left;
    margin: 1px 2px 0 0;
    width: auto;
}


/* End: Time (Current / Duration) */


/* Start: Play/Pause/Stop */

.mejs-controls .mejs-play button {
    background-position: 3px 0;
}

.mejs-controls .mejs-pause button {
    background-position: 3px -16px;
}

.mejs-controls .mejs-stop button {
    background-position: -112px 0;
}


/* Start: Play/Pause/Stop */


/* Start: Progress Bar */

.mejs-controls div.mejs-time-rail {
    direction: ltr;
    width: 200px;
    padding-top: 5px;
}

.mejs-controls .mejs-time-rail span,
.mejs-controls .mejs-time-rail a {
    display: block;
    position: absolute;
    width: 180px;
    height: 10px;
    cursor: pointer;
}

.mejs-controls .mejs-time-rail .mejs-time-total {
    margin: 5px;
    background: rgba(255, 255, 255, 0.2);
}

.mejs-controls .mejs-time-rail .mejs-time-buffering {
    width: 100%;
    background-image: -o-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -ms-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    -webkit-background-size: 15px 15px;
    -moz-background-size: 15px 15px;
    -o-background-size: 15px 15px;
    background-size: 15px 15px;
    -webkit-animation: buffering-stripes 2s linear infinite;
    -moz-animation: buffering-stripes 2s linear infinite;
    -ms-animation: buffering-stripes 2s linear infinite;
    -o-animation: buffering-stripes 2s linear infinite;
    animation: buffering-stripes 2s linear infinite;
}

@-webkit-keyframes buffering-stripes {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 30px 0;
    }
}

@-moz-keyframes buffering-stripes {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 30px 0;
    }
}

@-ms-keyframes buffering-stripes {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 30px 0;
    }
}

@-o-keyframes buffering-stripes {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 30px 0;
    }
}

@keyframes buffering-stripes {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 30px 0;
    }
}

.mejs-controls .mejs-time-rail .mejs-time-loaded {
    background: #3caac8;
    background: rgba(255, 255, 255, 0.3);
    width: 0;
}

.mejs-controls .mejs-time-rail .mejs-time-current {
    background: #fff;
    background: #e6ae49;
    width: 0;
}

.mejs-controls .mejs-time-rail .mejs-time-handle {
    display: none;
    position: absolute;
    margin: 0;
    width: 10px;
    background: #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    cursor: pointer;
    border: solid 2px #333;
    top: -2px;
    text-align: center;
}

.mejs-controls .mejs-time-rail .mejs-time-float {
    position: absolute;
    display: none;
    background: #eee;
    width: 36px;
    height: 17px;
    border: solid 1px #333;
    top: -26px;
    margin-left: -18px;
    text-align: center;
    color: #111;
}

.mejs-controls .mejs-time-rail .mejs-time-float-current {
    margin: 2px;
    width: 30px;
    display: block;
    text-align: center;
    left: 0;
}

.mejs-controls .mejs-time-rail .mejs-time-float-corner {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    line-height: 0;
    border: solid 5px #eee;
    border-color: #eee transparent transparent transparent;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    top: 15px;
    left: 13px;
}

.mejs-long-video .mejs-controls .mejs-time-rail .mejs-time-float {
    width: 48px;
}

.mejs-long-video .mejs-controls .mejs-time-rail .mejs-time-float-current {
    width: 44px;
}

.mejs-long-video .mejs-controls .mejs-time-rail .mejs-time-float-corner {
    left: 18px;
}


/*
.mejs-controls .mejs-time-rail:hover .mejs-time-handle {
	visibility:visible;
}
*/


/* End: Progress Bar */


/* Start: Fullscreen */

.mejs-controls .mejs-fullscreen-button button {
    background-position: -30px 0;
}

.mejs-controls .mejs-unfullscreen button {
    background-position: -30px -16px;
}


/* End: Fullscreen */


/* Start: Mute/Volume */

.mejs-controls .mejs-volume-button {}

.mejs-controls .mejs-mute button {
    background-position: -14px -16px;
}

.mejs-controls .mejs-unmute button {
    background-position: -14px 0;
}

.mejs-controls .mejs-volume-button {
    position: relative;
}

.mejs-controls .mejs-volume-button .mejs-volume-slider {
    display: none;
    height: 115px;
    width: 25px;
    background: url('/img/icons/background.png');
    background: rgba(50, 50, 50, 0.7);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    top: -115px;
    left: 0;
    z-index: 1;
    position: absolute;
    margin: 0;
}

.mejs-controls .mejs-volume-button:hover {
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
}


/*
.mejs-controls .mejs-volume-button:hover .mejs-volume-slider {
	display: block;
}
*/

.mejs-controls .mejs-volume-button .mejs-volume-slider .mejs-volume-total {
    position: absolute;
    left: 11px;
    top: 8px;
    width: 2px;
    height: 100px;
    background: rgba(255, 255, 255, 0.3);
    margin: 0;
}

.mejs-controls .mejs-volume-button .mejs-volume-slider .mejs-volume-current {
    position: absolute;
    left: 11px;
    top: 8px;
    width: 2px;
    height: 100px;
    background: #e6ae49;
    margin: 0;
}

.mejs-controls .mejs-volume-button .mejs-volume-slider .mejs-volume-handle {
    position: absolute;
    left: 4px;
    top: -3px;
    width: 16px;
    height: 6px;
    background: #fff;
    cursor: N-resize;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    margin: 0;
}


/* horizontal version */

.mejs-controls a.mejs-horizontal-volume-slider {
    height: 26px;
    width: 56px;
    position: relative;
    display: block;
    float: left;
    vertical-align: middle;
}

.mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total {
    position: absolute;
    left: 0;
    top: 11px;
    width: 50px;
    height: 8px;
    margin: 0;
    padding: 0;
    font-size: 1px;
    background: rgba(255, 255, 255, 0.2);
}

.mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
    position: absolute;
    left: 0;
    top: 11px;
    width: 50px;
    height: 8px;
    margin: 0;
    padding: 0;
    font-size: 1px;
    background: #e6ae49;
}

.mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-handle {
    display: none;
}


/* End: Mute/Volume */


/* Start: Track (Captions and Chapters) */

.mejs-controls .mejs-captions-button {
    position: relative;
}

.mejs-controls .mejs-captions-button button {
    background-position: -48px 0;
}

.mejs-controls .mejs-captions-button .mejs-captions-selector {
    visibility: hidden;
    position: absolute;
    bottom: 26px;
    right: -51px;
    width: 85px;
    height: 100px;
    background: url('/img/icons/background.png');
    background: rgba(50, 50, 50, 0.7);
    border: solid 1px transparent;
    padding: 10px 10px 0 10px;
    overflow: hidden;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}


/*
.mejs-controls .mejs-captions-button:hover  .mejs-captions-selector {
	visibility: visible;
}
*/

.mejs-controls .mejs-captions-button .mejs-captions-selector ul {
    margin: 0;
    padding: 0;
    display: block;
    list-style-type: none !important;
    overflow: hidden;
}

.mejs-controls .mejs-captions-button .mejs-captions-selector ul li {
    margin: 0 0 6px 0;
    padding: 0;
    list-style-type: none !important;
    display: block;
    color: #fff;
    overflow: hidden;
}

.mejs-controls .mejs-captions-button .mejs-captions-selector ul li input {
    clear: both;
    float: left;
    margin: 3px 3px 0 5px;
}

.mejs-controls .mejs-captions-button .mejs-captions-selector ul li label {
    width: 55px;
    float: left;
    padding: 4px 0 0 0;
    line-height: 15px;
    font-family: helvetica, arial;
    font-size: 10px;
}

.mejs-controls .mejs-captions-button .mejs-captions-translations {
    font-size: 10px;
    margin: 0 0 5px 0;
}

.mejs-chapters {
    position: absolute;
    top: 0;
    left: 0;
    -xborder-right: solid 1px #fff;
    width: 10000px;
    z-index: 1;
}

.mejs-chapters .mejs-chapter {
    position: absolute;
    float: left;
    background: #222;
    background: rgba(0, 0, 0, 0.7);
    background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(50, 50, 50, 0.7)), to(rgba(0, 0, 0, 0.7)));
    background: -webkit-linear-gradient(top, rgba(50, 50, 50, 0.7), rgba(0, 0, 0, 0.7));
    background: -moz-linear-gradient(top, rgba(50, 50, 50, 0.7), rgba(0, 0, 0, 0.7));
    background: -o-linear-gradient(top, rgba(50, 50, 50, 0.7), rgba(0, 0, 0, 0.7));
    background: -ms-linear-gradient(top, rgba(50, 50, 50, 0.7), rgba(0, 0, 0, 0.7));
    background: linear-gradient(rgba(50, 50, 50, 0.7), rgba(0, 0, 0, 0.7));
    filter: progid: DXImageTransform.Microsoft.Gradient(GradientType=0, startColorstr=#323232, endColorstr=#000000);
    overflow: hidden;
    border: 0;
}

.mejs-chapters .mejs-chapter .mejs-chapter-block {
    font-size: 11px;
    color: #fff;
    padding: 5px;
    display: block;
    border-right: solid 1px #333;
    border-bottom: solid 1px #333;
    cursor: pointer;
}

.mejs-chapters .mejs-chapter .mejs-chapter-block-last {
    border-right: none;
}

.mejs-chapters .mejs-chapter .mejs-chapter-block:hover {
    background: #666;
    background: rgba(102, 102, 102, 0.7);
    background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(102, 102, 102, 0.7)), to(rgba(50, 50, 50, 0.6)));
    background: -webkit-linear-gradient(top, rgba(102, 102, 102, 0.7), rgba(50, 50, 50, 0.6));
    background: -moz-linear-gradient(top, rgba(102, 102, 102, 0.7), rgba(50, 50, 50, 0.6));
    background: -o-linear-gradient(top, rgba(102, 102, 102, 0.7), rgba(50, 50, 50, 0.6));
    background: -ms-linear-gradient(top, rgba(102, 102, 102, 0.7), rgba(50, 50, 50, 0.6));
    background: linear-gradient(rgba(102, 102, 102, 0.7), rgba(50, 50, 50, 0.6));
    filter: progid: DXImageTransform.Microsoft.Gradient(GradientType=0, startColorstr=#666666, endColorstr=#323232);
}

.mejs-chapters .mejs-chapter .mejs-chapter-block .ch-title {
    font-size: 12px;
    font-weight: bold;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 0 3px 0;
    line-height: 12px;
}

.mejs-chapters .mejs-chapter .mejs-chapter-block .ch-timespan {
    font-size: 12px;
    line-height: 12px;
    margin: 3px 0 4px 0;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.mejs-captions-layer {
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    line-height: 20px;
    font-size: 16px;
    color: #fff;
}

.mejs-captions-layer a {
    color: #fff;
    text-decoration: underline;
}

.mejs-captions-layer[lang=ar] {
    font-size: 20px;
    font-weight: normal;
}

.mejs-captions-position {
    position: absolute;
    width: 100%;
    bottom: 15px;
    left: 0;
}

.mejs-captions-position-hover {
    bottom: 35px;
}

.mejs-captions-text {
    padding: 3px 5px;
    background: url('/img/icons/background.png');
    background: rgba(20, 20, 20, 0.5);
    white-space: pre-wrap;
}


/* End: Track (Captions and Chapters) */


/* Start: Error */

.me-cannotplay {}

.me-cannotplay a {
    color: #fff;
    font-weight: bold;
}

.me-cannotplay span {
    padding: 15px;
    display: block;
}


/* End: Error */


/* Start: Loop */

.mejs-controls .mejs-loop-off button {
    background-position: -64px -16px;
}

.mejs-controls .mejs-loop-on button {
    background-position: -64px 0;
}


/* End: Loop */


/* Start: backlight */

.mejs-controls .mejs-backlight-off button {
    background-position: -80px -16px;
}

.mejs-controls .mejs-backlight-on button {
    background-position: -80px 0;
}


/* End: backlight */


/* Start: Picture Controls */

.mejs-controls .mejs-picturecontrols-button {
    background-position: -96px 0;
}


/* End: Picture Controls */


/* context menu */

.mejs-contextmenu {
    position: absolute;
    width: 150px;
    padding: 10px;
    border-radius: 4px;
    top: 0;
    left: 0;
    background: #fff;
    border: solid 1px #999;
    z-index: 1001;
    /* make sure it shows on fullscreen */
}

.mejs-contextmenu .mejs-contextmenu-separator {
    height: 1px;
    font-size: 0;
    margin: 5px 6px;
    background: #333;
}

.mejs-contextmenu .mejs-contextmenu-item {
    font-family: Helvetica, Arial;
    font-size: 12px;
    padding: 4px 6px;
    cursor: pointer;
    color: #333;
}

.mejs-contextmenu .mejs-contextmenu-item:hover {
    background: #2C7C91;
    color: #fff;
}


/* Start: Source Chooser */

.mejs-controls .mejs-sourcechooser-button {
    position: relative;
}

.mejs-controls .mejs-sourcechooser-button button {
    background-position: -128px 0;
}

.mejs-controls .mejs-sourcechooser-button .mejs-sourcechooser-selector {
    visibility: hidden;
    position: absolute;
    bottom: 26px;
    right: -10px;
    width: 130px;
    height: 100px;
    background: url('/img/icons/background.png');
    background: rgba(50, 50, 50, 0.7);
    border: solid 1px transparent;
    padding: 10px;
    overflow: hidden;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.mejs-controls .mejs-sourcechooser-button .mejs-sourcechooser-selector ul {
    margin: 0;
    padding: 0;
    display: block;
    list-style-type: none !important;
    overflow: hidden;
}

.mejs-controls .mejs-sourcechooser-button .mejs-sourcechooser-selector ul li {
    margin: 0 0 6px 0;
    padding: 0;
    list-style-type: none !important;
    display: block;
    color: #fff;
    overflow: hidden;
}

.mejs-controls .mejs-sourcechooser-button .mejs-sourcechooser-selector ul li input {
    clear: both;
    float: left;
    margin: 3px 3px 0 5px;
}

.mejs-controls .mejs-sourcechooser-button .mejs-sourcechooser-selector ul li label {
    width: 100px;
    float: left;
    padding: 4px 0 0 0;
    line-height: 15px;
    font-family: helvetica, arial;
    font-size: 10px;
}


/* End: Source Chooser */


/* Start: Postroll */

.mejs-postroll-layer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('/img/icons/background.png');
    background: rgba(50, 50, 50, 0.7);
    z-index: 1000;
    overflow: hidden;
}

.mejs-postroll-layer-content {
    width: 100%;
    height: 100%;
}

.mejs-postroll-close {
    position: absolute;
    right: 0;
    top: 0;
    background: url('/img/icons/background.png');
    background: rgba(50, 50, 50, 0.7);
    color: #fff;
    padding: 4px;
    z-index: 100;
    cursor: pointer;
}


/* End: Postroll */


/* Start: Speed */

div.mejs-speed-button {
    width: 46px !important;
    position: relative;
}

.mejs-controls .mejs-button.mejs-speed-button button {
    background: transparent;
    width: 36px;
    font-size: 11px;
    line-height: normal;
    color: #ffffff;
}

.mejs-controls .mejs-speed-button .mejs-speed-selector {
    visibility: hidden;
    position: absolute;
    top: -100px;
    left: -10px;
    width: 60px;
    height: 100px;
    background: url('/img/icons/background.png');
    background: rgba(50, 50, 50, 0.7);
    border: solid 1px transparent;
    padding: 0;
    overflow: hidden;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.mejs-controls .mejs-speed-button:hover>.mejs-speed-selector {
    visibility: visible;
}

.mejs-controls .mejs-speed-button .mejs-speed-selector ul li label.mejs-speed-selected {
    color: rgba(33, 248, 248, 1);
}

.mejs-controls .mejs-speed-button .mejs-speed-selector ul {
    margin: 0;
    padding: 0;
    display: block;
    list-style-type: none !important;
    overflow: hidden;
}

.mejs-controls .mejs-speed-button .mejs-speed-selector ul li {
    margin: 0 0 6px 0;
    padding: 0 10px;
    list-style-type: none !important;
    display: block;
    color: #fff;
    overflow: hidden;
}

.mejs-controls .mejs-speed-button .mejs-speed-selector ul li input {
    clear: both;
    float: left;
    margin: 3px 3px 0 5px;
    display: none;
}

.mejs-controls .mejs-speed-button .mejs-speed-selector ul li label {
    width: 60px;
    float: left;
    padding: 4px 0 0 0;
    line-height: 15px;
    font-family: helvetica, arial;
    font-size: 11.5px;
    color: white;
    margin-left: 5px;
    cursor: pointer;
}

.mejs-controls .mejs-speed-button .mejs-speed-selector ul li:hover {
    background-color: rgb(200, 200, 200) !important;
    background-color: rgba(255, 255, 255, .4) !important;
}


/* End: Speed */


/* Start: Skip Back */

.mejs-controls .mejs-button.mejs-skip-back-button {
    background: transparent url('/img/icons/skipback.png') no-repeat;
    background-position: 3px 3px;
}

.mejs-controls .mejs-button.mejs-skip-back-button button {
    background: transparent;
    font-size: 9px;
    line-height: normal;
    color: #ffffff;
}


/* End: Skip Back */